import { DateTime, Duration } from 'luxon'
import getDerivedValuesFromOrder from '../helpers/getDerivedValuesFromOrder'
import notNull from '../helpers/notNull'
import useGetOrderFromCache from './useGetOrderFromCache'

// Token we use to parse the custom duration from the server
const CUSTOM_TARIFF_TOKEN = '-M'

export const parseCustomDuration = (duration: string): null | Duration => {
    // Validate format XXX-M
    const customTokenValidator = new RegExp(`.*${CUSTOM_TARIFF_TOKEN}`, 'gm')
    const isCustomTariff = duration.match(customTokenValidator)
    if (!isCustomTariff) return null

    // Validate that XXX is valid duration like P1D
    const durationFromCustomDuration = duration.split(CUSTOM_TARIFF_TOKEN)[0]
    const customParsedDuration = Duration.fromISO(durationFromCustomDuration)
    if (!customParsedDuration.isValid) return null

    return customParsedDuration
}

const useTariffMapper = (orderId: string) => {
    const order = useGetOrderFromCache(orderId)

    if (!order) return []

    const tariffs = [...order.lot_tariffs]
    const now = DateTime.now()
    let billableStart = DateTime.fromISO(order.billable_start)
    const { isTollRoadLot } = getDerivedValuesFromOrder({ order })

    tariffs.sort((tariff1, tariff2) => {
        const tariff1Mils = Duration.fromISO(tariff1).toMillis()
        const tariff2Mils = Duration.fromISO(tariff2).toMillis()
        return tariff1Mils > tariff2Mils ? 1 : -1
    })

    const tariffOptions = tariffs
        .map((duration) => {
            let parsedDuration: Duration | null = Duration.fromISO(duration)
            if (!parsedDuration.isValid) {
                parsedDuration = parseCustomDuration(duration)
                if (!parsedDuration) return null
                // Special case, if it's a custom duration (night tariff)
                // the billable start is from the beginning of the start_date day
                billableStart = DateTime.fromISO(order.start_date).startOf('day')
            }

            const parkingExpectedEnd = billableStart.plus(parsedDuration)
            if (!parkingExpectedEnd.isValid) return null
            if (parkingExpectedEnd.toMillis() < now.toMillis()) return null

            return {
                id: duration,
                title: isTollRoadLot ? 'Ticket' : parsedDuration.toHuman()
            }
        })
        .filter(notNull)

    return tariffOptions
}

export default useTariffMapper
