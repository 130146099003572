import { PolicyContextOption } from '@hooks/usePolicyContextMapper'
import { DateTime } from 'luxon'
import { FullOrderFragment, LotType } from 'parkdepot-shared/gql/graphql'
import { StayOrLeaveDecision } from '../views/Orders/ordersListingContext'
import getDerivedValuesFromOrder from './getDerivedValuesFromOrder'

const shouldShowPrepaymentDecisionStep = (order: FullOrderFragment) => {
    const { totalPrice, startDate, insideParkingLot, lot_type, isTollRoadLot, arrivalDetectionTimeSecs } =
        getDerivedValuesFromOrder({ order })
    const isFreeOrder = totalPrice === 0
    const timeDiffSinceArrival = DateTime.now().diff(startDate, ['seconds'])
    const isInsideArrivalDetectionTime = timeDiffSinceArrival.seconds <= arrivalDetectionTimeSecs

    return (
        !isTollRoadLot &&
        (isFreeOrder || isInsideArrivalDetectionTime) &&
        insideParkingLot &&
        lot_type === LotType.Prepaid
    )
}

const shouldRedirectUserToEmailInput = (
    order: FullOrderFragment,
    stayDecision: StayOrLeaveDecision,
    policiesContext: PolicyContextOption[]
) => {
    const { isTollRoadLot } = getDerivedValuesFromOrder({ order })

    if (policiesContext.length > 1) return false // user must select policy
    if (
        (order.lot_type === LotType.Prepaid && stayDecision === StayOrLeaveDecision.stay) ||
        (order.lot_type === LotType.Prepaid && isTollRoadLot)
    ) {
        return false // user must select tariff
    }

    return true
}

export { shouldRedirectUserToEmailInput, shouldShowPrepaymentDecisionStep }
