import { styled } from 'parkdepot-shared/theme'

const StepContainer = styled('div', ({ $theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    padding: `${$theme.sizing.scale800} 0`,
    gap: $theme.sizing.scale600
}))

export default StepContainer
