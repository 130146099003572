import { ApolloQueryResult } from '@apollo/client'
import { FragmentType } from 'parkdepot-shared/gql'
import { CarpayGetOrdersQuery } from 'parkdepot-shared/gql/graphql'
import { generateContext } from 'parkdepot-shared/utils/contextGenerator'
import { useContext } from 'react'
import { OrderFragment } from '../../graphql/queries/carpayGetOrders.query'

export enum StayOrLeaveDecision {
    stay = 'stay',
    leave = 'leave',
    notSelected = 'notSelected'
}

export interface OrdersListingContextProps {
    orders: FragmentType<typeof OrderFragment>[]
    couponCode: string | null
    orderIdToApplyCode?: string
    stayDecision: StayOrLeaveDecision
    loading: boolean
    plate: string
    onDecisionChange: (decision: StayOrLeaveDecision) => void
    attachDiscount: (orderId: string, appliedDiscount: number, appliedDiscountCodes: string[]) => void
    refetch: (
        variables?: Partial<{
            plate: string
            return_free: boolean
        }>
    ) => Promise<ApolloQueryResult<CarpayGetOrdersQuery>>
}

const [OrdersListingContext, OrdersListingContextProvider] = generateContext<OrdersListingContextProps>()

const useOrdersStore = () => {
    const context = useContext(OrdersListingContext)

    if (!Object.entries(context).length) {
        throw new Error('useOrdersStore should be use under OrdersListingContext context')
    }

    return context
}

export { OrdersListingContext, useOrdersStore }
export default OrdersListingContextProvider
