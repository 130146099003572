import { FullOrderFragment } from 'parkdepot-shared/gql/graphql'
import React, { ReactNode, createContext, useContext, useEffect, useRef } from 'react'
import { StoreApi, createStore, useStore } from 'zustand'
import OrderStep from '../components/OrderStepper/types'
import getDerivedValuesFromOrder from '../helpers/getDerivedValuesFromOrder'
import { shouldShowPrepaymentDecisionStep } from '../helpers/stepsUtils'

export interface OrderStore {
    step: OrderStep
    setStep: (step: OrderStep) => void
}

const createOrderStore = () => {
    return createStore<OrderStore>((set) => ({
        step: 0,
        setStep: (step) => set(() => ({ step }))
    }))
}

const OrderStoreContext = createContext<StoreApi<OrderStore> | null>(null)

interface OrderProviderProps {
    order: FullOrderFragment
    children: ReactNode
}

const OrderProvider = ({ order, children }: OrderProviderProps) => {
    const orderStore = useRef(createOrderStore()).current
    const setStep = useStore(orderStore, (state) => state.setStep)
    // We want the first order to define the first step. The order then is overriden with the calculateOrderPrice changes
    const firstOrderRef = useRef(order)

    const { lot_id } = getDerivedValuesFromOrder({ order })

    useEffect(() => {
        const showPrepaymentDecision = shouldShowPrepaymentDecisionStep(firstOrderRef.current)

        const defineInitialStep = () => {
            if (showPrepaymentDecision) return OrderStep.PrepayFlow

            return OrderStep.PostpayFlow
        }

        setStep(defineInitialStep())
    }, [lot_id, setStep])

    return <OrderStoreContext.Provider value={orderStore}>{children}</OrderStoreContext.Provider>
}

const useGetOrderStore = () => {
    const context = useContext(OrderStoreContext)

    if (!context) {
        throw new Error('useOrderStore should be userd inside OrderProvider')
    }

    return context
}

const useOrderStore = <T extends keyof OrderStore>(key: T) => {
    const orderStore = useGetOrderStore()
    return useStore<StoreApi<OrderStore>, OrderStore[T]>(orderStore, (state) => state[key])
}

export { useOrderStore }
export default OrderProvider
