import { Select as BaseUISelect, SelectProps as BaseUISelectProps, SelectOverrides, Value } from 'baseui/select'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { CaretDownIcon, CaretUpIcon } from '../../assets/icons'

export interface SelectProps {
    nativeProps?: BaseUISelectProps
    options?: { label: React.ReactNode; id: string }[]
    placeholder?: string
    onChange?: (newValue: Value) => void
    value?: { value: React.ReactNode; id: string } | null
    $dark?: boolean
    overrides?: SelectOverrides
}
export const Select = ({
    $dark = true,
    options = [],
    nativeProps = {},
    value = null,
    placeholder = 'Please select a option',
    onChange = () => {},
    overrides
}: SelectProps) => {
    const [css] = useStyletron()
    return (
        <div className={css({})}>
            <BaseUISelect
                clearable={false}
                searchable={false}
                overrides={{
                    SingleValue: {
                        style: {
                            display: 'flex',
                            alignItems: 'center'
                        }
                    },
                    Input: {
                        props: {
                            disabled: true
                            //THis is to not open keyboard on mobile
                        }
                    },
                    IconsContainer: {
                        component: (state) => {
                            return (
                                <div className={css({ padding: '10px !important;' })}>
                                    {state.$isOpen ? <CaretUpIcon $dark={$dark} /> : <CaretDownIcon $dark={$dark} />}
                                </div>
                            )
                        }
                    },
                    ControlContainer: {
                        style: {
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            color: $dark ? 'black' : 'white',
                            border: `2px solid ${$dark ? 'black' : 'white'}!important;`,
                            borderRadius: '38px',
                            caretColor: $dark ? 'black' : 'white',
                            ':hover': {
                                border: `2px solid ${$dark ? 'black' : 'white'}`
                            },
                            ':active': {
                                border: `2px solid ${$dark ? 'black' : 'white '}`
                            },
                            ':focus': {
                                border: `2px solid ${$dark ? 'black' : 'white'}`
                            },
                            fontSize: '16px',
                            fontWeight: '700',
                            fontFamily: 'Albert Sans',
                            lineHeight: '22px',
                            padding: '0px 16px',
                            ...overrides?.ControlContainer?.style
                        }
                    },
                    Placeholder: { style: { color: $dark ? 'black' : 'white' } },
                    Popover: {
                        props: {
                            placement: 'top',
                            overrides: {
                                Body: {
                                    style: {
                                        backgroundColor: 'transparent !important;',
                                        boxShadow: 'none !important;',
                                        zIndex: 2
                                    }
                                },
                                Inner: {
                                    style: { backgroundColor: 'transparent !important;', boxShadow: 'none !important;' }
                                }
                            }
                        }
                    },

                    Dropdown: {
                        style: {
                            backgroundColor: 'white !important;',
                            opacity: '100%',
                            borderRadius: '24px',
                            width: '100%',
                            paddingTop: 0,
                            paddingBottom: 0
                        }
                    },
                    DropdownListItem: {
                        style: {
                            // paddingRight: '8px',
                            paddingLeft: '31px !important;',
                            display: 'flex',
                            alignItems: 'center',
                            paddingBlock: '12px',
                            ':hover': {
                                backgroundColor: '#F5F6FF !important;'
                            },
                            ':active': {
                                backgroundColor: '#F5F6FF !important;'
                            }
                        }
                    },
                    DropdownContainer: {
                        style: { backgroundColor: 'transparent' }
                    }
                }}
                options={options}
                value={value ? [value] : undefined}
                placeholder={placeholder}
                onChange={(changeEvent) => {
                    if (changeEvent.value) {
                        onChange(changeEvent.value)
                    }
                }}
                {...nativeProps}
            />
        </div>
    )
}
