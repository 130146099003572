import { generateContext } from 'parkdepot-shared/utils/contextGenerator'
import { AnimationConfig } from '../PrepaymentAppliedOverlay'

export interface OverlayContextProps {
    openPrepaymentAppliedOverlay: (config?: AnimationConfig) => void
}

const [OverlayContext, OverlayContextProvider] = generateContext<OverlayContextProps>()

export { OverlayContext }
export default OverlayContextProvider
