export const GERMAN_PLATE_EXTRACT =
    /^(?<city>[a-z\u00e4\u00f6\u00fc]*)_(?<letterCode>[a-z]{0,3})_(?<numberCode>[0-9]{0,4})_?(?<vehicleType>[e|h]?)$/i
export const GERMAN_PLATE_MATCH =
    /^(?<city>[a-z\u00e4\u00f6\u00fc]+)_(?<letterCode>[a-z]{1,3})_(?<numberCode>[0-9]{1,4})_?(?<vehicleType>[e|h]?)$/i

// Rare German license plate formats that can be found while tindering
export const LONG_GERMAN_PLATE_EXTRACT = /^(?<city>[a-z\u00e4\u00f6\u00fc]*)_(?<numberCode>(03|04|06)[0-9]{0,4})$/i
export const LONG_GERMAN_PLATE_MATCH = /^(?<city>[a-z\u00e4\u00f6\u00fc]+)_(?<numberCode>(03|04|06)[0-9]{3,4})$/i

export const DIPLOMATIC_GERMAN_PLATE_EXTRACT =
    /^(?<plateFirstPart>[a-z0]*)_(?<plateFirstDigits>[1-9]|[1-9][0-9]|1[0-9][0-9]|20[0-6]*)_(?<plateLastDigits>[[1-9]|[1-9][0-9]|[1-4][0-9][0-9]|500*)?(?<plateStolen>[a-z]?)$/i
export const DIPLOMATIC_GERMAN_PLATE_MATCH =
    /^(?<plateFirstPart>[a-z0]+)_(?<plateFirstDigits>[1-9]|[1-9][0-9]|1[0-9][0-9]|20[0-6]+)_(?<plateLastDigits>[1-9]|[1-9][0-9]|[1-4][0-9][0-9]|500+)?(?<plateStolen>[a-z]?)$/i

export const CONSULAT_GERMAN_PLATE_EXTRACT = /^(?<city>[a-z\u00e4\u00f6\u00fc]*)_(?<numberCode>9[0-9]{0,4})$/i
export const CONSULAT_GERMAN_PLATE_MATCH = /^(?<city>[a-z\u00e4\u00f6\u00fc]+)_(?<numberCode>9[0-9]{2,4})$/i

export const EXPORT_GERMAN_PLATE_EXTRACT =
    /^(?<plateFirstPart>[a-z\u00e4\u00f6\u00fc]*)_(?<plateLastPart>[0-9]*[a-z]{0,1})$/i
export const EXPORT_GERMAN_PLATE_MATCH =
    /^(?<plateFirstPart>[a-z\u00e4\u00f6\u00fc]+)_(?<plateLastPart>[0-9]+[a-z]{1})$/i

export const AUSTRIAN_PLATE_EXTRACT =
    /^(?<city>[a-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]+)_(?<plate>[a-z0-9\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]*)$/i
export const AUSTRIAN_PLATE_MATCH =
    /^(?<city>[a-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]{1,2})_(?<plate>[a-z0-9\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]{3,6})$/i
export const SWISS_PLATE_EXTRACT = /^(?<canton>[a-z]*)(_)*(?<plate>[0-9]*(U|Z){0,1})$/i
export const SWISS_PLATE_MATCH = /^(?<canton>[a-z]{2,2})_(?<plate>[0-9]{2,6}(U|Z){0,1})$/i
export const ITALY_PLATE_EXTRACT = /^(?<plateFirstPart>[a-z]+)(?<plateLastPart>[a-z0-9]*)$/i
export const ITALY_PLATE_MATCH = /^(?<plateFirstPart>[a-z]{1,3})(?<plateLastPart>[a-z0-9]{4,6})$/i

export const DENMARK_PLATE_EXTRACT = /^(?<plateLetters>[a-z]{0,2})(?<plateDigits>[0-9]{0,5})$/i
export const DENMARK_PLATE_MATCH = /^(?<plateLetters>[a-z]{2})(?<plateDigits>[0-9]{4,5})$/i

export const DENMARK_ARMY_PLATE_EXTRACT = /^(?<plate>[0-9]{0,5})$/i
export const DENMARK_ARMY_PLATE_MATCH = /^(?<plate>[0-9]{5})$/i

export const POLAND_PLATE_EXTRACT =
    /^(?<plateFirstPart>[a-z]+)(?<plateFirstDigits>[0-9]{0,2})(_)*(?<plateLastPart>[a-z0-9]*)$/i
export const POLAND_PLATE_MATCH =
    /^(?<plateFirstPart>[a-z]{1,3})(?<plateFirstDigits>[0-9]{0,2})_(?<plateLastPart>[a-z0-9]{2,6})$/i

export const COUNTRIES = {
    germany: 'DE',
    austria: 'AT',
    switzerland: 'CH',
    italy: 'IT',
    denmark: 'DK',
    poland: 'PL',
    foreign: 'INT'
}

export const SWISS_CANTON: Record<string, number> = {
    AG: 6,
    AI: 5,
    AR: 5,
    BE: 6,
    BL: 6,
    BS: 5,
    FR: 6,
    GE: 6,
    GL: 5,
    GR: 6,
    JU: 5,
    LU: 6,
    NE: 6,
    NW: 5,
    OW: 5,
    SG: 6,
    SH: 5,
    SO: 6,
    SZ: 5,
    TG: 6,
    TI: 6,
    UR: 5,
    VD: 6,
    VS: 6,
    ZG: 6,
    ZH: 6
}

export const lpToUIString = (plate: string) => {
    return plate.replace(/\_/g, ' ')
}

export const concatDKPlateParts = (plateObject: {
    plateLetters?: string
    plateDigits?: string
    [key: string]: string | undefined
}) => {
    const plateParts: string[] = []

    if (plateObject.plateLetters) {
        plateParts.push(`${plateObject.plateLetters || ''}`)
    }

    if (plateObject.plateDigits) {
        plateParts.push(`${plateObject.plateDigits || ''}`)
    }

    return plateParts.join('')
}
