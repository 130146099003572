import { DateTime } from 'luxon'
import { FullOrderFragment, SvLotType } from 'parkdepot-shared/gql/graphql'

// 15 mins
const DEFAULT_ARRIVAL_DETECTION_TIME_SECS = 900

const getDerivedValuesFromOrder = ({ order }: { order: FullOrderFragment }) => {
    const billableStartDate = DateTime.fromISO(order.billable_start)
    const startDate = DateTime.fromISO(order.start_date)
    const endDate = order?.end_date ? DateTime.fromISO(order.end_date) : null
    const priceValidUntil = DateTime.fromISO(order.price_valid_until)
    const isFullyWhitelisted = !order.billable
    const whitelistDiscount = order.full_price - order.price
    const billableEnd = DateTime.fromISO(order.billable_end)
    const insideParkingLot = order.order_status === 'INCOMPLETE'

    const tax = order.price - order.price / (1 + order.vat_rate)
    const originalPrice = order.full_price - tax
    const totalPrice = Math.max(order.price - (order.applied_discount ?? 0), 0)
    const isTollRoadLot = order.sv_lot_type === SvLotType.TollRoad
    const arrivalDetectionTimeSecs = order.arrival_detection_time ?? DEFAULT_ARRIVAL_DETECTION_TIME_SECS
    const vat = order.vat_rate * 100

    return {
        ...order,
        vat,
        billableStartDate,
        startDate,
        endDate,
        priceValidUntil,
        insideParkingLot,
        isFullyWhitelisted,
        whitelistDiscount,
        originalPrice,
        totalPrice,
        tax,
        billableEnd,
        isTollRoadLot,
        arrivalDetectionTimeSecs
    }
}

export default getDerivedValuesFromOrder
