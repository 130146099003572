import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { AppFrame, Contact, GlobalErrorBoundary, Home, Orders } from './views'

const Routes: React.FC = () => {
    return (
        <AppFrame>
            <GlobalErrorBoundary>
                <Switch>
                    <Route key="contact" path="/contact" component={Contact} />
                    <Route key="orders" path="/orders" component={Orders} />
                    <Route key="home" path="/" component={Home} />
                </Switch>
            </GlobalErrorBoundary>
        </AppFrame>
    )
}

export default Routes
