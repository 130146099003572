import { styled, withStyle } from 'parkdepot-shared/theme'

const SuccessTitle = styled('h2', ({ $theme }) => ({
    width: '100%',
    marginBottom: 0,
    fontWeight: 600,
    fontSize: '1.5rem',
    color: $theme.colors.black,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
}))

const SuccessfulTitle = withStyle(SuccessTitle, ({ $theme }) => ({
    color: $theme.colors.positive
}))

const WarningTitle = withStyle(SuccessTitle, ({ $theme }) => ({
    color: $theme.colors.warning
}))

const FailedTitle = withStyle(SuccessTitle, ({ $theme }) => ({
    color: $theme.colors.negative
}))

export { FailedTitle, SuccessTitle, SuccessfulTitle, WarningTitle }
