import { ErrorBoundary } from '@sentry/react'
import React, { ReactNode } from 'react'
import GlobalErrorView from './globalErrorView'

type GlobalErrorBoundaryProps = {
    children: ReactNode
}

const GlobalErrorBoundary = ({ children }: GlobalErrorBoundaryProps) => {
    const onError = (error: Error, componentStack: string) => {
        console.error('Global error handler caught exception', error, componentStack)
    }

    return (
        <ErrorBoundary onError={onError} fallback={<GlobalErrorView />}>
            {children}
        </ErrorBoundary>
    )
}

export default GlobalErrorBoundary
