import React from 'react'

export const CloseIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                width="30.6414"
                height="3.13647"
                transform="matrix(0.71051 -0.703687 0.71051 0.703687 0 22.4675)"
                fill="#0A0A0A"
            />
            <rect
                width="30.6414"
                height="3.13647"
                transform="matrix(0.71051 0.703687 -0.71051 0.703687 2.229 0.674622)"
                fill="#0A0A0A"
            />
        </svg>
    )
}
