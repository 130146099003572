import { useLazyQuery } from '@apollo/client'
import CARPAY_CALCULATE_ORDER_PRICE from '../graphql/queries/carpayCalculatePrice.query'

const useCalculateOrderPriceQuery = (onCompleted?: () => void) => {
    const [executeFn, props] = useLazyQuery(CARPAY_CALCULATE_ORDER_PRICE, { fetchPolicy: 'network-only' })

    const recalculatePrice = (
        orderId: string,
        selectedPolicyContextId: string | null,
        selectedParkingTariff: string
    ) => {
        return executeFn({
            variables: {
                order_id: orderId,
                selected_parking_tariff: selectedParkingTariff,
                parking_context_uuid: selectedPolicyContextId
            },
            onCompleted
        })
    }

    return [recalculatePrice, props] as const
}

export default useCalculateOrderPriceQuery
