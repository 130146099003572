import type {} from '@redux-devtools/extension'
import { Value } from 'baseui/select'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export type ValueWithUUID = (Value[number] & { uuid: string | null })[]

interface UserInputStore {
    email: string
    policy: ValueWithUUID | null
    tariff: Value | null

    setEmail: (email: string) => void
    setPolicy: (selectedPolicy: ValueWithUUID | null) => void
    setTariff: (selectedTariff: Value | null) => void
    cleanUserInputStore: () => void
    getPolicyId: () => string | null
    getTariff: () => string | null
}

const useUserInputStore = create<UserInputStore>()(
    devtools((set, get) => ({
        email: '',
        policy: null,
        tariff: null,

        setEmail: (email) => set(() => ({ email })),
        setPolicy: (selectedPolicy) => set(() => ({ policy: selectedPolicy })),
        setTariff: (selectedTariff) => set(() => ({ tariff: selectedTariff })),
        getTariff: () => {
            const { tariff } = get()
            if (!tariff || !tariff[0].id) return null

            return tariff[0].id.toString()
        },
        getPolicyId: () => {
            const { policy } = get()
            if (!policy || !policy[0].uuid) return null

            return policy[0].uuid
        },
        cleanUserInputStore: () =>
            set(() => ({
                email: '',
                policy: null,
                tariff: null
            }))
    }))
)

export default useUserInputStore
