export enum LAYOUT_KEYS {
    DEFAULT = 'default',
    DE = 'default',
    FR = 'FR',
    INT = 'INT'
}

const layoutExists = (layoutKey: string) => {
    if (Object.keys(LAYOUT_KEYS).includes(layoutKey)) return true
    return false
}

export const getLayoutPerLanguage = (langCode: string) => {
    // Check format country_lang or lang_country
    const underscoreSplit = langCode.split('_')
    if (underscoreSplit.length > 1) {
        if (layoutExists(underscoreSplit[0])) return LAYOUT_KEYS[underscoreSplit[0] as keyof typeof LAYOUT_KEYS]
        if (layoutExists(underscoreSplit[1])) return LAYOUT_KEYS[underscoreSplit[1] as keyof typeof LAYOUT_KEYS]
    }
    // Check format country-lang or lang-country
    const dashSplit = langCode.split('-')
    if (dashSplit.length > 1) {
        if (layoutExists(dashSplit[0])) return LAYOUT_KEYS[dashSplit[0] as keyof typeof LAYOUT_KEYS]
        if (layoutExists(dashSplit[1])) return LAYOUT_KEYS[dashSplit[1] as keyof typeof LAYOUT_KEYS]
    }
    // Check format lang
    if (Object.keys(LAYOUT_KEYS).includes(langCode.toUpperCase()))
        return LAYOUT_KEYS[langCode.toUpperCase() as keyof typeof LAYOUT_KEYS]
    return LAYOUT_KEYS.INT
}

export const layouts: Record<LAYOUT_KEYS, Record<string, string[]>> = {
    [LAYOUT_KEYS.DEFAULT]: {
        license_plate: [
            '1 2 3 4 5 6 7 8 9 0',
            'Q W E R T Z U I O P Ü',
            'A S D F G H J K L Ö Ä',
            'Y X C V B N M {backspace}'
        ],
        name: ['Q W E R T Z U I O P Ü', 'A S D F G H J K L Ö Ä', 'Y X C V B N M', '@ . {space} _ - {backspace}'],
        email: [
            '1 2 3 4 5 6 7 8 9 0',
            'Q W E R T Z U I O P',
            'A S D F G H J K L',
            'Y X C V B N M',
            '@ . _ - {backspace}'
        ],
        default: ['1 2 3 4 5 6 7 8 9 0', 'Q W E R T Z U I O P Ü', 'A S D F G H J K L Ö Ä', 'Y X C V B N M {backspace}']
    },
    [LAYOUT_KEYS.FR]: {
        license_plate: [
            '1 2 3 4 5 6 7 8 9 0',
            'A Z E R T Y U I O P ',
            'Q S D F G H J K L',
            'W X C V B N M {backspace}'
        ],
        name: [
            '1 2 3 4 5 6 7 8 9 0',
            'A Z E R T Y U I O P',
            'Q S D F G H J K L',
            'W X C V B N M',
            '@ . {space} _ - {backspace}'
        ],
        email: [
            '1 2 3 4 5 6 7 8 9 0',
            'A Z E R T Y U I O P',
            'Q S D F G H J K L',
            'W X C V B N M',
            '@ . _ - {backspace}'
        ],
        default: ['1 2 3 4 5 6 7 8 9 0 {backspace}', 'A Z E R T Y U I O P', 'Q S D F G H J K L', '@ W X C V B N M .']
    },
    [LAYOUT_KEYS.INT]: {
        license_plate: ['1 2 3 4 5 6 7 8 9 0', 'Q W E R T Y U I O P', 'A S D F G H J K L', 'Z X C V B N M {backspace}'],
        name: [
            '1 2 3 4 5 6 7 8 9 0',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M',
            '@ . {space} _ - {backspace}'
        ],
        email: [
            '1 2 3 4 5 6 7 8 9 0',
            'Q W E R T Y U I O P',
            'A S D F G H J K L',
            'Z X C V B N M',
            '@ . _ - {backspace}'
        ],
        default: ['1 2 3 4 5 6 7 8 9 0 {backspace}', 'Q W E R T Y U I O P', 'A S D F G H J K L', '@ Z X C V B N M .']
    }
}
