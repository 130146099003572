import _ from 'lodash'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { useImmer } from 'use-immer'
import TouchKeyboard from './TouchKeyboard'
import { layouts } from './layouts'

export interface ITouchKeyboardProps {
    handler?: (input: string) => void
    props?: any
    defaultValue?: string
    withTrim?: boolean
}

interface ITouchKeyboardContext {
    showKeyboard: (id: string, { handler, props, defaultValue, withTrim }: ITouchKeyboardProps) => void
    hideKeyboard: () => void
    updateHandler: (handler: (input: string) => void) => void
    keyboardRef: any
    hasKeyboard: boolean
    forceSelectionOfKeyboard: (name: string, value: string) => void
}

export const TouchKeyboardContext = React.createContext<ITouchKeyboardContext>({
    showKeyboard: () => {},
    hideKeyboard: () => {},
    updateHandler: () => {},
    keyboardRef: null,
    hasKeyboard: false,
    forceSelectionOfKeyboard: () => {}
})

export const DefaultTouchKeyboardProps = {
    layoutName: 'email',
    layout: layouts.default,
    display: {
        '{backspace}': '<i class="pkd-delete" style="color: #DB2B1E;" />',
        '{space}': 'space'
    }
}

interface ITouchKeyboardProvider {
    children: React.ReactNode | React.ReactNodeArray
}

const TouchKeyboardProvider = ({ children }: ITouchKeyboardProvider) => {
    const [css] = useStyletron()
    const [keyboardState, setKeyboardState] = useImmer<{ id?: string } & ITouchKeyboardProps>({})
    const keyboard = React.useRef<any>(null)

    const updateState = ({
        id,
        handler,
        props,
        defaultValue,
        withTrim
    }: Partial<ITouchKeyboardProps & { id: string }>) => {
        setKeyboardState((draft) => {
            draft.id = id
            draft.handler = handler
            draft.props = props
            draft.withTrim = withTrim
            draft.defaultValue = defaultValue
        })
    }

    const debouncedUpdateState = React.useCallback(_.debounce(updateState, 200), [])

    // Memoize context object to avoid expensive rerenders of the entire
    // app tree.
    const staticContext = React.useMemo(() => {
        const showKeyboard = (id: string, args: ITouchKeyboardProps) => {
            debouncedUpdateState({ ...args, id })
        }

        const hideKeyboard = () => {
            debouncedUpdateState({})
        }

        const updateHandler = (handler: (input: string) => void) => {
            setKeyboardState((draft) => {
                draft.handler = handler
            })
        }

        return {
            showKeyboard,
            hideKeyboard,
            updateHandler,
            keyboardRef: keyboard
        }
    }, [])

    const forceSelectionOfKeyboard = React.useCallback(
        (inpuName: string, value: string) => {
            if (keyboard.current?.getInput(inpuName) !== value) {
                keyboard.current && keyboard.current.setInput(value, inpuName)
                keyboard.current.caretPosition = value.length
                keyboard.current.caretPositionEnd = value.length
            }
        },
        [keyboard]
    )

    const { id, handler, props, withTrim } = keyboardState

    const hasKeyboard = !!id

    const context = { ...staticContext, hasKeyboard, forceSelectionOfKeyboard }

    // Hides usercentrics icon if the keyboard is being displayed
    React.useEffect(() => {
        let interval: NodeJS.Timeout
        if (id) {
            setTimeout(() => {
                keyboard?.current &&
                    keyboard.current.getInput(id) !== keyboardState.defaultValue &&
                    keyboard.current.setInput(keyboardState.defaultValue, id)
            }, 200)
        }
        interval = setInterval(() => {
            const usercentricPrivacyButton = document
                ?.querySelector('#usercentrics-root')
                ?.shadowRoot?.querySelector('div > div > button')
            if (usercentricPrivacyButton) {
                usercentricPrivacyButton.setAttribute('style', id ? 'display: none;' : 'display: block;')
                clearInterval(interval)
            }
        }, 100)
        return () => clearInterval(interval)
    }, [id])

    return (
        <TouchKeyboardContext.Provider value={context}>
            {children}
            {id && (
                <div
                    className={css({
                        zIndex: 1000,
                        position: 'fixed',
                        width: '100%',
                        bottom: '0px',
                        left: 0
                    })}
                >
                    <TouchKeyboard
                        keyboardRef={keyboard}
                        onChange={handler}
                        {...props}
                        withFormatting={withTrim}
                        inputName={id}
                    />
                </div>
            )}
        </TouchKeyboardContext.Provider>
    )
}

export default TouchKeyboardProvider
