import Hotjar from '@hotjar/browser'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { generateConsentContext } from 'parkdepot-shared/utils/consentContextGenerator'
import React, { ReactNode, useCallback, useContext, useMemo } from 'react'
import { useImmer } from 'use-immer'

type Permissions = {
    allowHotjar: boolean
    allowSentry: boolean
}

type PermissionEvent = {
    event: string
    Sentry: boolean
    Hotjar: boolean
}

const [ConsentContext, getConsentContainer] = generateConsentContext<Permissions, PermissionEvent>()

const INITIAL_PERMISSIONS = {
    allowHotjar: false,
    allowSentry: false
}

const CarpayConsentContainer = ({ children }: { children: ReactNode }) => {
    const [permissions, setPermissions] = useImmer(INITIAL_PERMISSIONS)

    const handleConsentChange = useCallback(
        (e: CustomEvent<PermissionEvent>) => {
            if (e.detail && e.detail.event !== 'consent_status') return

            const { VITE_ENVIRONMENT, NODE_ENV, VITE_HOTJAR_SITE_ID, VITE_HOTJAR_VERSION, VITE_SENTRY_DSN } =
                process.env

            if (!VITE_ENVIRONMENT || !NODE_ENV || NODE_ENV === 'development') {
                return
            }

            if (VITE_SENTRY_DSN && VITE_ENVIRONMENT) {
                if (e.detail?.Sentry === true) {
                    Sentry.init({
                        dsn: VITE_SENTRY_DSN,
                        environment: VITE_ENVIRONMENT,
                        logLevel: 3,
                        attachStacktrace: true,
                        maxBreadcrumbs: 300,
                        integrations: [
                            new CaptureConsoleIntegration({
                                levels: ['warn', 'error']
                            })
                        ]
                    })
                } else {
                    Sentry.close()
                }
            }

            if (e.detail?.Hotjar === true && VITE_HOTJAR_SITE_ID && VITE_HOTJAR_VERSION) {
                Hotjar.init(Number(VITE_HOTJAR_SITE_ID), Number(VITE_HOTJAR_VERSION))
            }

            setPermissions((draft) => {
                draft.allowHotjar = !!e.detail?.Hotjar
                draft.allowSentry = !!e.detail?.Sentry
            })
        },
        [setPermissions]
    )

    const ConsentContainer = useMemo(() => getConsentContainer(handleConsentChange), [handleConsentChange])

    return <ConsentContainer context={permissions}>{children}</ConsentContainer>
}

const useConsentPermission = () => {
    const context = useContext(ConsentContext)

    if (!context) {
        throw new Error('useConsentPermission should be used under CarpayConsentContainer')
    }

    return context
}

export { useConsentPermission }
export default CarpayConsentContainer
