import React from 'react'

export const BurgerIcon = ({ $dark }: { $dark: boolean }) => {
    return (
        <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.92981" y="0.674683" width="24.0702" height="3.07082" fill={$dark ? '#0A0A0A' : '#FFFFFF'} />
            <rect x="0.92981" y="10.6039" width="24.0702" height="3.07082" fill={$dark ? '#0A0A0A' : '#FFFFFF'} />
        </svg>
    )
}
