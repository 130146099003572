import React from 'react'
import { ITouchKeyboardProps, TouchKeyboardContext } from '../../components/TouchKeyboard'

const useTouchKeyboard = (id: string, { handler, props, defaultValue, withTrim }: ITouchKeyboardProps) => {
    const [keyboardShown, setKeyboardShown] = React.useState(false)
    const { showKeyboard, hideKeyboard, keyboardRef } = React.useContext(TouchKeyboardContext)

    const showAndRegister = () => {
        showKeyboard(id, { handler, props, defaultValue, withTrim })
        setKeyboardShown(true)
    }

    const hideAndUnregister = () => {
        setKeyboardShown(false)
        hideKeyboard()
    }

    React.useEffect(() => {
        if (keyboardShown) {
            showKeyboard(id, { handler, props, defaultValue, withTrim })
            if (defaultValue) {
                keyboardRef.current.setInput(defaultValue, 'german_right_input')
                keyboardRef.current.caretPosition = defaultValue.length
                keyboardRef.current.caretPositionEnd = defaultValue.length
            }
        }
    }, [defaultValue])

    return { showKeyboard: showAndRegister, hideKeyboard: hideAndUnregister }
}

export default useTouchKeyboard
