import { ResultCode } from '@adyen/adyen-web/dist/types/components/types'
import { useApolloClient } from '@apollo/client'
import { DateTime } from 'luxon'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import LicensePlateInput from 'parkdepot-shared/components/LPInput'
import { SvLotType } from 'parkdepot-shared/gql/graphql'
import { CARPAY_STATUS } from 'parkdepot-shared/models/database'
import { useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'
import { useOrdersStore } from '@views/Orders/ordersListingContext'
import { OrderFragment } from '../../graphql/queries/carpayGetOrders.query'
import BackSubheader from '../BackSubheader/BackSubheader'
import CardBackground from '../style/CardBackground.styles'
import { CircleIconFailed, CircleIconSuccess, CircleIconWarning } from './style/CircleIcon.style'
import MessageWrapper from './style/MessageWrapper.style'
import ParkingTime from './style/ParkingTime.style'
import SuccessPageMessage from './style/SuccessPageMessage.style'
import { FailedTitle, SuccessTitle, SuccessfulTitle, WarningTitle } from './style/SuccessTitle'

// Default to 15 minutes
const DEFAULT_MIN_VISIBLE_TOLERATION_SECS = 900

export interface SuccessProps {
    plate?: string
    orderId?: string | null
    success?: ResultCode | null
}

const Success: React.FC<SuccessProps> = ({ plate, success, orderId }) => {
    const { t } = useTranslation(['feedback'])
    const [css, theme] = useStyletron()
    const history = useHistory()
    const client = useApolloClient()
    const { orders, refetch } = useOrdersStore()

    const order = client.cache.readFragment({
        id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
        fragment: OrderFragment
    })

    const isTollRoadLot = order?.sv_lot_type === SvLotType.TollRoad

    const startDate = DateTime.now().toFormat('dd.MM.yyyy HH:mm')

    const exitTime = order?.price_valid_until
        ? DateTime.fromISO(order?.price_valid_until).plus({
              seconds: order.visible_toleration_time || DEFAULT_MIN_VISIBLE_TOLERATION_SECS
          })
        : null

    const showedPlate = plate || order?.plate

    // plate not found
    if (!success) {
        return (
            <div className={css({ position: 'relative', paddingTop: theme.sizing.scale1600 })}>
                <BackSubheader />
                {/* baseui is weird */}
                <CardBackground style={{ width: '100%' }}>
                    <MessageWrapper>
                        <SuccessTitle>{t('feedback.title')}</SuccessTitle>
                        <SuccessPageMessage>{t('feedback.subtitle')}</SuccessPageMessage>
                        <div>
                            <LicensePlateInput readOnly value={showedPlate} />
                            <ParkingTime>{startDate}</ParkingTime>
                        </div>
                    </MessageWrapper>
                </CardBackground>
            </div>
        )
    }

    // if payment is successful
    if (success === 'Authorised' && showedPlate) {
        if (order && order.order_status === CARPAY_STATUS.INCOMPLETE) {
            // If the user refreshed the page and for some reason the order was not yet marked as PAID in the DB
            // We redirect the user for him to not see an incorrect price_valid_until
            return <Redirect to="/" />
        }

        return (
            <div className={css({ position: 'relative', paddingTop: theme.sizing.scale1600 })}>
                <BackSubheader />
                <CardBackground>
                    <MessageWrapper>
                        <SuccessfulTitle>
                            <CircleIconSuccess className="wemolo-check-circle" />
                            {t('feedback.success.successTitle')}
                        </SuccessfulTitle>
                        <SuccessPageMessage data-testid="success-message">
                            {!isTollRoadLot
                                ? t('feedback.success.successSubtitle', {
                                      hour: exitTime?.toFormat('HH:mm') ?? '',
                                      relativeTime: exitTime?.toRelativeCalendar({ unit: 'days' }) ?? ''
                                  })
                                : t('feedback.success.successSubtitleTollRoad')}
                        </SuccessPageMessage>
                        <div>
                            <LicensePlateInput readOnly value={showedPlate} />
                            <ParkingTime>{startDate}</ParkingTime>
                        </div>
                    </MessageWrapper>
                </CardBackground>

                {orders.length > 1 ? (
                    <div className={css({ alignSelf: 'center', marginTop: theme.sizing.scale800 })}>
                        <Button
                            $kind={BUTTON_KIND.primary}
                            $size={BUTTON_SIZE.large}
                            onClick={() => {
                                refetch()
                                history.push({
                                    pathname: '/orders',
                                    search: `?plate=${showedPlate}`
                                })
                            }}
                        >
                            {t('feedback.payNextOrder')}
                        </Button>
                    </div>
                ) : null}
            </div>
        )
    }

    // if payment is pending
    if ((success === 'Pending' || success === 'Received') && showedPlate) {
        return (
            <div className={css({ position: 'relative', paddingTop: theme.sizing.scale1600 })}>
                <BackSubheader />
                <CardBackground>
                    <MessageWrapper>
                        <WarningTitle>
                            <CircleIconWarning className="wemolo-clock" />
                            {t('feedback.success.pendingTitle')}
                        </WarningTitle>
                        <SuccessPageMessage>{t('feedback.success.pendingSubtitle')}</SuccessPageMessage>
                        <div>
                            <LicensePlateInput readOnly value={showedPlate} />
                            <ParkingTime>{startDate}</ParkingTime>
                        </div>
                    </MessageWrapper>
                </CardBackground>
            </div>
        )
    }

    // if payment is not successful
    if ((success === 'Refused' || success === 'Error') && showedPlate) {
        return (
            <div className={css({ position: 'relative', paddingTop: theme.sizing.scale1600 })}>
                <BackSubheader />
                <CardBackground>
                    <MessageWrapper>
                        <FailedTitle>
                            <CircleIconFailed className="wemolo-cross-circle" />
                            {t('feedback.notSuccessful.title')}
                        </FailedTitle>
                        <SuccessPageMessage>{t('feedback.notSuccessful.subtitle')}</SuccessPageMessage>
                        <div>
                            <LicensePlateInput readOnly value={showedPlate} />
                            <ParkingTime>{startDate}</ParkingTime>
                        </div>
                    </MessageWrapper>
                </CardBackground>
            </div>
        )
    }

    // default
    return (
        <div className={css({ position: 'relative' })}>
            <BackSubheader />
            <CardBackground>
                <MessageWrapper>
                    <CircleIconFailed className="wemolo-cross-circle" />
                    <p style={{ marginTop: '20px', fontWeight: 600, color: '#D92D20', fontSize: '24px' }}>
                        {t('feedback.notSuccessful.title')}
                    </p>
                    <p style={{ marginTop: '24px', fontWeight: 400, fontSize: '20px', padding: '0 16px' }}>
                        {t('feedback.notSuccessful.subtitle')}
                    </p>
                </MessageWrapper>
            </CardBackground>
        </div>
    )
}

export default Success
