import LicensePlateInput from 'parkdepot-shared/components/LPInput'
import { styled } from 'parkdepot-shared/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface MyPlateProps {
    plate: string
}

const MyPlateWrapper = styled('div', ({ $theme }) => ({
    gap: $theme.sizing.scale500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
}))

const Text = styled('p', ({ $theme }) => ({
    ...$theme.typography.LabelXSmall
}))

const MyPlate = ({ plate }: MyPlateProps) => {
    const { t } = useTranslation('orders')

    return (
        <MyPlateWrapper>
            <Text>{t('orders.youWillPayFor')}</Text>

            <LicensePlateInput disabled readOnly value={plate} />
        </MyPlateWrapper>
    )
}

export default MyPlate
