export const VIOLATION_CASE = {
    STATUS: {
        FINAL: {
            PAID: 'paid',
            PAID_BACK: 'paid_back',
            CANCELED: 'canceled',
            DELPARK: 'outsourced_delpark',
            TRANSFERRED: 'transferred',
            WRITE_OFF: 'write_off',
            YELLOW_CARD_SENT: 'yellow_card_sent'
        },
        MANUAL: {
            NEW: 'new',
            PAID_BACK: 'paid_back',
            ON_HOLD: 'on_hold',
            CANCELED: 'canceled',
            INTERNATIONAL: 'international',
            IN_CHECK_TECH: 'in_check_tech',
            CHECKED_BY_TECH: 'checked_by_tech',
            OPEN: 'open',
            PAID: 'paid'
        },
        LETTER: {
            //NOTE : LETTER PROPERTY MUST REMAINED IN ASCENDING ORDER OF MAIL STAGES
            READY_TO_SEND_LETTER: 'ready_to_send_letter',
            LETTER_SENT: 'letter_sent',
            REMINDER_SENT: 'reminder_sent',
            FIRST_WARNING_SENT: 'first_warning_sent',
            SECOND_WARNING_SENT: 'second_warning_sent'
        },
        RESTRICTED: {
            WAITING_FOR_OWNER_DATA: 'waiting_for_owner_data',
            WAITING_FOR_DATA_VALIDATION: 'waiting_for_data_validation',
            READY_TO_SEND_EXTENDED_KBA_REQUEST: 'ready_to_send_extended_kba_request',
            EXTENDED_KBA_REQUEST_SENT: 'extended_kba_request_sent',
            READY_FOR_DATENHAUS_REQUEST: 'ready_for_datenhaus_request',
            READY_TO_SEND_LETTER: 'ready_to_send_letter',
            DATENHAUS_REQUEST_SENT: 'datenhaus_request_sent',
            NO_NEW_INFORMATION_DATENHAUS: 'no_new_information_datenhaus',
            OWNER_REQUEST_FAILED: 'owner_request_failed',
            KBA_NOT_REGISTERED: 'kba_not_registered',
            KBA_REQUEST_BLOCKED: 'kba_request_blocked',
            KBA_REQUEST_FAILED: 'kba_request_failed',
            REQUEST_FAILED: 'request_failed',
            LETTER_GENERATED: 'letter_generated',
            LETTER_SENT: 'letter_sent',
            REMINDER_SENT: 'reminder_sent',
            FIRST_WARNING_SENT: 'first_warning_sent',
            SECOND_WARNING_SENT: 'second_warning_sent',
            TRANSFERRED: 'transferred',
            IN_CHECK_TECH: 'in_check_tech',
            CHECKED_BY_TECH: 'checked_by_tech',
            LETTER_UNDELIVERABLE: 'letter_undeliverable',
            DEBT_COLLECTION: 'debt_collection',
            WAITING_FOR_DRIVER_INFORMATION: 'waiting_for_driver_information',
            READY_TO_SEND_DRIVER_REQUEST: 'ready_to_send_driver_request',
            DELPARK: 'outsourced_delpark',
            WRITE_OFF: 'write_off',
            YELLOW_CARD_SENT: 'yellow_card_sent',
            HOLD_BACK_CANCELLATION_PREDICTION: 'hold_back_cancellation_prediction'
        },
        ONLY_PLATE_CHANGE: ['request_failed'],
        FORBIDDEN_PAYMENTS: [
            'paid',
            'paid_back',
            'transferred',
            'debt_collection',
            'canceled',
            'yellow_card_sent',
            'outsourced_delpark'
        ],
        FORBIDDEN_CHANGE: [
            'write_off',
            'waiting_for_owner_data',
            'extended_kba_request_sent',
            'datenhaus_request_sent',
            'paid_back',
            'transferred',
            'debt_collection',
            'request_failed',
            'waiting_for_driver_information',
            'ready_to_send_driver_request',
            'hold_back_cancellation_prediction'
        ],
        RESTART_ALLOWED: [
            'open',
            'canceled',
            'on_hold',
            'in_check_tech',
            'checked_by_tech',
            'paid_back',
            'write_off',
            'paid'
        ],
        SPECIAL_LETTER_FORBIDDEN: [
            'new',
            'waiting_for_owner_data',
            'ready_to_send_extended_kba_request',
            'no_new_information_datenhaus',
            'kba_request_blocked',
            'kba_request_failed',
            'kba_not_registered',
            'ready_for_datenhaus_request',
            'extended_kba_request_sent',
            'datenhaus_request_sent',
            'transferred',
            'debt_collection',
            'request_failed',
            'letter_undeliverable',
            'ready_to_send_letter',
            'letter_generated',
            'in_check_tech',
            'checked_by_tech',
            'waiting_for_driver_information',
            'ready_to_send_driver_request',
            'hold_back_cancellation_prediction'
        ]
    }
}

export const LOT = {
    LOT_TYPES: {
        REGULAR: 'regular',
        SHORT_TERM: 'short_term_parking',
        NO_PARKING: 'no_parking',
        CAR_PARK: 'car_park',
        TRUCK_PARKING: 'truck_parking',
        MULTILOT: 'multilot'
    },
    TINDER_FILTERS: {
        REGULAR: ['regular', 'short_term_parking', 'no_parking'],
        CAR_PARK: ['car_park'],
        TRUCK_PARKING: ['truck_parking'],
        MULTILOT: ['multilot']
    }
}

export const CARPAY_STATUS = {
    INCOMPLETE: 'INCOMPLETE',
    UNPAID: 'UNPAID',
    UNPAID_ENFORCEABLE: 'UNPAID_ENFORCEABLE',
    PAID: 'PAID',
    CANCELED: 'CANCELED'
} as const

export type CarpayStatusType = (typeof CARPAY_STATUS)[keyof typeof CARPAY_STATUS]

export const CARPAY_ORDER_PAYMENT_TYPE = {
    CASHLESS: 'credit',
    CASH: 'cash',
    STRIPE: 'stripe',
    FREE: 'free',
    FREE_ERROR_MQTT: 'free_error_mqtt'
}

export const CARPAY_ORDER_USER_INTERFACE = {
    TERMINAL: 'terminal',
    WEB: 'web'
}

export const WhitelistStatus = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    DELETED: 'deleted'
}
export const WhitelistScope = {
    LOCAL: 'local',
    GLOBAL: 'global'
}

export enum CASE_CHARGE_TYPES {
    BASE_FINE = 'base_fine',
    PAID_PARKING_UNPAID_ORDER = 'paid_parking_unpaid_order',
    REMINDER_FEE = 'reminder_fee',
    FIRST_WARNING_FEE = 'first_warning_fee',
    SECOND_WARNING_FEE = 'second_warning_fee',
    OWNER_REQUEST_FEE = 'owner_request_fee',
    CROSS_ORIGIN_FEE = 'cross_origin_fee',
    EXTENDED_CAR_OWNER_RESEARCH_CHARGE = 'extended_car_owner_research_charge',
    EXTENDED_ADDRESS_RESEARCH_CHARGE = 'extended_address_research_charge',
    ADMINISTRATION_FEE = 'administration_fee'
}

export const FEE_CHARGES = [CASE_CHARGE_TYPES.BASE_FINE, CASE_CHARGE_TYPES.PAID_PARKING_UNPAID_ORDER]

export const CHARGES_BY_COLUMN = {
    FEE: FEE_CHARGES,
    REMINDER_CHARGES: Object.values(CASE_CHARGE_TYPES).filter((charge) => !FEE_CHARGES.includes(charge))
}

export const VIOLATION_CASE_CHARGES_TYPES = [
    'base_fine',
    'paid_parking_unpaid_order',
    'administration_fee',
    'owner_request_fee',
    'reminder_fee',
    'first_warning_fee',
    'second_warning_fee',
    'cross_origin_fee',
    'extended_car_owner_research_charge',
    'extended_address_research_charge'
]

export interface IScannedCode {
    carpay_discount_model_id: number
    code: string
    extractor_result: any
    validated: boolean
    value: number
    __typename?: string
}

export interface IWhitelistPaidParking {
    plate: string
    country: string
    lot_id: string
    start_date: string
    end_date: string
    scope: string
    status: string
}

export interface ICarpayOrder {
    plate: string
    id?: number
    lot_id?: string
    country: string
    start_date: string
    end_date: string
    payment: any
    whitelist: IWhitelistPaidParking
    carpay_scanned_codes?: IScannedCode
    status: string
    price: number
    order_id: string
    invoice_id: string
    price_valid_until: string
}

export enum SPECIAL_LETTER_STATUSES {
    PREPROCESSING = 'preprocessing',
    GENERATED_LETTER = 'generated_letter',
    GENERATION_FAILED = 'generation_failed',
    DELETED = 'deleted',
    SENT = 'sent'
}
