import { useApolloClient, useQuery } from '@apollo/client'
import { getFragmentData } from 'parkdepot-shared/gql'
import React, { useCallback, useState } from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import StyledWrapper from '@components/style/StyledWrapper.style'
import OverlayProvider from '../../components/Overlays/OverlayContextProvider'
import CARPAY_GET_ORDERS, { OrderFragment } from '../../graphql/queries/carpayGetOrders.query'
import Cancel from '../Cancel'
import Payment from '../Payment'
import SuccessView from '../SuccessView'
import OrdersListingContextProvider, { OrdersListingContextProps, StayOrLeaveDecision } from './ordersListingContext'
import OrdersListingView from './ordersListingView'

const Orders = ({ plate }: { plate: string }) => {
    const { path } = useRouteMatch()
    const location = useLocation()
    const client = useApolloClient()
    // TODO: This is not a proper solution. If in the future we add another queryparam after coupon we are fuckedup.
    // Proper solution is to use new URLSearchParams(location.search) -> We cannot do this now because the URL is not encoded on the backend when generated
    // https://www.notion.so/002-524-Problem-with-Subsidization-Flyer-on-Webapp-25e797beb383405fb2fbbb3a8321e44d
    const couponCode = location.search.split('coupon=')[1] ?? null
    const [stayDecision, setStayDecision] = useState<StayOrLeaveDecision>(StayOrLeaveDecision.notSelected)

    // Even though it has the return_free in false, it's retrieving the free orders which are open (not the UNPAID ones)
    const {
        data,
        loading: orderLoading,
        refetch
    } = useQuery(CARPAY_GET_ORDERS, {
        variables: {
            plate,
            return_free: false
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    const attachDiscount = useCallback(
        (orderId: string, appliedDiscount: number, appliedDiscountCodes: string[]) => {
            client.cache.updateFragment(
                {
                    id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
                    fragment: OrderFragment
                },
                (data) =>
                    data && { ...data, applied_discount: appliedDiscount, applied_discount_codes: appliedDiscountCodes }
            )
        },
        [client]
    )

    const handleDecisionChange = (decision: StayOrLeaveDecision) => {
        setStayDecision(decision)
    }

    const orders = getFragmentData(OrderFragment, data?.carpay_get_orders || [])

    // Compute the first order in the lists that has no discount applied and is >0€
    const firstOrderWithoutCode = orders.find((element) => element.price > 0 && !element.applied_discount)

    const context: OrdersListingContextProps = {
        orders: data?.carpay_get_orders || [],
        couponCode,
        orderIdToApplyCode: firstOrderWithoutCode?.order_id,
        stayDecision, // we map the decisions made on every order
        onDecisionChange: handleDecisionChange,
        loading: orderLoading,
        plate,
        attachDiscount,
        refetch
    }

    return (
        <OrdersListingContextProvider context={context}>
            <StyledWrapper>
                <OverlayProvider>
                    <Switch>
                        <Route key="main" exact path={path} component={OrdersListingView} />
                        <Route key="payment" exact path={`${path}/:id/payment`} component={Payment} />
                        <Route key="success" path={`${path}/success`} component={SuccessView} />
                        <Route key="cancel" path={`${path}/cancel`} component={Cancel} />
                    </Switch>
                </OverlayProvider>
            </StyledWrapper>
        </OrdersListingContextProvider>
    )
}

const OrdersView = () => {
    const location = useLocation()
    const queryparams = new URLSearchParams(location.search)
    const plate = queryparams.get('plate')

    if (!plate) {
        return <Redirect to="/" />
    }

    return <Orders plate={plate} />
}

export default OrdersView
