import { getDomainExtension } from 'parkdepot-shared/utils/getDomainExtension'

export const getContactInfoByDomainExtension = (domainExtension?: string) => {
    const currentDomainExtension = domainExtension || getDomainExtension()

    switch (currentDomainExtension) {
        case 'ch':
            return {
                email: 'help@wemolo.ch',
                phone: '+41 43 588 07 71'
            }
        case 'at':
            return {
                email: 'help@wemolo.at',
                phone: '+43 720 116211'
            }
        default:
            return {
                email: 'help@wemolo.de',
                phone: '+49 89 6931 464 91'
            }
    }
}
