import { Spinner } from 'baseui/spinner'
import { DateTime } from 'luxon'
import { FullOrderFragment } from 'parkdepot-shared/gql/graphql'
import { styled, useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { dateToLocaleString } from '../../helpers/dateToLocaleString'
import { formatPriceWithCurrency } from '../../helpers/formatPriceWithCurrency'
import getDerivedValuesFromOrder from '../../helpers/getDerivedValuesFromOrder'
import { shouldRedirectUserToEmailInput } from '../../helpers/stepsUtils'
import usePolicyContextMapper from '../../hooks/usePolicyContextMapper'
import { useOrdersStore } from '../../views/Orders/ordersListingContext'
import OrderAfterPaymentInfo from '../OrderAfterPaymentInfo'
import OrderStep from '../OrderStepper/types'

const HorizontalLine = styled('div', ({ $theme }) => ({
    width: '100%',
    display: 'inline-block',
    height: '1px',
    backgroundColor: $theme.colors.accent300,
    margin: 'auto'
}))

const ParkingText = styled('p', ({ $theme }) => ({
    ...$theme.typography.LabelSmall,
    color: $theme.colors.mono400
}))

const MiniOrderInfoParkingText = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphSmall
}))

const ParkingTextValue = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphSmall,
    color: $theme.colors.black
}))

const ParkingTextDiscountValue = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphSmall,
    color: $theme.colors.positive
}))

const BolderParkingText = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphMedium,
    fontWeight: 700,
    color: $theme.colors.black
}))

const TotalValueText = styled('p', ({ $theme }) => ({
    ...$theme.typography.LabelLarge
}))

const TotalTaxTextValue = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphXSmall,
    alignSelf: 'flex-end'
}))

const TotalValueWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale200
}))

const CardInfoWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale700,
    width: '100%',
    backgroundColor: `${$theme.colors.mono100}`,
    padding: `${$theme.sizing.scale800} ${$theme.sizing.scale900}`,
    borderRadius: $theme.borders.radius300
}))

const TextRow = styled('div', () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}))

const TextSection = styled('section', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: $theme.sizing.scale300
}))

interface OrderTotalInfoProps {
    hidePrice: boolean
    whitelistDiscount: number
    appliedDiscount: number
    currency: string
    totalPrice: number
    tax: number
    vat: number
}

const OrderTotalInfo = ({
    hidePrice,
    whitelistDiscount,
    appliedDiscount,
    currency,
    totalPrice,
    tax,
    vat
}: OrderTotalInfoProps) => {
    const { t } = useTranslation(['orders'])

    return (
        <>
            <TextSection
                $style={{ display: hidePrice || (whitelistDiscount === 0 && !appliedDiscount) ? 'none' : 'block' }}
            >
                {whitelistDiscount > 0 ? (
                    <TextRow>
                        <ParkingTextDiscountValue>
                            {t('orders.orderCard.discount')} - {t('orders.orderCard.freeParkingTime')}
                        </ParkingTextDiscountValue>
                        <ParkingTextDiscountValue>
                            {hidePrice ? '-' : `-${formatPriceWithCurrency(currency).format(whitelistDiscount)}`}
                        </ParkingTextDiscountValue>
                    </TextRow>
                ) : null}

                {appliedDiscount ? (
                    <TextRow data-testid="order-card-discount">
                        <ParkingTextDiscountValue>
                            {t('orders.orderCard.discount')} - {t('orders.orderCard.parkingVoucher')}
                        </ParkingTextDiscountValue>
                        <ParkingTextDiscountValue data-testid="order-card-discount-value">
                            {hidePrice ? '-' : `-${formatPriceWithCurrency(currency).format(appliedDiscount)}`}
                        </ParkingTextDiscountValue>
                    </TextRow>
                ) : null}
            </TextSection>

            <TotalValueWrapper>
                <TextRow>
                    <TotalValueText>{t('orders.orderCard.total')}</TotalValueText>
                    <TotalValueText data-testid="order-fullprice">
                        {hidePrice ? '-' : formatPriceWithCurrency(currency).format(totalPrice)}
                    </TotalValueText>
                </TextRow>

                <TotalTaxTextValue data-testid="order-tax">
                    {t('orders.orderCard.taxText', {
                        vat: vat.toFixed(2),
                        vatAmount: hidePrice ? '-' : formatPriceWithCurrency(currency).format(tax)
                    })}
                </TotalTaxTextValue>
            </TotalValueWrapper>
        </>
    )
}

export interface OrderInfoProps {
    order: FullOrderFragment
    step: OrderStep
}

const OrderInfo = ({ order, step }: OrderInfoProps) => {
    const { t } = useTranslation(['orders'])
    const [css, theme] = useStyletron()
    const { stayDecision } = useOrdersStore()

    const {
        vat,
        tax,
        order_id: orderId,
        endDate,
        startDate,
        priceValidUntil,
        insideParkingLot,
        whitelistDiscount,
        currency,
        totalPrice,
        applied_discount: appliedDiscount,
        isTollRoadLot
    } = getDerivedValuesFromOrder({
        order
    })

    const policies = usePolicyContextMapper(orderId)
    const hidePrice = step === OrderStep.PrepayFlow || !shouldRedirectUserToEmailInput(order, stayDecision, policies)
    const userOutsideLot = !insideParkingLot
    const now = DateTime.now()

    let endText = ''
    if (isTollRoadLot) {
        endText = t('orders.orderCard.ticketValidUntil')
    } else if (!userOutsideLot) {
        // If you are inside the parking lots
        endText = t('orders.orderCard.onGoingParking')
    } else {
        // If you are outside the parking lot
        endText = t('orders.orderCard.end')
    }

    let endValue = ''
    if (isTollRoadLot) {
        endValue = `1x ${t('orders.orderCard.passage')}`
    } else if (!userOutsideLot) {
        // If you are inside the parking lot
        endValue = dateToLocaleString(now)
    } else {
        // If you are ouside the parking lot
        endValue = dateToLocaleString(endDate || priceValidUntil)
    }

    return (
        <CardInfoWrapper>
            <TextRow className={css({ justifyContent: 'flex-start', gap: theme.sizing.scale300 })}>
                <BolderParkingText>
                    {t('orders.orderCard.transactionNr')}: {orderId}
                </BolderParkingText>
            </TextRow>

            <HorizontalLine />

            <TextSection>
                <TextRow>
                    <ParkingText>{t('orders.orderCard.start')}</ParkingText>
                    <ParkingTextValue>{dateToLocaleString(startDate)}</ParkingTextValue>
                </TextRow>

                <TextRow>
                    <ParkingText data-testid="order-info-end-text">{endText}</ParkingText>
                    <ParkingTextValue data-testid="order-info-end-value">{endValue}</ParkingTextValue>
                </TextRow>
            </TextSection>

            <HorizontalLine />

            <OrderTotalInfo
                tax={tax}
                currency={currency}
                appliedDiscount={appliedDiscount}
                whitelistDiscount={whitelistDiscount}
                totalPrice={totalPrice}
                hidePrice={hidePrice}
                vat={vat}
            />

            <OrderAfterPaymentInfo />
        </CardInfoWrapper>
    )
}

interface MiniOrderInfoProps extends OrderTotalInfoProps {
    loading: boolean
    validUntil: DateTime
}

const MiniOrderInfo = ({
    loading,
    validUntil,
    totalPrice,
    currency,
    vat,
    tax,
    whitelistDiscount,
    appliedDiscount,
    hidePrice
}: MiniOrderInfoProps) => {
    const { t } = useTranslation(['orders'])
    const [, theme] = useStyletron()

    return (
        <CardInfoWrapper $style={{ gap: theme.sizing.scale300 }}>
            {!loading ? (
                <>
                    <TextRow>
                        <MiniOrderInfoParkingText>{t('orders.orderCard.ticketValidUntil')}</MiniOrderInfoParkingText>
                        <MiniOrderInfoParkingText>
                            {hidePrice ? '-' : dateToLocaleString(validUntil)}
                        </MiniOrderInfoParkingText>
                    </TextRow>

                    <OrderTotalInfo
                        hidePrice={hidePrice}
                        whitelistDiscount={whitelistDiscount}
                        currency={currency}
                        appliedDiscount={appliedDiscount}
                        totalPrice={totalPrice}
                        vat={vat}
                        tax={tax}
                    />
                </>
            ) : (
                <Spinner
                    $style={{ alignSelf: 'center' }}
                    $color={theme.colors.primary}
                    data-testid="mini-order-loading"
                />
            )}
        </CardInfoWrapper>
    )
}

export { MiniOrderInfo, OrderInfo }
