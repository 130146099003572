import { useApolloClient } from '@apollo/client'
import { OrderFragment } from '../graphql/queries/carpayGetOrders.query'

const useGetOrderFromCache = (orderId: string) => {
    const client = useApolloClient()

    return client.cache.readFragment({
        id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
        fragment: OrderFragment
    })
}

export default useGetOrderFromCache
