import { styled } from 'baseui'
import { ExtendedTheme, useStyletron, withStyle } from 'parkdepot-shared/theme'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import BackSubheader from '../../components/BackSubheader/BackSubheader'
import CardBackground from '../../components/style/CardBackground.styles'
import StyledWrapper from '../../components/style/StyledWrapper.style'
import { getContactInfoByDomainExtension } from './utils/getContactInfoByDomainExtension'

const Heading = styled('h2', ({ $theme }) => ({
    ...$theme.typography.HeadingMedium,
    marginTop: 0
}))

const StyledCardBackground = withStyle(CardBackground, ({ $theme }: { $theme: ExtendedTheme }) => ({
    padding: $theme.sizing.scale1400,
    gap: $theme.sizing.scale1200
}))

const ContactInfoWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale1200,
    justifyContent: 'space-between'
}))

const ContactInfoText = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphLarge
}))

const InnerWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
    paddingTop: $theme.sizing.scale1600
}))

interface ContactProps {
    errorMessage?: ReactNode
}

const Contact = ({ errorMessage }: ContactProps) => {
    const { t } = useTranslation(['feedback'])
    const [css, theme] = useStyletron()

    const { email, phone } = getContactInfoByDomainExtension()

    return (
        <StyledWrapper>
            <InnerWrapper>
                <BackSubheader />
                <StyledCardBackground>
                    <Heading>{t('feedback.contactUs.heading')}</Heading>

                    {errorMessage}

                    <ContactInfoWrapper>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i
                                className={`wemolo-mail-open ${css({
                                    color: theme.colors.primary,
                                    marginRight: theme.sizing.scale600,
                                    fontSize: theme.sizing.scale800
                                })}`}
                            />
                            <ContactInfoText>{email}</ContactInfoText>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i
                                className={`wemolo-phone-call ${css({
                                    color: theme.colors.primary,
                                    marginRight: theme.sizing.scale600,
                                    fontSize: theme.sizing.scale800
                                })}`}
                            />
                            <ContactInfoText>{phone}</ContactInfoText>
                        </div>
                    </ContactInfoWrapper>
                </StyledCardBackground>
            </InnerWrapper>
        </StyledWrapper>
    )
}

export default Contact
