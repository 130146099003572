import { FullOrderFragment } from 'parkdepot-shared/gql/graphql'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import Button from '../Button'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import Voucher from '../Voucher'
import ActionsWrapper from '../style/ActionsWrapper.style'

interface PostpayActionButtonsProps {
    order: FullOrderFragment
    onPayButtonClick: () => void
}

const PostpayActionButtons = ({ order, onPayButtonClick }: PostpayActionButtonsProps) => {
    const { t } = useTranslation(['orders'])

    return (
        <ActionsWrapper>
            <Button data-testid="payButton" $size={BUTTON_SIZE.large} kind="primary" onClick={onPayButtonClick}>
                {t('orders.orderCard.pay')}
            </Button>

            <Voucher order={order} />
        </ActionsWrapper>
    )
}

export default PostpayActionButtons
