import React from 'react'
import { useTranslation } from 'react-i18next'
import { ValueWithUUID } from '@hooks/useUserInputStore'
import { PolicyContextOption } from '../../hooks/usePolicyContextMapper'
import FormControl from '../FormControl'
import Select from '../Select'

interface VehicleTypeSelectProps {
    onChange: (value: ValueWithUUID) => void
    value: ValueWithUUID | null
    options: PolicyContextOption[]
}

const PolicyContextSelect = ({ onChange, value, options }: VehicleTypeSelectProps) => {
    const { t } = useTranslation(['common', 'payment'])

    return (
        <div>
            <FormControl label={t('vehicleType', { ns: 'payment' })}>
                <Select
                    overrides={{
                        Input: { props: { 'data-testid': 'user-input-context-select', readOnly: true } },
                        Root: { props: { 'data-testid': 'user-input-context-select-root' } }
                    }}
                    value={value ?? []}
                    onChange={(options) => onChange(options.value as ValueWithUUID)} // We need to do this... BaseUI does not support generics
                    searchable={false}
                    clearable={false}
                    placeholder={t('selectVehicleType', { ns: 'payment' })}
                    options={options}
                    labelKey="title"
                    valueKey="id"
                />
            </FormControl>
        </div>
    )
}

export default PolicyContextSelect
