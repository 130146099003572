import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND } from 'parkdepot-shared/components/Buttons/types'
import { styled, useStyletron } from 'parkdepot-shared/theme'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import SearchInput from '@components/SearchInput'
import LicensePlateInputExplanationModal from '../../components/LicensePlateInputExplanationModal'

const MainWrapper = styled('div', ({ $theme }) => ({
    flex: 1,
    display: 'flex',
    background: `linear-gradient(to bottom right, ${$theme.colors.primary}, ${$theme.colors.primary300})`,
    borderBottomLeftRadius: '40px',
    borderBottomRightRadius: '40px',
    alignItems: 'flex-start'
}))

const MainOverlay = styled('div', ({ $theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: $theme.colors.black,
    zIndex: '-1'
}))

const MainInnerWrapper = styled('div', ({ $theme }) => ({
    paddingTop: 'calc(80px + 140px)', // header height + extra padding
    flex: 1,
    gap: $theme.sizing.scale700,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}))

const ContentWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: $theme.sizing.scale1600,
    maxWidth: 'unset',
    paddingRight: $theme.sizing.scale700,
    paddingLeft: $theme.sizing.scale700,

    [$theme.mediaQuery.large]: {
        maxWidth: '896px',
        paddingRight: 'unset',
        paddingLeft: 'unset'
    }
}))

const Heading = styled('h1', ({ $theme }) => ({
    color: $theme.colors.white,
    padding: 'unset',

    [$theme.mediaQuery.small]: {
        ...$theme.typography.HeadingXLarge
    },

    [$theme.mediaQuery.large]: {
        ...$theme.typography.DisplayMedium
    }
}))

const Main = () => {
    const [plate, setPlate] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [, theme] = useStyletron()

    const history = useHistory()
    let couponCode =
        new URLSearchParams(window.location.search).get('code') ?? new URLSearchParams(window.location.search).get('c')
    // couponCode is base64 encoded and might contain of '+' and '/'.
    // Since '+' is replaced to ' ' in URLSearchParams.get we have to replace it back.
    // We have to do nothing with '/'
    couponCode = couponCode ? couponCode.replace(/ /g, '+') : couponCode
    const { t } = useTranslation(['home'])

    const handlePlateChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const input = event.currentTarget.value.replace(/[^a-zA-Z0-9äÄöÖüÜ]/g, '').toLocaleUpperCase()

        setPlate(input)
    }

    const handleSubmit = () => {
        if (plate && plate.length > 0) {
            history.push({
                pathname: '/orders',
                search: `?plate=${plate}${couponCode ? `&coupon=${couponCode}` : ''}`
            })
        }
    }

    const handleModalOpen = () => {
        setIsModalOpen(true)
    }

    const handleModalClose = () => {
        setIsModalOpen(false)
    }

    return (
        <MainWrapper>
            <MainOverlay />
            <MainInnerWrapper>
                <ContentWrapper>
                    <Heading>{t('home.main.heading')}</Heading>

                    <SearchInput
                        value={plate}
                        onValueChange={handlePlateChange}
                        onSubmit={handleSubmit}
                        placeholder={t('home.main.inputLabel')}
                    />
                </ContentWrapper>

                <Button
                    $kind={BUTTON_KIND.ghost}
                    onClick={handleModalOpen}
                    overrides={{
                        BaseButton: {
                            style: {
                                color: '#FFFFFFCC',
                                ...theme.typography.ParagraphMedium,

                                ':hover': {
                                    color: theme.colors.white
                                }
                            }
                        }
                    }}
                >
                    {t('home.main.help')}
                </Button>

                <LicensePlateInputExplanationModal isOpen={isModalOpen} onClose={handleModalClose} />
            </MainInnerWrapper>
        </MainWrapper>
    )
}

export default Main
