import { styled, useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { useHistory } from 'react-router-dom'

const SubheaderWrapper = styled('div', ({ $theme }) => ({
    position: 'absolute',
    top: $theme.sizing.scale800,
    display: 'flex',
    cursor: 'pointer',
    fontSize: $theme.sizing.scale800,
    alignSelf: 'flex-start'
}))

interface BackSubheaderProps {
    onClick?: () => void
}

const BackSubheader = ({ onClick }: BackSubheaderProps) => {
    const [css, theme] = useStyletron()
    const history = useHistory()

    const goHome = () => {
        history.replace('/')
    }

    const defaultBehaviourOnClick = onClick || goHome

    return (
        <SubheaderWrapper onClick={defaultBehaviourOnClick}>
            <i className={`${css({ color: theme.colors.primary })} wemolo-arrow-left`} />
        </SubheaderWrapper>
    )
}

export default BackSubheader
