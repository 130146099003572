import { styled } from 'baseui'

const ParkingCardsTitleContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    marginBottom: $theme.sizing.scale500
}))

export default ParkingCardsTitleContainer
