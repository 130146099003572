import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import LicensePlateInput from 'parkdepot-shared/components/LPInput'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import CardBackground from '../style/CardBackground.styles'
import Success from '../Success'
import ButtonsWrapper from './style/ButtonsWrapper.style'
import DescriptionList from './style/DescriptionList.style'
import DescriptionListItem from './style/DescriptionListItem.style'
import DescriptionTitle from './style/DescriptionTitle.style'
import DescriptionWrapper from './style/DescriptionWrapper.style'
import Title from './style/Title.style'
import { ILicensePlateProps } from './types'

const NoPlateFound: FC<ILicensePlateProps> = ({ plate }) => {
    const [confirmation, setConfirmation] = useState(false)
    const { t } = useTranslation(['orders'])
    const history = useHistory()

    const cancelBtnClicked = (): void => {
        history.goBack()
    }

    return confirmation ? (
        <Success success={null} plate={plate} />
    ) : (
        <CardBackground>
            <Title>{t('noPlateFound.title')}</Title>
            <LicensePlateInput readOnly value={plate} />
            <DescriptionWrapper>
                <DescriptionTitle>{t('noPlateFound.descriptionTitle')}</DescriptionTitle>
                <DescriptionList>
                    <DescriptionListItem>{t('noPlateFound.reason1')}</DescriptionListItem>
                    <DescriptionListItem>{t('noPlateFound.reason2')}</DescriptionListItem>
                    <DescriptionListItem>{t('noPlateFound.reason3')}</DescriptionListItem>
                    <DescriptionListItem>{t('noPlateFound.reason4')}</DescriptionListItem>
                    <DescriptionListItem>{t('noPlateFound.reason5')}</DescriptionListItem>
                </DescriptionList>
            </DescriptionWrapper>
            <ButtonsWrapper>
                <Button onClick={cancelBtnClicked} $kind={BUTTON_KIND.primary} $size={BUTTON_SIZE.medium}>
                    {t('noPlateFound.correct')}
                </Button>
                <Button onClick={() => setConfirmation(true)} $kind={BUTTON_KIND.secondary} $size={BUTTON_SIZE.medium}>
                    {t('noPlateFound.confirm')}
                </Button>
            </ButtonsWrapper>
        </CardBackground>
    )
}

export default NoPlateFound
