import { BaseProvider } from 'baseui'
import React, { ReactNode } from 'react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'

import theme from '..'

const engine = new Styletron()
interface ThemeProviderProps {
    children: ReactNode
    styletronConfiguration?: Styletron
}

export const ThemeProvider = ({ children, styletronConfiguration }: ThemeProviderProps) => {
    return (
        <StyletronProvider value={styletronConfiguration ? styletronConfiguration : engine}>
            <BaseProvider theme={theme}>{children}</BaseProvider>
        </StyletronProvider>
    )
}
