import { Button, StyledBaseButton } from 'baseui/button'
import { Input, StyledInput, StyledInputContainer, StyledRoot } from 'baseui/input'
import { styled, useStyletron, withStyle } from 'parkdepot-shared/theme'
import React, { ChangeEvent, useEffect, useRef } from 'react'

const SearchInputContainer = styled('div', () => ({
    maxWidth: '450px',
    width: '100%',
    position: 'relative'
}))

const InputRoot = withStyle(StyledRoot, () => ({
    borderTopRightRadius: '35px',
    borderBottomRightRadius: '35px',
    borderTopLeftRadius: '35px',
    borderBottomLeftRadius: '35px',
    border: 'none',
    backgroundColor: '#F6F6F61A'
}))

const BaseInputContainer = withStyle(StyledInputContainer, ({ $theme }) => ({
    height: '70px',
    backgroundColor: 'transparent',
    color: 'white',
    ...$theme.typography.ParagraphLarge
}))

const BaseInput = withStyle(StyledInput, ({ $theme }) => ({
    paddingLeft: $theme.sizing.scale800,
    color: $theme.colors.white,
    caretColor: $theme.colors.white,

    '::placeholder': {
        color: '#FFFFFF80'
    }
}))

const BaseButton = withStyle(StyledBaseButton, ({ $theme }) => ({
    backgroundColor: $theme.colors.white,
    position: 'absolute',
    right: '0',
    top: '0',
    height: '70px',
    width: '70px'
}))

interface SearchInputProps {
    value: string
    placeholder: string
    onValueChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onSubmit: () => void
}

const SearchInput = ({ value = '', onValueChange, placeholder, onSubmit }: SearchInputProps) => {
    const [css, theme] = useStyletron()
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)

    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    return (
        <SearchInputContainer>
            <form onSubmit={onSubmit}>
                <Input
                    maxLength={10}
                    inputRef={inputRef}
                    value={value}
                    onChange={onValueChange}
                    overrides={{ Root: InputRoot, InputContainer: BaseInputContainer, Input: BaseInput }}
                    placeholder={placeholder}
                />
                <Button
                    data-testid="searchLPButton"
                    shape="circle"
                    overrides={{ BaseButton }}
                    type="submit"
                    disabled={!value}
                >
                    <i
                        className={`wemolo-search ${css({
                            fontSize: theme.sizing.scale800,
                            color: theme.colors.black
                        })}`}
                    />
                </Button>
            </form>
        </SearchInputContainer>
    )
}

export default SearchInput
