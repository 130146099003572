import { styled, withStyle } from 'parkdepot-shared/theme'

const CircleIcon = styled('i', ({ $theme }) => ({
    color: $theme.colors.positive,
    fontSize: '64px',
    marginTop: '20px',

    [$theme.mediaQuery.small]: {
        fontSize: '33px'
    }
}))

const CircleIconSuccess = withStyle(CircleIcon, ({ $theme }) => ({
    color: $theme.colors.positive
}))

const CircleIconWarning = withStyle(CircleIcon, ({ $theme }) => ({
    color: $theme.colors.warning
}))

const CircleIconFailed = withStyle(CircleIcon, ({ $theme }) => ({
    color: $theme.colors.negative
}))

export { CircleIconFailed, CircleIconSuccess, CircleIconWarning }
