import React from 'react'
import { useOrderStore } from '../../context/OrderProvider'
import { StayOrLeaveDecision, useOrdersStore } from '../../views/Orders/ordersListingContext'
import PrepaidActionButtons from '../PrepayActions/PrepaymentActionButtons'
import OrderStep from './types'

const PrepaySelection = () => {
    const { onDecisionChange } = useOrdersStore()
    const setStep = useOrderStore('setStep')

    const handleStay = () => {
        onDecisionChange(StayOrLeaveDecision.stay)

        setStep(OrderStep.PostpayFlow)
    }

    const handleLeave = async () => {
        onDecisionChange(StayOrLeaveDecision.leave)

        setStep(OrderStep.PostpayFlow)
    }

    return <PrepaidActionButtons onStay={handleStay} onLeave={handleLeave} />
}

export default PrepaySelection
