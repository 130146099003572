import React from 'react'
import { DanishIcon, EnglishIcon, FrenchIcon, GermanIcon, ItalianIcon, PolishIcon } from '../../assets/icons'
import './index.css'

export const LanguageSelectOptions = [
    {
        label: (
            <div className="language-option">
                <EnglishIcon />
                &nbsp;&nbsp;English
            </div>
        ),
        id: 'en'
    },
    {
        label: (
            <div className="language-option">
                <GermanIcon />
                &nbsp;&nbsp;Deutsch
            </div>
        ),
        id: 'de'
    },
    {
        label: (
            <div className="language-option">
                <FrenchIcon />
                &nbsp;&nbsp;Francais
            </div>
        ),
        id: 'fr'
    },
    {
        label: (
            <div className="language-option">
                <ItalianIcon />
                &nbsp;&nbsp;Italiano
            </div>
        ),
        id: 'it'
    },
    {
        label: (
            <div className="language-option">
                <PolishIcon />
                &nbsp;&nbsp;Polski
            </div>
        ),
        id: 'pl'
    },
    {
        label: (
            <div className="language-option">
                <DanishIcon />
                &nbsp;&nbsp;Dansk
            </div>
        ),
        id: 'da'
    }
]
