import { StatefulTooltip } from 'baseui/tooltip'
import { getFragmentData } from 'parkdepot-shared/gql'
import { CARPAY_STATUS } from 'parkdepot-shared/models/database'
import { styled, useStyletron } from 'parkdepot-shared/theme'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useUserInputStore from '@hooks/useUserInputStore'
import BackSubheader from '../../components/BackSubheader/BackSubheader'
import MyPlate from '../../components/MyPlate'
import NoPlateFound from '../../components/NoPlateFound'
import LoadingSpinner from '../../components/style/LoadingSpinner.style'
import ParkingCardsTitle from '../../components/style/ParkingCardsTitle.style'
import ParkingCardsTitleContainer from '../../components/style/ParkingCardsTitleContainer.style'
import { PastParkingTitleTooltip } from '../../components/style/PastParkingTitleTooltip.style'
import OrderProvider from '../../context/OrderProvider'
import { OrderFragment } from '../../graphql/queries/carpayGetOrders.query'
import OrderCard from './orderCard'
import { useOrdersStore } from './ordersListingContext'

const OrderListWrapper = styled('div', ({ $theme }) => ({
    paddingTop: $theme.sizing.scale1600,
    gap: $theme.sizing.scale900,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    position: 'relative'
}))

const OrderList = () => {
    const { orders: ordersFragments, plate } = useOrdersStore()
    const { t } = useTranslation(['orders', 'feedback'])
    const [css, theme] = useStyletron()

    const orders = getFragmentData(OrderFragment, ordersFragments)

    const ordersWithoutEndDate = orders.filter((order) => order.end_date === null)
    const ordersWithEndDate = orders.filter((order) => order.end_date !== null)

    return (
        <OrderListWrapper>
            <BackSubheader />

            <MyPlate plate={plate} />

            {ordersWithEndDate.length > 0 ? (
                <div className={css({ width: '100%' })}>
                    <ParkingCardsTitleContainer>
                        <ParkingCardsTitle> {t('orders.pastParkingsTitle')}</ParkingCardsTitle>
                        <StatefulTooltip
                            accessibilityType="tooltip"
                            content={
                                <PastParkingTitleTooltip>
                                    {t('orders.pastParkingsHoverTooltip')}
                                </PastParkingTitleTooltip>
                            }
                            popoverMargin={1}
                            triggerType="hover"
                            ignoreBoundary
                        >
                            <i className={`${css({ color: theme.colors.primary })} wemolo-info-circle`} />
                        </StatefulTooltip>
                    </ParkingCardsTitleContainer>

                    {ordersWithEndDate.map((order) => {
                        return (
                            <OrderProvider key={order.order_id} order={order}>
                                <OrderCard order={order} />
                            </OrderProvider>
                        )
                    })}
                </div>
            ) : null}

            {ordersWithoutEndDate.length > 0 ? (
                <div className={css({ width: '100%' })}>
                    <ParkingCardsTitleContainer>
                        <ParkingCardsTitle>{t('orders.currentParkingsTitle')}</ParkingCardsTitle>
                    </ParkingCardsTitleContainer>

                    {ordersWithoutEndDate.map((order) => {
                        return (
                            <OrderProvider key={order.order_id} order={order}>
                                <OrderCard
                                    order={order}
                                    disabled={ordersWithEndDate.length > 0} // Only when there is not anything else to pay
                                />
                            </OrderProvider>
                        )
                    })}

                    {ordersWithoutEndDate.length > 0 && ordersWithEndDate.length > 0 ? (
                        <div className={css({ color: theme.colors.negative })}>
                            {t('orders.multipleOrdersExplanation')}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </OrderListWrapper>
    )
}

const OrdersListingView = () => {
    const { orders: ordersFragments, loading, plate } = useOrdersStore()
    const cleanUserInputStore = useUserInputStore((state) => state.cleanUserInputStore)

    /*
        end_date: "2022-01-06T20:00:15.705814+00:00"
        lot_address: "Bleicherstraße 2, 87437 Kempten"
        lot_name: "McD Beer I"
        order_id: "BOBA-0001-0015"
        price: 7402
        price_valid_until: "2022-01-06T20:40:00+00:00"
        start_date: "2021-01-01T19:40:00+00:00"
        stripe_session_id: "cs_test_a1xZhUKhAFUPd7NpxtoD8xdeARtN6kIO599b4JloJI3udrF9dIETCf50gG"
        stripe_url: "https://checkout.stripe.com/pay/cs_test_a1xZhUKhAFUPd7NpxtoD8xdeARtN6kIO59
    */

    useEffect(() => {
        // We clean the store in case there are multiple orders
        cleanUserInputStore()
    }, [cleanUserInputStore])

    // In case we just paid one order and we are going back (browser)
    const orders = getFragmentData(OrderFragment, ordersFragments)
    const filteredOrders = orders.filter((order) => order.order_status !== CARPAY_STATUS.PAID)

    let view = null
    if (loading) view = <LoadingSpinner />
    else if (filteredOrders.length === 0) view = <NoPlateFound plate={plate} />
    else view = <OrderList />

    return view
}

export default OrdersListingView
