export const sizing = (scale: number) => ({
    /** scale * 2 px */
    scale0: scale * 2 + 'px',
    /** scale * 4 px */
    scale100: scale * 4 + 'px',
    /** scale * 6 px */
    scale200: scale * 6 + 'px',
    /** scale * 8 px */
    scale300: scale * 8 + 'px',
    /** scale * 10 px */
    scale400: scale * 10 + 'px',
    /** scale * 12 px */
    scale500: scale * 12 + 'px',
    /** scale * 14 px */
    scale550: scale * 14 + 'px',
    /** scale * 16 px */
    scale600: scale * 16 + 'px',
    /** scale * 18 px */
    scale650: scale * 18 + 'px',
    /** scale * 20 px */
    scale700: scale * 20 + 'px',
    /** scale * 22 px */
    scale750: scale * 22 + 'px',
    /** scale * 24 px */
    scale800: scale * 24 + 'px',
    /** scale * 28 px */
    scale850: scale * 28 + 'px',
    /** scale * 32 px */
    scale900: scale * 32 + 'px',
    /** scale * 36 px */
    scale950: scale * 36 + 'px',
    /** scale * 40 px */
    scale1000: scale * 40 + 'px',
    /** scale * 48 px */
    scale1200: scale * 48 + 'px',
    /** scale * 56 px */
    scale1400: scale * 56 + 'px',
    /** scale * 64 px */
    scale1600: scale * 64 + 'px',
    /** scale * 96 px */
    scale2400: scale * 96 + 'px',
    /** scale * 128 px */
    scale3200: scale * 128 + 'px',
    /** scale * 192 px */
    scale4800: scale * 192 + 'px'
})
