import { Input, InputProps, InternalState } from 'baseui/input'
import React from 'react'
import InnerComponentStyleProps from '../types'

const LeftLPInput = ({ ...props }: InputProps & InternalState) => {
    return (
        <Input
            {...props}
            overrides={{
                Root: {
                    style: ({ $isFocused, $theme, $disabled, $readOnly }: InnerComponentStyleProps) => ({
                        height: '100%',
                        minHeight: '40px',
                        flex: '2 1 0%',
                        backgroundColor: 'transparent',
                        borderLeftWidth: $disabled && !$readOnly ? '0px' : '1px',
                        borderRightWidth: $disabled && !$readOnly ? '0px' : '1px',
                        borderTopWidth: $disabled && !$readOnly ? '0px' : '1px',
                        borderBottomWidth: $disabled && !$readOnly ? '0px' : '1px',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        ':hover': {
                            borderLeftColor: $isFocused
                                ? $theme.colors.primary
                                : $disabled && !$readOnly
                                ? 'transparent'
                                : $theme.colors.primary200,
                            borderRightColor: $isFocused
                                ? $theme.colors.primary
                                : $disabled && !$readOnly
                                ? 'transparent'
                                : $theme.colors.primary200,
                            borderTopColor: $isFocused
                                ? $theme.colors.primary
                                : $disabled && !$readOnly
                                ? 'transparent'
                                : $theme.colors.primary200,
                            borderBottomColor: $isFocused
                                ? $theme.colors.primary
                                : $disabled && !$readOnly
                                ? 'transparent'
                                : $theme.colors.primary200
                        },
                        ...props.overrides?.Root?.style
                    })
                },
                InputContainer: {
                    style: {
                        backgroundColor: 'transparent',
                        borderLeftColor: 'transparent',
                        borderRightColor: 'transparent',
                        borderTopColor: 'transparent',
                        borderBottomColor: 'transparent',
                        height: '100%',
                        ...props.overrides?.InputContainer?.style
                    }
                },
                Input: {
                    props: props.overrides?.Input?.props ?? {},
                    style: ({ $theme, $disabled }: InnerComponentStyleProps) => ({
                        textAlign: 'center',
                        fontWeight: 600,
                        fontSize: '16px',
                        padding: '0',
                        color: `${$theme.colors.contentPrimary} !important`,
                        '-webkit-text-fill-color': 'none !important',
                        opacity: '1',
                        '-webkit-opacity': '1',
                        '::placeholder': {
                            color: $disabled ? '#1F1F1F' : '#E2E2E2'
                        },
                        ...props.overrides?.Input?.style
                    })
                }
            }}
        />
    )
}

export default LeftLPInput
