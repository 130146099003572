import primitives from '../../primitives'

const buttonPrimaryColors = {
    // Primary
    /** {@link primitives.primary} */
    buttonPrimaryFill: primitives.primary,
    /** {@link primitives.white} */
    buttonPrimaryText: primitives.white,
    /** {@link primitives.primary300} */
    buttonPrimaryHover: primitives.primary300,
    /** {@link primitives.white} */
    buttonPrimaryHoverText: primitives.white,
    /** {@link primitives.primary300} */
    buttonPrimaryActive: primitives.primary300,
    /** {@link primitives.primary200} */
    buttonPrimarySelectedFill: primitives.primary200,
    /** {@link primitives.white} */
    buttonPrimarySelectedText: primitives.white,
    /** {@link primitives.mono200} */
    buttonPrimaryDisabledFill: primitives.mono200,
    /** {@link primitives.mono300} */
    buttonPrimaryDisabledText: primitives.mono300,

    //Primary Dark
    /** {@link primitives.white} */
    buttonPrimaryDarkFill: primitives.white,
    /** {@link primitives.primary} */
    buttonPrimaryDarkText: primitives.primary,
    /** {@link primitives.mono200} */
    buttonPrimaryDarkHover: primitives.mono200,
    /** {@link primitives.primary} */
    buttonPrimaryDarkHoverText: primitives.primary,
    /** {@link primitives.mono200} */
    buttonPrimaryDarkActive: primitives.mono200,
    /** {@link primitives.mono200} */
    buttonPrimaryDarkSelectedFill: primitives.mono200,
    /** {@link primitives.primary} */
    buttonPrimaryDarkSelectedText: primitives.primary,
    /** {@link primitives.mono300} */
    buttonPrimaryDarkDisabledFill: primitives.mono300,
    /** {@link primitives.mono400} */
    buttonPrimaryDarkDisabledText: primitives.mono400,

    //Label Primary Button
    /** {@link primitives.primary} */
    buttonLabelPrimaryFill: primitives.primary,
    /** {@link primitives.white} */
    buttonLabelPrimaryText: primitives.white,
    /** {@link primitives.primary200} */
    buttonLabelPrimaryHover: primitives.primary200,
    /** {@link primitives.white} */
    buttonLabelPrimaryHoverText: primitives.white,
    /** {@link primitives.primary200} */
    buttonLabelPrimaryActive: primitives.primary200,
    /** {@link primitives.primary200} */
    buttonLabelPrimarySelectedFill: primitives.primary200,
    /** {@link primitives.white} */
    buttonLabelPrimarySelectedText: primitives.white,
    /** {@link primitives.mono200} */
    buttonLabelPrimaryDisabledFill: primitives.mono200,
    /** {@link primitives.mono400} */
    buttonLabelPrimaryDisabledText: primitives.mono400,

    //Label Primary Dark
    /** {@link primitives.white} */
    buttonLabelPrimaryDarkFill: primitives.white,
    /** {@link primitives.primary} */
    buttonLabelPrimaryDarkText: primitives.primary,
    /** {@link primitives.mono200} */
    buttonLabelPrimaryDarkHover: primitives.mono200,
    /** {@link primitives.primary} */
    buttonLabelPrimaryDarkHoverText: primitives.primary,
    /** {@link primitives.mono200} */
    buttonLabelPrimaryDarkActive: primitives.mono200,
    /** {@link primitives.mono200} */
    buttonLabelPrimaryDarkSelectedFill: primitives.mono200,
    /** {@link primitives.primary} */
    buttonLabelPrimaryDarkSelectedText: primitives.primary,
    /** {@link primitives.mono300} */
    buttonLabelPrimaryDarkDisabledFill: primitives.mono300,
    /** {@link primitives.mono400} */
    buttonLabelPrimaryDarkDisabledText: primitives.mono400,

    //Icon Primary Button
    /** {@link primitives.mono200} */
    buttonIconPrimaryFill: primitives.mono200,
    /** {@link primitives.black} */
    buttonIconPrimaryText: primitives.black,
    /** {@link primitives.mono300} */
    buttonIconPrimaryHover: primitives.mono300,
    /** {@link primitives.black} */
    buttonIconPrimaryHoverText: primitives.black,
    /** {@link primitives.mono300} */
    buttonIconPrimaryActive: primitives.mono300,
    /** {@link primitives.mono300} */
    buttonIconPrimarySelectedFill: primitives.mono300,
    /** {@link primitives.black} */
    buttonIconPrimarySelectedText: primitives.black,
    /** {@link primitives.mono200} */
    buttonIconPrimaryDisabledFill: primitives.mono200,
    /** {@link primitives.mono300} */
    buttonIconPrimaryDisabledText: primitives.mono300,

    //Icon Primary Dark
    /** {@link primitives.mono200} */
    buttonIconPrimaryDarkFill: primitives.mono200,
    /** {@link primitives.black} */
    buttonIconPrimaryDarkText: primitives.black,
    /** {@link primitives.mono300} */
    buttonIconPrimaryDarkHover: primitives.mono300,
    /** {@link primitives.black} */
    buttonIconPrimaryDarkHoverText: primitives.black,
    /** {@link primitives.black} */
    buttonIconPrimaryDarkActive: primitives.black,
    /** {@link primitives.mono300} */
    buttonIconPrimaryDarkSelectedFill: primitives.mono300,
    /** {@link primitives.black} */
    buttonIconPrimaryDarkSelectedText: primitives.black,
    /** {@link primitives.mono200} */
    buttonIconPrimaryDarkDisabledFill: primitives.mono200,
    /** {@link primitives.mono300} */
    buttonIconPrimaryDarkDisabledText: primitives.mono300
}

export default buttonPrimaryColors
