import React, { ReactNode, useRef } from 'react'
import PrepaymentAppliedOverlay, { AnimationConfig, PrepaymentAppliedOverlayHandler } from '../PrepaymentAppliedOverlay'
import OverlayContextProvider from './OverlayContext'

interface OverlayProps {
    children: ReactNode
}

const OverlayProvider = ({ children }: OverlayProps) => {
    const prepaymentOverlayRef = useRef<PrepaymentAppliedOverlayHandler>(null)

    const openPrepaymentAppliedOverlay = (config?: AnimationConfig) => {
        prepaymentOverlayRef.current?.showOverlay(config)
    }

    const context = {
        openPrepaymentAppliedOverlay
    }

    // The idea here is to add as much overlays as we want
    return (
        <OverlayContextProvider context={context}>
            {children}
            <PrepaymentAppliedOverlay ref={prepaymentOverlayRef} />
        </OverlayContextProvider>
    )
}

export default OverlayProvider
