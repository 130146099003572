import { Button, ButtonOverrides } from 'baseui/button'
import { Override } from 'baseui/helpers/overrides'
import { ComponentProps } from 'react'

export enum BUTTON_SIZE {
    compact = 'compact',
    small = 'small',
    medium = 'medium',
    large = 'large'
}

export enum BUTTON_KIND {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    ghost = 'ghost'
}

export enum BUTTON_SHAPE {
    default = 'default',
    pill = 'pill',
    round = 'round',
    square = 'square',
    circle = 'circle'
}

export enum BUTTON_USE {
    default = 'default',
    icon = 'icon',
    label = 'label'
}

export enum BUTTON_KIND_CAPITALIZED {
    primary = 'Primary',
    secondary = 'Secondary',
    tertiary = 'Tertiary',
    ghost = 'Ghost'
}

export enum BUTTON_USE_CAPITALIZED {
    default = 'Default',
    icon = 'Icon',
    label = 'Label'
}

export interface IButton extends Omit<ComponentProps<typeof Button>, '$size' | '$kind' | '$dark' | '$shape' | '$use'> {
    /** Defines the size of the button (small, medium, large) */
    $size?: BUTTON_SIZE
    /** Defines the BUTTON_KIND of the button (primary, secondary, tertiary, ghost) */
    $kind?: BUTTON_KIND
    /** Boolean defining whether the button should be displayed on top of a dark background (true or false by default) */
    $dark?: boolean
    /** Defines the BUTTON_SHAPE of the button (default, pill, round, square, circle) */
    $shape?: BUTTON_SHAPE
    /** Defines the BUTTON_USE of the button (default, icon, label) */
    $use?: BUTTON_USE

    overrides?: ButtonOverrides & { ContentContainer?: Override }

    onClick?: (a: React.SyntheticEvent<HTMLButtonElement>) => unknown
}

export enum BUTTON_TYPE {
    DefaultPrimary = 'Primary',
    DefaultSecondary = 'Secondary',
    DefaultTertiary = 'Tertiary',
    DefaultGhost = 'Ghost',
    LabelPrimary = 'LabelPrimary',
    LabelSecondary = 'Secondary',
    LabelTertiary = 'Tertiary',
    LabelGhost = 'Ghost',
    IconPrimary = 'IconPrimary',
    IconSecondary = 'IconSecondary',
    IconTertiary = 'Tertiary',
    IconGhost = 'Ghost'
}
