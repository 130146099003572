import React from 'react'

import { styled } from 'baseui'
import { useTranslation } from 'react-i18next'
import Contact from '../Contact'

const ErrorMessage = styled('p', ({ $theme }) => ({
    color: $theme.colors.negative,
    textAlign: 'center'
}))

const GlobalErrorView: React.FC = () => {
    const { t } = useTranslation(['feedback'])

    return <Contact errorMessage={<ErrorMessage>{t('feedback.error.description')}</ErrorMessage>} />
}

export default GlobalErrorView
