import React from 'react'
import PaymentStep from '@views/Payment/types'
import ProgressBar from '../ProgressBar'

interface StepperBarProps {
    step: PaymentStep
}

// Improve this in the future to make it smarter. Use the lenght if we want to add more steps
const StepPercentages: { [key in PaymentStep]: number } = {
    [PaymentStep.UserInput]: 0,
    [PaymentStep.EmailInput]: 33.33,
    [PaymentStep.PaymentDropin]: 66.66
}

const StepperBar = ({ step }: StepperBarProps) => {
    const value = StepPercentages[step]

    return <ProgressBar value={value} />
}

export default StepperBar
