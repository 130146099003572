import { Value } from 'baseui/select'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormControl from '../FormControl'
import Select from '../Select'

interface TariffSelectProps {
    onChange: (value: Value) => void
    value: Value | null
    options: { id: string; title: string }[]
}

const TariffSelect = ({ onChange, value, options }: TariffSelectProps) => {
    const { t } = useTranslation(['common', 'payment'])

    return (
        <div>
            <FormControl label={t('tariff', { ns: 'payment' })} data-testid="user-input-form-tariff-select">
                <Select
                    overrides={{
                        Input: { props: { 'data-testid': 'user-input-tariff-select', readOnly: true } },
                        Root: { props: { 'data-testid': 'user-input-tariff-select-root' } }
                    }}
                    clearable={false}
                    value={value ?? []}
                    searchable={false}
                    onChange={(options) => onChange(options.value)}
                    placeholder={t('selectTariff', { ns: 'payment' })}
                    options={options}
                    labelKey="title"
                    valueKey="id"
                />
            </FormControl>
        </div>
    )
}

export default TariffSelect
