import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ActionsWrapper from '../style/ActionsWrapper.style'

interface PrepaidActionButtonsProps {
    onStay: () => void
    onLeave: () => void
    disabled?: boolean
}

const PrepaidActionButtons = ({ onStay, onLeave, disabled }: PrepaidActionButtonsProps) => {
    const { t } = useTranslation('orders')

    return (
        <ActionsWrapper>
            <Button
                data-testid="wantToStayButton"
                $kind={BUTTON_KIND.primary}
                $size={BUTTON_SIZE.large}
                onClick={onStay}
                disabled={disabled}
                startEnhancer={<i className="wemolo-login" />}
            >
                {t('prepayment.wantToStay')}
            </Button>

            <Button
                data-testid="wantToLeaveButton"
                $kind={BUTTON_KIND.primary}
                $size={BUTTON_SIZE.large}
                onClick={onLeave}
                disabled={disabled}
                startEnhancer={<i className="wemolo-logout" />}
            >
                {t('prepayment.wantToLeave')}
            </Button>
        </ActionsWrapper>
    )
}

export default PrepaidActionButtons
