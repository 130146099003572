import { useStyletron } from 'baseui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SwissSvg from '../../../assets/ch_cantons/ch_shield.svg'
import useTouchKeyboard from '../../../utils/hooks/useTouchkeyboard'
import { SWISS_PLATE_EXTRACT } from '../../../utils/licensePlate'
import { getLayoutPerLanguage, layouts, LAYOUT_KEYS, TouchKeyboardContext } from '../../TouchKeyboard'
import { LicensePlateInputContext } from '../LicensePlateInputContext'
import { StyledLPInnerWraper, StyleLPWrapper } from '../StyledComponents'
import LeftLPInput from './LeftLPInput'
import PlateCountryPart from './PlateCountryPart'
import RightLpInput from './RightLPInput'

const convertToPlate = (plateObject: { canton?: string; plate?: string }) => {
    if (!plateObject.plate) {
        return `${plateObject.canton || ''}_`
    } else {
        return `${plateObject.canton || ''}_${plateObject.plate}`
    }
}

const SwissLPInput: React.FunctionComponent = () => {
    const [, theme] = useStyletron()
    const { i18n } = useTranslation()
    const cantonRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const plateRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)

    const {
        value,
        onChange,
        disabled,
        error,
        overrides,
        readOnly,
        withKeyboard,
        setFirstPartEmptyError,
        firstPartEmptyError
    } = React.useContext(LicensePlateInputContext)

    let displayedValue: {
        canton?: string
        plate?: string
    } = {}

    if (value === '' || value.match(SWISS_PLATE_EXTRACT)) {
        displayedValue = {
            canton: '',
            plate: '',
            ...SWISS_PLATE_EXTRACT.exec(value)?.groups
        }
    }
    const { keyboardRef, forceSelectionOfKeyboard } = React.useContext(TouchKeyboardContext)

    const { canton, plate } = displayedValue

    const handleCantonChange = React.useCallback(
        (cantonInput: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('swiss_canton_input') === undefined && withKeyboard) return
            const newCanton = cantonInput.replace(/[^a-z¨]/i, '').toUpperCase()
            if (newCanton.length > 3) {
                forceSelectionOfKeyboard('swiss_canton_input', canton || '')
                return
            }
            onChange(
                convertToPlate({
                    ...displayedValue,
                    canton: newCanton
                })
            )
            if (withKeyboard && keyboardRef?.current?.getInput('swiss_canton_input') !== newCanton) {
                forceSelectionOfKeyboard('swiss_canton_input', newCanton)
            }
        },
        [canton, plate]
    )

    const handlePlateLastPartChange = React.useCallback(
        (input: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('swiss_plateLastpart_input') === undefined && withKeyboard) return
            if (input.length > 7) {
                forceSelectionOfKeyboard('swiss_plateLastpart_input', plate || '')
                return
            }
            const newLastPartValue = input
                .replace(/[^0-9UZ]/i, '')
                .replace(/(?:U|Z)*(\d+)+(?:(U|Z){0,1}(\d+)*(U|Z){0,1})/i, '$1$3$2')
                .toUpperCase()
            const convertedPlate = convertToPlate({
                ...displayedValue,
                plate: newLastPartValue
            })
            const { plate: newLastPart } = {
                plate: '',
                ...SWISS_PLATE_EXTRACT.exec(convertedPlate)?.groups
            }
            const newFormattedLastPart = newLastPart.replace(/_/g, '')
            if (newFormattedLastPart !== newLastPartValue && withKeyboard && keyboardRef?.current) {
                forceSelectionOfKeyboard('swiss_plateLastpart_input', newFormattedLastPart)
                return
            }
            onChange(convertedPlate)
            if (withKeyboard && keyboardRef?.current?.getInput('swiss_plateLastpart_input') !== newFormattedLastPart) {
                forceSelectionOfKeyboard('swiss_plateLastpart_input', newFormattedLastPart)
            }
        },
        [canton, plate]
    )

    const layoutName =
        getLayoutPerLanguage(i18n.language) === LAYOUT_KEYS.DEFAULT
            ? LAYOUT_KEYS.INT
            : getLayoutPerLanguage(i18n.language)

    const { showKeyboard: showCantonKeyboard, hideKeyboard: hideCantonKeyboard } = useTouchKeyboard(
        'swiss_canton_input',
        {
            handler: handleCantonChange,
            defaultValue: canton,
            props: { layout: layouts[layoutName], layoutName: 'license_plate' }
        }
    )
    const { showKeyboard: showPlateLastPartKeyboard, hideKeyboard: hidePlateLastPartKeyboard } = useTouchKeyboard(
        'swiss_plateLastpart_input',
        {
            handler: handlePlateLastPartChange,
            defaultValue: plate ? plate?.replace(/_/g, '') : '',
            props: { layout: layouts[layoutName], layoutName: 'license_plate' }
        }
    )

    React.useEffect(() => {
        const plateInput = plateRef.current
        if (plate) {
            plateInput && plateInput.setSelectionRange(plate.length, plate.length)
        }
    }, [plate])

    React.useEffect(() => {
        const cantonInput = cantonRef.current
        if (canton) {
            cantonInput && cantonInput.setSelectionRange(canton.length, canton.length)
        }
    }, [canton])

    const inputMode = withKeyboard ? 'none' : 'text'

    return (
        <StyleLPWrapper $error={error} $overrides={overrides?.LPContainer}>
            <PlateCountryPart
                // @ts-ignore
                src={SwissSvg}
                width="18px"
                $backgroundColor={theme.colors.white}
                $color={theme.colors.black}
                $overrides={overrides?.PlateCountryPart}
            />
            <StyledLPInnerWraper $overrides={overrides?.LPInnerContainer}>
                <LeftLPInput
                    id="swiss_canton_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={cantonRef}
                    value={displayedValue.canton || ''}
                    placeholder="AA"
                    disabled={disabled}
                    error={error}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-poland/city-code',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    maxLength={2}
                    onFocus={() => {
                        withKeyboard && showCantonKeyboard()
                    }}
                    onBlur={() => {
                        withKeyboard && hideCantonKeyboard()
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleCantonChange(e.target.value)
                    }}
                />
                <RightLpInput
                    id="swiss_plateLastpart_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={plateRef}
                    value={displayedValue.plate || ''}
                    placeholder="12345"
                    disabled={disabled}
                    error={error || firstPartEmptyError}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-poland/city-code',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    maxLength={8}
                    onFocus={() => {
                        if (!withKeyboard) return
                        if (canton !== '') {
                            showPlateLastPartKeyboard()
                        } else {
                            setFirstPartEmptyError(true)
                            cantonRef.current?.select()
                            cantonRef.current?.focus()
                            return
                        }
                    }}
                    onBlur={() => {
                        withKeyboard && hidePlateLastPartKeyboard()
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handlePlateLastPartChange(e.target.value)
                    }}
                />
            </StyledLPInnerWraper>
        </StyleLPWrapper>
    )
}

export default SwissLPInput
