import React from 'react'
import { WemoloIcon } from '../../assets/icons'
import { useStyletron } from '../../theme'
import { LanguageSelectOptions, Select } from '../Select'

export interface FooterLink {
    label: string
    clicked: () => void
}
export interface FooterProps {
    links?: Array<FooterLink>
    selectedLanguage?: string
    onLanguageChange?: (value: string) => void
    languages?: Array<{ label: React.ReactNode; id: string }>
}

export const Footer = ({
    links,
    onLanguageChange,
    selectedLanguage,
    languages = [...LanguageSelectOptions]
}: FooterProps) => {
    const [css, theme] = useStyletron()
    const mobileBreakpoint = 840
    const currentWidth = window.innerWidth
    const isViewPortMobile = currentWidth < mobileBreakpoint

    let selectedOptionIndex = selectedLanguage ? LanguageSelectOptions.findIndex((e) => e.id === selectedLanguage) : 0
    if (selectedOptionIndex === -1) selectedOptionIndex = 0

    return (
        <div
            className={css({
                backgroundColor: 'black',
                padding: '2rem 1rem',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',

                [theme.mediaQuery.large]: {
                    padding: '2.5rem 4rem'
                }
            })}
        >
            <div>
                <WemoloIcon $dark={false} />
            </div>
            <div className={css({ maxWidth: '200px', marginTop: '20px' })}>
                <Select
                    $dark={false}
                    options={languages}
                    value={{
                        value: LanguageSelectOptions[selectedOptionIndex].label,
                        id: LanguageSelectOptions[selectedOptionIndex].id
                    }}
                    onChange={(newValue) => onLanguageChange && onLanguageChange(newValue[0].id as string)}
                />
            </div>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: isViewPortMobile ? '80px' : '40px',
                    maxWidth: '100%',
                    flexWrap: 'wrap'
                })}
            >
                <div
                    className={css({
                        fontFamily: 'Albert Sans',
                        fontSize: '16px',
                        fontWeight: 700,

                        color: 'white',
                        minWidth: '160px !important;'
                    })}
                >
                    © 2024 Wemolo GmbH
                </div>
                <div
                    className={css({
                        display: 'flex',
                        width: '80%',
                        justifyContent: isViewPortMobile ? 'flex-start' : 'center'
                    })}
                >
                    {links?.map((link) => (
                        <div
                            key={link.label}
                            onClick={link.clicked}
                            className={css({
                                color: 'white',
                                fontWeight: 300,
                                fontSize: '14px',
                                fontFamily: 'Albert Sans',
                                lineHeight: '19.6px',
                                opacity: 0.7,
                                marginTop: isViewPortMobile ? '14px' : '0px',
                                marginRight: isViewPortMobile ? '1rem' : '3rem',
                                ':hover': {
                                    opacity: 1,
                                    cursor: 'pointer'
                                }
                            })}
                        >
                            {link.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
