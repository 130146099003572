import primitives from '../../primitives'

const buttonGhostColor = {
    //Ghost Button
    /** transparent */
    buttonGhostFill: 'transparent',
    /** {@link primitives.primary} */
    buttonGhostText: primitives.primary,
    /** transparent */
    buttonGhostHover: 'transparent',
    /** {@link primitives.primary300} */
    buttonGhostHoverText: primitives.primary300,
    /** transparent */
    buttonGhostActive: 'transparent',
    /** transparent */
    buttonGhostSelectedFill: 'transparent',
    /** {@link primitives.primary} */
    buttonGhostSelectedText: primitives.primary,
    /** transparent */
    buttonGhostDisabledFill: 'transparent',
    /** {@link primitives.mono300} */
    buttonGhostDisabledText: primitives.mono300
}

export default buttonGhostColor
