import { graphql } from 'parkdepot-shared/gql'

const GET_CHECKOUT_SESSION = graphql(`
    query CarpayCheckoutSession(
        $order_id: String!
        $email: String!
        $language: String
        $selected_parking_tariff: String
        $parking_context_uuid: String
    ) {
        carpay_checkout_session(
            order_id: $order_id
            email: $email
            language: $language
            selected_parking_tariff: $selected_parking_tariff
            parking_context_uuid: $parking_context_uuid
        ) {
            adyen_id
            adyen_session_data
            stripe_session_id
            stripe_url
            price
            currency
            country_code
        }
    }
`)

export default GET_CHECKOUT_SESSION
