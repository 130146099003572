import { primitives } from '../primitives'
import button from './button'
import input from './input'

export const colors = {
    ...button,
    ...input,
    /** {@link primitives.black} */
    contentPrimary: primitives.black
}
