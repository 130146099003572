import { SetupWorker, setupWorker, graphql, rest } from 'msw'
import { Page } from '@playwright/test'

import { buildHandlersContext } from './handlers'
import { RestHandlerData, GraphQLHandlerData } from './types'
import { Data } from './fakeData'

export class DispatchContainer {
    dispatch = async (page: Page, data: Data) => {
        const { rawDataGraphql, rawDataRestHandlerData } = buildHandlersContext(data)
        await page.evaluate(
            async ([rawDataGraphql, rawDataRestHandlerData]) => {
                const { worker } = window
                await worker.recreateHandlers(rawDataGraphql, rawDataRestHandlerData)
            },
            [rawDataGraphql, rawDataRestHandlerData] as const
        )
    }
}

export interface ExtendedWorker extends SetupWorker {
    recreateHandlers(graphqlData: Array<GraphQLHandlerData>, restHandlerData: Array<RestHandlerData>): void
}

const createWorker = () => {
    const worker = setupWorker() as ExtendedWorker
    // This function is needed because:
    // we need to recreate the handlers to be able to inject them in the browser
    // for that either the whole code chunk is serializable or you expose the functions in the window
    // as neither worked, I created the function and we just serialize the very basic raw data for the queries
    // and recreate the handlers from inside the browser
    worker.recreateHandlers = function recreateHandlers(
        graphqlData: Array<GraphQLHandlerData>,
        restHandlerData: Array<RestHandlerData>
    ) {
        const buildgraphQLHandlers = () => {
            return graphqlData.map((q) => {
                if (q.type === 'QUERY') return graphql.query(q.name, (req, res, ctx) => res(ctx.data(q.data)))
                return graphql.mutation(q.name, (req, res, ctx) => res(ctx.data(q.data)))
            })
        }

        const buildRestHandlers = () => {
            return restHandlerData.map((q) => {
                if (q.method === 'POST') return rest.post(q.url, (req, res, ctx) => res(ctx.json(q.response)))
                // get for now extend if needed (path put etc)
                return rest.get(q.url, (req, res, ctx) => res(ctx.json(q.response)))
            })
        }
        const handlers = buildgraphQLHandlers()
        const restHandlers = buildRestHandlers()
        this.resetHandlers(...[...handlers, ...restHandlers])
    }
    return worker
}

export default createWorker
