import React from 'react'
import { primitives } from '../../theme/primitives'

export const GermanIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_4538_4037"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="19"
                height="20"
            >
                <circle cx="9.5" cy="9.49988" r="9.5" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4538_4037)">
                <rect y="-0.00012207" width="19" height="6.33333" fill="black" />
                <rect y="6.33319" width="19" height="6.33333" fill={primitives.negative} />
                <rect y="12.6666" width="19" height="6.33333" fill="#FFC043" />
            </g>
        </svg>
    )
}

export const EnglishIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_4538_4040"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="19"
                height="20"
            >
                <circle cx="9.5" cy="9.49988" r="9.5" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4538_4040)">
                <rect
                    x="19.7916"
                    y="-0.00012207"
                    width="19"
                    height="19.7917"
                    transform="rotate(90 19.7916 -0.00012207)"
                    fill="#174291"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-0.792023 15.4126L6.96481 10.9343L8.72595 11.7457L-0.000355832 16.7838L-0.792023 15.4126Z"
                    fill={primitives.negative}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-0.000392109 13.4583L4.35352 11.0365L7.34626 11.0365L0.0262351 15.5004L-0.000392109 13.4583Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.60426 15.6449L6.66347 12.4927L7.31892 12.6684L1.414 16.355L1.60426 15.6449Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-0.494883 3.02511L7.26184 7.50357L7.43976 9.43445L-1.28655 4.39631L-0.494883 3.02511Z"
                    fill={primitives.negative}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.59335 2.73359L6.72864 5.54155L7.36401 7.88508L-0.16182 3.77768L1.59335 2.73359Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.502023 5.2166L5.76147 8.02193L5.9371 8.67738L-0.208047 5.40686L0.502023 5.2166Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.7954 15.4125L12.0386 10.9342L10.2775 11.7456L19.0038 16.7837L19.7954 15.4125Z"
                    fill={primitives.negative}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.0037 13.4582L14.6498 11.0364L11.6571 11.0364L18.9771 15.5003L19.0037 13.4582Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.3991 15.6448L12.3399 12.4927L11.6844 12.6683L17.5894 16.3549L17.3991 15.6448Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.4983 3.02511L11.7416 7.50357L11.5637 9.43445L20.29 4.39631L19.4983 3.02511Z"
                    fill={primitives.negative}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.4101 2.73359L12.2748 5.54155L11.6394 7.88508L19.1652 3.77768L17.4101 2.73359Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.5014 5.2166L13.2419 8.02193L13.0663 8.67738L19.2115 5.40686L18.5014 5.2166Z"
                    fill="white"
                />
                <mask id="path-15-inside-1_4538_4040" fill="white">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.666 6.33323V-1.58343L6.33262 -1.58344L6.33262 6.33323L-1.58404 6.33323L-1.58405 12.6666L6.33262 12.6666L6.33262 20.5832H12.666V12.6666L20.5826 12.6666V6.33323L12.666 6.33323Z"
                    />
                </mask>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.666 6.33323V-1.58343L6.33262 -1.58344L6.33262 6.33323L-1.58404 6.33323L-1.58405 12.6666L6.33262 12.6666L6.33262 20.5832H12.666V12.6666L20.5826 12.6666V6.33323L12.666 6.33323Z"
                    fill={primitives.negative}
                />
                <path
                    d="M12.666 -1.58343H14.166V-3.08343L12.666 -3.08343V-1.58343ZM12.666 6.33323H11.166V7.83323H12.666V6.33323ZM6.33262 -1.58344V-3.08344L4.83262 -3.08344V-1.58344L6.33262 -1.58344ZM6.33262 6.33323V7.83323H7.83262V6.33323H6.33262ZM-1.58404 6.33323L-1.58404 4.83323H-3.08404L-3.08404 6.33323H-1.58404ZM-1.58405 12.6666H-3.08405L-3.08405 14.1666H-1.58405L-1.58405 12.6666ZM6.33262 12.6666H7.83262V11.1666H6.33262V12.6666ZM6.33262 20.5832L4.83262 20.5832V22.0832H6.33262V20.5832ZM12.666 20.5832V22.0832H14.166V20.5832H12.666ZM12.666 12.6666V11.1666H11.166V12.6666H12.666ZM20.5826 12.6666V14.1666H22.0826V12.6666H20.5826ZM20.5826 6.33323H22.0826V4.83323H20.5826V6.33323ZM11.166 -1.58343V6.33323H14.166V-1.58343H11.166ZM6.33262 -0.0834351L12.666 -0.0834348V-3.08343L6.33262 -3.08344V-0.0834351ZM7.83262 6.33323L7.83262 -1.58343L4.83262 -1.58344L4.83262 6.33323H7.83262ZM-1.58405 7.83323L6.33262 7.83323V4.83323L-1.58404 4.83323L-1.58405 7.83323ZM-0.0840454 12.6666L-0.0840449 6.33323H-3.08404L-3.08405 12.6666H-0.0840454ZM6.33262 11.1666L-1.58405 11.1666L-1.58405 14.1666L6.33262 14.1666V11.1666ZM7.83262 20.5832L7.83262 12.6666H4.83262L4.83262 20.5832L7.83262 20.5832ZM12.666 19.0832H6.33262V22.0832H12.666V19.0832ZM11.166 12.6666V20.5832H14.166V12.6666H11.166ZM20.5826 11.1666L12.666 11.1666V14.1666L20.5826 14.1666V11.1666ZM19.0826 6.33323V12.6666H22.0826V6.33323H19.0826ZM12.666 7.83323L20.5826 7.83323V4.83323L12.666 4.83323V7.83323Z"
                    fill="white"
                    mask="url(#path-15-inside-1_4538_4040)"
                />
            </g>
        </svg>
    )
}

export const FrenchIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_4938_4049"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="19"
                height="20"
            >
                <circle cx="9.5" cy="9.49988" r="9.5" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4938_4049)">
                <rect
                    x="18.9999"
                    y="-6.10352e-05"
                    width="19"
                    height="6.33333"
                    transform="rotate(90 18.9999 -6.10352e-05)"
                    fill={primitives.negative}
                />
                <rect
                    x="12.6665"
                    y="-6.10352e-05"
                    width="19"
                    height="6.33333"
                    transform="rotate(90 12.6665 -6.10352e-05)"
                    fill="white"
                />
                <rect
                    x="6.33319"
                    y="-6.10352e-05"
                    width="19"
                    height="6.33333"
                    transform="rotate(90 6.33319 -6.10352e-05)"
                    fill={primitives.primary}
                />
            </g>
        </svg>
    )
}

export const DanishIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_4538_4049"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="19"
                height="20"
            >
                <circle cx="9.5" cy="9.49988" r="9.5" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4538_4049)">
                <rect y="-0.00012207" width="19" height="19" fill={primitives.negative} />
                <rect x="19" y="7.9165" width="3.16667" height="19" transform="rotate(90 19 7.9165)" fill="white" />
                <rect
                    x="7.91669"
                    y="18.9998"
                    width="3.16667"
                    height="19"
                    transform="rotate(-180 7.91669 18.9998)"
                    fill="white"
                />
            </g>
        </svg>
    )
}

export const ItalianIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_4138_4049"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="19"
                height="20"
            >
                <circle cx="9.5" cy="9.49988" r="9.5" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_4138_4049)">
                <rect
                    x="18.9999"
                    y="-6.10352e-05"
                    width="19"
                    height="6.33333"
                    transform="rotate(90 18.9999 -6.10352e-05)"
                    fill={primitives.negative}
                />
                <rect
                    x="12.6665"
                    y="-6.10352e-05"
                    width="19"
                    height="6.33333"
                    transform="rotate(90 12.6665 -6.10352e-05)"
                    fill="white"
                />
                <rect
                    x="6.33319"
                    y="-6.10352e-05"
                    width="19"
                    height="6.33333"
                    transform="rotate(90 6.33319 -6.10352e-05)"
                    fill="#039855"
                />
            </g>
        </svg>
    )
}

export const PolishIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_4538_4052"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="19"
                height="20"
            >
                <circle cx="9.5" cy="9.49988" r="9" fill="#D9D9D9" stroke="black" />
            </mask>
            <g mask="url(#mask0_4538_4052)">
                <path
                    d="M9.5 0.249755C14.6086 0.249756 18.75 4.39112 18.75 9.49976L18.75 10.0414L0.25 10.0414L0.25 9.49976C0.25 4.39112 4.39137 0.249755 9.5 0.249755Z"
                    fill="white"
                    stroke="#E2E2E2"
                    strokeWidth="0.5"
                />
                <rect y="9.49976" width="19" height="9.5" fill={primitives.negative} />
            </g>
        </svg>
    )
}
