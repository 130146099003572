import { Modal, ModalBody } from 'baseui/modal'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { useStyletron } from 'parkdepot-shared/theme'
import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import FormControl from '../FormControl'
import Input from '../Input'
import StyledModalHeader from '../style/StyledModalHeader.style'

export interface AddVoucherModalProps {
    isOpen: boolean
    errorMessage: string
    inputValue: string
    loading: boolean
    onClose: () => void
    onSubmit: () => void
    onVoucherChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const AddVoucherModal: FC<AddVoucherModalProps> = ({
    isOpen,
    errorMessage,
    inputValue,
    loading,
    onClose,
    onSubmit,
    onVoucherChange
}) => {
    const [css, theme] = useStyletron()
    const { t } = useTranslation(['orders'])

    const isButtonDisabled = !inputValue

    return (
        <Modal
            onClose={onClose}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            overrides={{
                Dialog: {
                    style: ({ $theme }) => ({
                        width: '73%',
                        height: '375px',
                        backgroundColor: 'white',

                        [$theme.mediaQuery.small]: {
                            width: '90%',
                            height: '90vh'
                        }
                    })
                }
            }}
        >
            <StyledModalHeader data-testid="voucher-modal-title">
                {t('orders.orderCard.addVoucherCode')}
            </StyledModalHeader>
            <ModalBody
                $style={{
                    flex: '1 1 0',
                    padding: '0 3rem',

                    [theme.mediaQuery.small]: {
                        padding: '0'
                    }
                }}
            >
                <p style={{ marginBottom: '50px' }}>{t('orders.modal.description')}</p>
                <form
                    className={css({ flexDirection: 'column', display: 'flex' })}
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault()

                        onSubmit()
                    }}
                >
                    <FormControl error={errorMessage}>
                        <Input
                            data-testid="voucher-input"
                            value={inputValue}
                            onChange={onVoucherChange}
                            placeholder={t('orders.modal.placeholder')}
                            error={!!errorMessage}
                        />
                    </FormControl>

                    <Button
                        $kind={BUTTON_KIND.primary}
                        $size={BUTTON_SIZE.large}
                        isLoading={loading}
                        disabled={isButtonDisabled}
                        overrides={{
                            StartEnhancer: {
                                style: { fontSize: theme.sizing.scale800 }
                            }
                        }}
                        startEnhancer={<i className="wemolo-search" />}
                        type="submit"
                    >
                        {t('orders.modal.redeem')}
                    </Button>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AddVoucherModal
