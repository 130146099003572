import primitives from '../../primitives'

export default {
    //Secondary Button

    /** transparent */
    buttonSecondaryFill: 'transparent',

    /** {@link primitives.primary} */
    buttonSecondaryText: primitives.primary,

    /** {@link primitives.primary} */
    buttonSecondaryHover: primitives.primary,

    /** {@link primitives.white} */
    buttonSecondaryHoverText: primitives.white,

    /** {@link primitives.primary} */
    buttonSecondaryActive: primitives.primary,

    /** {@link primitives.primary} */
    buttonSecondarySelectedFill: primitives.primary,

    /** {@link primitives.white} */
    buttonSecondarySelectedText: primitives.white,

    /** transparent */
    buttonSecondaryDisabledFill: 'transparent',

    /** {@link primitives.mono300} */
    buttonSecondaryDisabledText: primitives.mono300,

    //Secondary Dark

    /** transparent */
    buttonSecondaryDarkFill: 'transparent',

    /** {@link primitives.white} */
    buttonSecondaryDarkText: primitives.white,

    /** {@link primitives.white} */
    buttonSecondaryDarkHover: primitives.primary50,

    /** {@link primitives.black} */
    buttonSecondaryDarkHoverText: primitives.black,

    /** {@link primitives.white} */
    buttonSecondaryDarkActive: primitives.white,

    /** {@link primitives.primary200} */
    buttonSecondaryDarkSelectedFill: primitives.black,

    /** {@link primitives.white} */
    buttonSecondaryDarkSelectedText: primitives.black,

    /** {@link primitives.mono300} */
    buttonSecondaryDarkDisabledFill: primitives.mono300,

    /** {@link primitives.mono400} */
    buttonSecondaryDarkDisabledText: primitives.mono400,

    //Icon Secondary Button

    /** transparent */
    buttonIconSecondaryFill: 'transparent',

    /** {@link primitives.primary} */
    buttonIconSecondaryText: primitives.primary,

    /** transparent */
    buttonIconSecondaryHover: 'transparent',

    /** {@link primitives.primary300} */
    buttonIconSecondaryHoverText: primitives.primary300,

    /** transparent */
    buttonIconSecondaryActive: 'transparent',

    /** {@link primitives.accent100} */
    buttonIconSecondarySelectedFill: primitives.accent100,

    /** {@link primitives.primary200} */
    buttonIconSecondarySelectedText: primitives.primary200,

    /** transparent */
    buttonIconSecondaryDisabledFill: 'transparent',

    /** {@link primitives.accent100} */
    buttonIconSecondaryDisabledText: primitives.accent100,

    //Icon Secondary Dark

    /** transparent */
    buttonIconSecondaryDarkFill: 'transparent',

    /** {@link primitives.primary} */
    buttonIconSecondaryDarkText: primitives.primary,

    /** transparent */
    buttonIconSecondaryDarkHover: 'transparent',

    /** {@link primitives.primary300} */
    buttonIconSecondaryDarkHoverText: primitives.primary300,

    /** {@link primitives.accent100} */
    buttonIconSecondaryDarkActive: primitives.accent100,

    /** {@link primitives.accent100} */
    buttonIconSecondaryDarkSelectedFill: primitives.accent100,

    /** {@link primitives.primary200} */
    buttonIconSecondaryDarkSelectedText: primitives.primary200,

    /** transparent */
    buttonIconSecondaryDarkDisabledFill: 'transparent',

    /** {@link primitives.accent100} */
    buttonIconSecondaryDarkDisabledText: primitives.accent100
}
