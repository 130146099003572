import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getDomainExtension } from 'parkdepot-shared/utils/getDomainExtension'
import Routes from './routes'

const App: React.FC = () => {
    const { i18n } = useTranslation()

    useEffect(() => {
        const domain = getDomainExtension()
        if (domain === 'ch') {
            i18n.changeLanguage('de-CH')
        }
    }, [i18n])

    return <Routes />
}

export default App
