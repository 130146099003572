import { styled } from 'baseui'

const MessageWrapper = styled('div', ({ $theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    gap: $theme.sizing.scale1200,
    height: '100%'
}))

export default MessageWrapper
