import { graphql } from 'parkdepot-shared/gql'

const CARPAY_CALCULATE_ORDER_PRICE = graphql(`
    query CarpayCalculateOrderPrice(
        $order_id: String!
        $selected_parking_tariff: String!
        $parking_context_uuid: String
    ) {
        carpay_calculate_order_price(
            order_id: $order_id
            selected_parking_tariff: $selected_parking_tariff
            parking_context_uuid: $parking_context_uuid
        ) {
            order_id
            price
            price_valid_until
            full_price
            billable
            billable_start
            billable_end
        }
    }
`)

export default CARPAY_CALCULATE_ORDER_PRICE
