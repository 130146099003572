import { ModalHeader } from 'baseui/modal'
import { withStyle } from 'parkdepot-shared/theme'

const StyledModalHeader = withStyle(ModalHeader, ({ $theme }) => ({
    fontSize: '42px',
    padding: '2rem 3rem',

    [$theme.mediaQuery.small]: {
        fontSize: '24px',
        padding: '0'
    },

    [$theme.mediaQuery.medium]: {
        fontSize: '32px'
    }
}))

export default StyledModalHeader
