import { styled } from 'baseui'

const ActionsWrapper = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'flex-end',
    width: '100%'
}))

export default ActionsWrapper
