import primitives from '../../primitives'

export default {
    //Tertiary Button

    /** {@link primitives.primary} */
    buttonTertiaryFill: primitives.primary,

    /** {@link primitives.white} */
    buttonTertiaryText: primitives.white,

    /** {@link primitives.primary300} */
    buttonTertiaryHover: primitives.primary300,

    /** {@link primitives.white} */
    buttonTertiaryHoverText: primitives.white,

    /** {@link primitives.primary300} */
    buttonTertiaryActive: primitives.primary300,

    /** {@link primitives.primary300} */
    buttonTertiarySelectedFill: primitives.primary300,

    /** {@link primitives.white} */
    buttonTertiarySelectedText: primitives.white,

    /** {@link primitives.mono200} */
    buttonTertiaryDisabledFill: primitives.mono200,

    /** {@link primitives.mono300} */
    buttonTertiaryDisabledText: primitives.mono300
}
