import React from 'react'
import useTouchKeyboard from '../../../utils/hooks/useTouchkeyboard'

import { ITALY_PLATE_EXTRACT } from '../../../utils/licensePlate'
import { layouts, TouchKeyboardContext } from '../../TouchKeyboard'
import { LicensePlateInputContext } from '../LicensePlateInputContext'

import { StyledLPInnerWraper, StyleLPWrapper } from '../StyledComponents'
import LeftLPInput from './LeftLPInput'
import PlateCountryPart from './PlateCountryPart'
import RightLpInput from './RightLPInput'

const convertToPlate = (plateObject: { plateFirstPart?: string; plateLastPart?: string }) => {
    if (!plateObject.plateLastPart) {
        return `${plateObject.plateFirstPart || ''}`
    } else {
        return `${plateObject.plateFirstPart || ''}${plateObject.plateLastPart}`
    }
}

const ItalyLPInput: React.FunctionComponent = () => {
    const plateFirstPartRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const plateLastPartRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)

    const {
        value,
        onChange,
        disabled,
        error,
        overrides,
        readOnly,
        withKeyboard,
        firstPartEmptyError,
        setFirstPartEmptyError
    } = React.useContext(LicensePlateInputContext)

    let displayedValue: {
        plateFirstPart?: string
        plateLastPart?: string
    } = {}
    if (value === '' || value.match(ITALY_PLATE_EXTRACT)) {
        displayedValue = {
            plateFirstPart: '',
            plateLastPart: '',
            ...ITALY_PLATE_EXTRACT.exec(value)?.groups
        }
    }

    const { keyboardRef, forceSelectionOfKeyboard } = React.useContext(TouchKeyboardContext)

    const { plateFirstPart, plateLastPart } = displayedValue

    const handlePlateFrstPartChange = React.useCallback(
        (plateFirstPartInput: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('italian_plateFirstPart_input') === undefined && withKeyboard) return
            const newPlateFrstPart = plateFirstPartInput.replace(/[^a-z¨]/i, '').toUpperCase()
            if (newPlateFrstPart.length > 3) {
                forceSelectionOfKeyboard('italian_plateFirstPart_input', plateFirstPart || '')
                return
            }
            onChange(
                convertToPlate({
                    ...displayedValue,
                    plateFirstPart: newPlateFrstPart
                })
            )
            if (withKeyboard && keyboardRef?.current?.getInput('italian_plateFirstPart_input') !== newPlateFrstPart) {
                forceSelectionOfKeyboard('italian_plateFirstPart_input', newPlateFrstPart)
            }
        },
        [plateFirstPart, plateLastPart]
    )

    const handlePlateLastPartChange = React.useCallback(
        (input: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('italian_plateLastpart_input') === undefined && withKeyboard) return
            if (input.length > 5) {
                forceSelectionOfKeyboard('italian_plateLastpart_input', plateLastPart || '')
                return
            }
            const newLastPartValue = input.replace(/[^a-z0-9]/i, '').toUpperCase()
            const convertedPlate = convertToPlate({
                ...displayedValue,
                plateLastPart: newLastPartValue
            })
            const { plateLastPart: newLastPart } = {
                plateLastPart: '',
                ...ITALY_PLATE_EXTRACT.exec(convertedPlate)?.groups
            }
            const newFormattedLastPart = newLastPart.replace(/_/g, '')
            if (newFormattedLastPart !== newLastPartValue && withKeyboard && keyboardRef?.current) {
                forceSelectionOfKeyboard('italian_plateLastpart_input', newFormattedLastPart)
                return
            }
            onChange(convertedPlate)
            if (
                withKeyboard &&
                keyboardRef?.current?.getInput('italian_plateLastpart_input') !== newFormattedLastPart
            ) {
                forceSelectionOfKeyboard('italian_plateLastpart_input', newFormattedLastPart)
            }
        },
        [plateFirstPart, plateLastPart]
    )

    const { showKeyboard: showPlateFrstPartKeyboard, hideKeyboard: hidePlateFrstPartKeyboard } = useTouchKeyboard(
        'italian_plateFirstPart_input',
        {
            handler: handlePlateFrstPartChange,
            defaultValue: plateFirstPart,
            props: { layout: layouts.INT, layoutName: 'license_plate' }
        }
    )
    const { showKeyboard: showPlateLastPartKeyboard, hideKeyboard: hidePlateLastPartKeyboard } = useTouchKeyboard(
        'italian_plateLastpart_input',
        {
            handler: handlePlateLastPartChange,
            defaultValue: plateLastPart ? plateLastPart?.replace(/_/g, '') : '',
            props: { layout: layouts.INT, layoutName: 'license_plate' }
        }
    )

    React.useEffect(() => {
        const plateInput = plateLastPartRef.current
        if (plateLastPart) {
            plateInput && plateInput.setSelectionRange(plateLastPart.length, plateLastPart.length)
        }
    }, [plateLastPart])

    React.useEffect(() => {
        const plateFirstPartInput = plateFirstPartRef.current
        if (plateFirstPart) {
            plateFirstPartInput && plateFirstPartInput.setSelectionRange(plateFirstPart.length, plateFirstPart.length)
        }
    }, [plateFirstPart])

    const inputMode = withKeyboard ? 'none' : 'text'

    return (
        <StyleLPWrapper $error={error} $overrides={overrides?.LPContainer}>
            <PlateCountryPart label="I" $overrides={overrides?.PlateCountryPart} />
            <StyledLPInnerWraper $overrides={overrides?.LPInnerContainer}>
                <LeftLPInput
                    id="italian_plateFirstPart_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={plateFirstPartRef}
                    value={displayedValue.plateFirstPart || ''}
                    placeholder="AB"
                    disabled={disabled}
                    error={error}
                    maxLength={2}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-italy/city-code',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    onFocus={() => {
                        withKeyboard && showPlateFrstPartKeyboard()
                    }}
                    onBlur={() => {
                        withKeyboard && hidePlateFrstPartKeyboard()
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handlePlateFrstPartChange(e.target.value)
                    }}
                />
                <RightLpInput
                    id="italian_plateLastPart_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={plateLastPartRef}
                    value={displayedValue.plateLastPart ? displayedValue.plateLastPart.replace(/_/g, '') : ''}
                    placeholder="123CD"
                    disabled={disabled}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-italy/plate',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    error={error || firstPartEmptyError}
                    onFocus={() => {
                        if (!withKeyboard) return
                        if (plateFirstPart !== '') {
                            showPlateLastPartKeyboard()
                        } else {
                            setFirstPartEmptyError(true)
                            plateFirstPartRef.current?.select()
                            plateFirstPartRef.current?.focus()
                            return
                        }
                    }}
                    onBlur={() => {
                        withKeyboard && hidePlateLastPartKeyboard()
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handlePlateLastPartChange(e.target.value)
                    }}
                    maxLength={5}
                />
            </StyledLPInnerWraper>
        </StyleLPWrapper>
    )
}

export default ItalyLPInput
