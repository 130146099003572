import Joi from '@hapi/joi'

const useEmailValidation = (allowTLD?: string[]) => {
    const emailSchema = Joi.string().email({ tlds: { allow: allowTLD } })

    const validateEmail = (email: string) => {
        const validation = emailSchema.validate(email)
        return validation.error ? false : true
    }
    return validateEmail
}

export default useEmailValidation
