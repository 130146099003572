import { styled } from 'parkdepot-shared/theme'

const CardBackground = styled('div', ({ $theme }) => ({
    backgroundColor: $theme.colors.mono100,
    width: '100%',
    padding: $theme.sizing.scale800,
    margin: '0 auto',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}))

export default CardBackground
