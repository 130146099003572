import { styled } from 'parkdepot-shared/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

const InfoContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    bottom: $theme.sizing.scale950
}))

const InfoText = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphSmall,
    textAlign: 'left'
}))

const OrderAfterPaymentInfo = () => {
    const { t } = useTranslation('orders')

    return (
        <InfoContainer>
            <InfoText>{t('orderCard.afterPaymentInfo')}</InfoText>
        </InfoContainer>
    )
}

export default OrderAfterPaymentInfo
