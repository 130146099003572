import { graphql } from 'parkdepot-shared/gql'

const REDEEM_VOUCHER_CODE = graphql(`
    mutation CarpayApplyDiscountCode($code: String!, $order_id: String!, $parking_context_uuid: String) {
        carpay_apply_discount_code(code: $code, order_id: $order_id, parking_context_uuid: $parking_context_uuid) {
            status
            price
            applied_discount
            applied_discount_codes
            order_status
        }
    }
`)

export default REDEEM_VOUCHER_CODE
