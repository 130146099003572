import { styled } from 'baseui'

const OrderCardWrapper = styled('div', ({ $theme }) => ({
    width: '100%',
    marginBottom: `${$theme.sizing.scale1200}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: $theme.sizing.scale800
}))

export default OrderCardWrapper
