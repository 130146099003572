import { useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { shouldShowPrepaymentDecisionStep } from '@helpers/stepsUtils'
import PaymentStepper from '../../components/PaymentStepper'
import LoadingSpinner from '../../components/style/LoadingSpinner.style'
import useGetOrderFromCache from '../../hooks/useGetOrderFromCache'
import { StayOrLeaveDecision, useOrdersStore } from '../Orders/ordersListingContext'

const Payment = () => {
    const [css, theme] = useStyletron()
    const { id: orderId } = useParams<{ id: string }>()
    const { loading, stayDecision, plate } = useOrdersStore()
    const order = useGetOrderFromCache(orderId)

    if (!order) return <Redirect to="/" />
    if (shouldShowPrepaymentDecisionStep(order) && stayDecision === StayOrLeaveDecision.notSelected) {
        return <Redirect to={`/orders?plate=${plate}`} />
    }
    if (loading) return <LoadingSpinner />

    return (
        <div className={css({ position: 'relative', paddingTop: theme.sizing.scale1600 })}>
            <PaymentStepper order={order} />
        </div>
    )
}

export default Payment
