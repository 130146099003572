import React from 'react'

export interface IMixpanelContext {
    alias: (id: string) => void
    track: (name: string, props?: any) => void
    time_event: (name: string) => void
    people: { set: (props: any) => void }
}

export const MixpanelContext = React.createContext<IMixpanelContext>({
    alias: () => {},
    track: () => {},
    time_event: () => {},
    people: { set: () => {} }
})

interface IMixpanelProvider {
    context: IMixpanelContext
}

const MixpanelProvider: React.FunctionComponent<IMixpanelProvider> = ({ context, children }) => (
    <MixpanelContext.Provider value={context}>{children}</MixpanelContext.Provider>
)

export default MixpanelProvider
