import React from 'react'
import { concatDKPlateParts, DENMARK_PLATE_EXTRACT } from '../../../utils/licensePlate'
import { LicensePlateInputContext } from '../LicensePlateInputContext'

import useTouchKeyboard from '../../../utils/hooks/useTouchkeyboard'
import { layouts, TouchKeyboardContext } from '../../TouchKeyboard'
import { StyledLPInnerWraper, StyleLPWrapper } from '../StyledComponents'
import LeftLPInput from './LeftLPInput'
import PlateCountryPart from './PlateCountryPart'
import RightLpInput from './RightLPInput'

const DanishLPInput: React.FunctionComponent = () => {
    const plateLettersRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const plateDigitsRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)

    const {
        value,
        onChange,
        disabled,
        error,
        overrides,
        readOnly,
        withKeyboard,
        firstPartEmptyError,
        setFirstPartEmptyError
    } = React.useContext(LicensePlateInputContext)

    let displayedValue: {
        plateLetters?: string
        plateDigits?: string
    } = {}

    const formattedValue = value.replace('_', '')

    if (value === '' || formattedValue.match(DENMARK_PLATE_EXTRACT)) {
        displayedValue = {
            plateLetters: '',
            plateDigits: '',
            ...DENMARK_PLATE_EXTRACT.exec(formattedValue)?.groups
        }
    }

    const { keyboardRef, forceSelectionOfKeyboard } = React.useContext(TouchKeyboardContext)

    const { plateLetters, plateDigits } = displayedValue

    const handleLettersChange = React.useCallback(
        (lettersInputs: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('danish_plateLetters') === undefined && withKeyboard) return
            const newPlateLetters = lettersInputs.replace(/[^a-z]/i, '').toUpperCase()
            if (newPlateLetters.length > 2) {
                forceSelectionOfKeyboard('danish_plateLetters', plateLetters || '')
                return
            }
            onChange(
                concatDKPlateParts({
                    ...displayedValue,
                    plateLetters: newPlateLetters
                })
            )
            if (withKeyboard && keyboardRef?.current?.getInput('danish_plateLetters') !== newPlateLetters) {
                forceSelectionOfKeyboard('danish_plateLetters', newPlateLetters)
            }
        },
        [plateLetters, plateDigits]
    )

    const handleDigitsChange = React.useCallback(
        (input: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('danish_plateDigits') === undefined && withKeyboard) return
            const newValue = input.replace(/[^0-9]/i, '').toUpperCase()
            if (input.length > 5 && plateDigits) {
                forceSelectionOfKeyboard('danish_plateDigits', plateDigits)
                return
            }
            const convertedPlate = concatDKPlateParts({
                ...displayedValue,
                plateDigits: newValue
            })
            const { plateDigits: newPlateDigits } = {
                plateDigits: '',
                ...DENMARK_PLATE_EXTRACT.exec(convertedPlate)?.groups
            }
            if (newPlateDigits !== newValue && withKeyboard && keyboardRef?.current) {
                forceSelectionOfKeyboard('danish_plateDigits', newPlateDigits)
                return
            }
            onChange(convertedPlate)
            if (withKeyboard && keyboardRef?.current?.getInput('danish_plateDigits') !== newPlateDigits) {
                forceSelectionOfKeyboard('danish_plateDigits', newPlateDigits)
            }
        },
        [plateLetters, plateDigits]
    )

    const { showKeyboard: showLettersKeyboard, hideKeyboard: hideLettersKeyboard } = useTouchKeyboard(
        'danish_plateLetters',
        {
            handler: handleLettersChange,
            defaultValue: plateLetters,
            props: { layout: layouts.INT, layoutName: 'license_plate' }
        }
    )
    const { showKeyboard: showDigitsKeyboard, hideKeyboard: hideDigitsKeyboard } = useTouchKeyboard(
        'danish_plateDigits',
        {
            handler: handleDigitsChange,
            defaultValue: plateDigits || '',
            props: { layout: layouts.INT, layoutName: 'license_plate' }
        }
    )

    const inputMode = withKeyboard ? 'none' : 'text'

    React.useEffect(() => {
        const plateLettersInput = plateLettersRef.current
        if (plateLetters) {
            plateLettersInput && plateLettersInput.setSelectionRange(plateLetters.length, plateLetters.length)
        }
    }, [plateLetters])

    React.useEffect(() => {
        const plateDigitsInput = plateDigitsRef.current
        if (plateDigits) {
            plateDigitsInput && plateDigitsInput.setSelectionRange(plateDigits.length, plateDigits.length)
        }
    }, [plateDigits])

    return (
        <StyleLPWrapper $error={error} $overrides={overrides?.LPContainer} $disabled={!!disabled}>
            <PlateCountryPart label="DK" $overrides={overrides?.PlateCountryPart} />
            <StyledLPInnerWraper $overrides={overrides?.LPInnerContainer}>
                <LeftLPInput
                    id="danish_plateLetters"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={plateLettersRef}
                    value={displayedValue.plateLetters || ''}
                    placeholder="AA"
                    disabled={disabled}
                    error={error}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-denmark/plateLetters',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    maxLength={2}
                    onFocus={() => {
                        withKeyboard && showLettersKeyboard()
                    }}
                    onBlur={() => withKeyboard && hideLettersKeyboard()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleLettersChange(e.target.value)
                    }}
                />
                <RightLpInput
                    id="danish_plateDigits"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={plateDigitsRef}
                    value={displayedValue.plateDigits || ''}
                    placeholder="12345"
                    disabled={disabled}
                    error={error || firstPartEmptyError}
                    maxLength={5}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-denmark/plateDigits',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    onFocus={() => {
                        if (!withKeyboard) return
                        if (plateLetters !== '') {
                            showDigitsKeyboard()
                        } else {
                            plateLettersRef.current?.select()
                            plateLettersRef.current?.focus()
                            setFirstPartEmptyError(true)
                            return
                        }
                    }}
                    onBlur={() => withKeyboard && hideDigitsKeyboard()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleDigitsChange(e.target.value)
                    }}
                />
            </StyledLPInnerWraper>
        </StyleLPWrapper>
    )
}

export default DanishLPInput
