import React from 'react'
import useTouchKeyboard from '../../../utils/hooks/useTouchkeyboard'
import { POLAND_PLATE_EXTRACT } from '../../../utils/licensePlate'
import { layouts, TouchKeyboardContext } from '../../TouchKeyboard'
import { LicensePlateInputContext } from '../LicensePlateInputContext'

import { StyledLPInnerWraper, StyleLPWrapper } from '../StyledComponents'
import LeftLPInput from './LeftLPInput'
import PlateCountryPart from './PlateCountryPart'
import RightLpInput from './RightLPInput'

const convertToPlate = (plateObject: {
    plateFirstPart?: string
    plateFirstDigits?: string
    plateLastPart?: string
}) => {
    if (!plateObject.plateLastPart) {
        return `${plateObject.plateFirstPart || ''}${plateObject.plateFirstDigits || ''}_`
    } else {
        return `${plateObject.plateFirstPart || ''}${plateObject.plateFirstDigits || ''}_${plateObject.plateLastPart}`
    }
}

const PolandLPInput: React.FunctionComponent = () => {
    const plateFirstPartRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const plateLastPartRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)

    const {
        value,
        onChange,
        disabled,
        error,
        overrides,
        readOnly,
        withKeyboard,
        setFirstPartEmptyError,
        firstPartEmptyError
    } = React.useContext(LicensePlateInputContext)

    let displayedValue: {
        plateFirstPart?: string
        plateFirstDigits?: string
        plateLastPart?: string
    } = {}

    if (value === '' || value.match(POLAND_PLATE_EXTRACT)) {
        displayedValue = {
            plateFirstPart: '',
            plateFirstDigits: '',
            plateLastPart: '',
            ...POLAND_PLATE_EXTRACT.exec(value)?.groups
        }
    }

    const { keyboardRef, forceSelectionOfKeyboard } = React.useContext(TouchKeyboardContext)

    const { plateFirstPart, plateFirstDigits, plateLastPart } = displayedValue

    const handlePlateFrstPartChange = React.useCallback(
        (plateFirstPartInput: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('polish_plateFirstPart_input') === undefined && withKeyboard) return
            const newPlateFirstPartValue = plateFirstPartInput.replace(/[^a-z0-9]/i, '').toUpperCase()
            console.log(newPlateFirstPartValue)
            if (newPlateFirstPartValue.length > 3) {
                forceSelectionOfKeyboard('polish_plateFirstPart_input', plateFirstPart || '')
                return
            }
            const { plateFirstPart: newPlateFirstPart, plateFirstDigits: newPlateFirstDigits } =
                (POLAND_PLATE_EXTRACT.exec(newPlateFirstPartValue)?.groups as {
                    plateFirstPart?: string
                    plateFirstDigits?: string
                    plateLastPart?: string
                }) || {}
            onChange(
                convertToPlate({
                    ...displayedValue,
                    plateFirstPart: newPlateFirstPart || '',
                    plateFirstDigits: newPlateFirstDigits || ''
                })
            )
            if (withKeyboard && keyboardRef?.current?.getInput('polish_plateFirstPart_input') !== newPlateFirstPart) {
                forceSelectionOfKeyboard('polish_plateFirstPart_input', newPlateFirstPart || '')
            }
        },
        [plateFirstPart, plateFirstDigits, plateLastPart]
    )

    const handlePlateLastPartChange = React.useCallback(
        (input: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('polish_plateLastpart_input') === undefined && withKeyboard) return
            if (input.length > 5) {
                forceSelectionOfKeyboard('polish_plateLastpart_input', plateLastPart || '')
                return
            }
            const newLastPartValue = input.replace(/[^a-z0-9]/i, '').toUpperCase()
            const convertedPlate = convertToPlate({
                ...displayedValue,
                plateLastPart: newLastPartValue
            })
            const { plateLastPart: newLastPart } = {
                plateLastPart: '',
                ...POLAND_PLATE_EXTRACT.exec(convertedPlate)?.groups
            }
            const newFormattedLastPart = newLastPart.replace(/_/g, '')
            if (newFormattedLastPart !== newLastPartValue && withKeyboard && keyboardRef?.current) {
                forceSelectionOfKeyboard('polish_plateLastpart_input', newFormattedLastPart)
                return
            }
            onChange(convertedPlate)
            if (withKeyboard && keyboardRef?.current?.getInput('polish_plateLastpart_input') !== newFormattedLastPart) {
                forceSelectionOfKeyboard('polish_plateLastpart_input', newFormattedLastPart)
            }
        },
        [plateFirstPart, plateFirstDigits, plateLastPart]
    )

    const { showKeyboard: showPlateFrstPartKeyboard, hideKeyboard: hidePlateFrstPartKeyboard } = useTouchKeyboard(
        'polish_plateFirstPart_input',
        {
            handler: handlePlateFrstPartChange,
            defaultValue: plateFirstPart,
            props: { layout: layouts.INT, layoutName: 'license_plate' }
        }
    )
    const { showKeyboard: showPlateLastPartKeyboard, hideKeyboard: hidePlateLastPartKeyboard } = useTouchKeyboard(
        'polish_plateLastpart_input',
        {
            handler: handlePlateLastPartChange,
            defaultValue: plateLastPart ? plateLastPart?.replace(/_/g, '') : '',
            props: { layout: layouts.INT, layoutName: 'license_plate' }
        }
    )

    React.useEffect(() => {
        const plateInput = plateLastPartRef.current
        if (plateLastPart) {
            plateInput && plateInput.setSelectionRange(plateLastPart.length, plateLastPart.length)
        }
    }, [plateLastPart])

    React.useEffect(() => {
        const plateFirstPartInput = plateFirstPartRef.current
        if (plateFirstPart) {
            plateFirstPartInput && plateFirstPartInput.setSelectionRange(plateFirstPart.length, plateFirstPart.length)
        }
    }, [plateFirstPart])

    const inputMode = withKeyboard ? 'none' : 'text'

    return (
        <StyleLPWrapper $error={error} $disabled={!!disabled} $overrides={overrides?.LPContainer}>
            <PlateCountryPart label="PL" $overrides={overrides?.PlateCountryPart} />
            <StyledLPInnerWraper $overrides={overrides?.LPInnerContainer}>
                <LeftLPInput
                    id="polish_plateFirstPart_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={plateFirstPartRef}
                    value={`${displayedValue.plateFirstPart || ''}${displayedValue.plateFirstDigits || ''}`}
                    placeholder="AAA"
                    disabled={disabled}
                    error={error}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-poland/city-code',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    maxLength={3}
                    onFocus={() => {
                        withKeyboard && showPlateFrstPartKeyboard()
                    }}
                    onBlur={() => {
                        withKeyboard && hidePlateFrstPartKeyboard()
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handlePlateFrstPartChange(e.target.value)
                    }}
                />
                <RightLpInput
                    id="polish_plateLastPart_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={plateLastPartRef}
                    value={displayedValue.plateLastPart || ''}
                    placeholder="123AA"
                    disabled={disabled}
                    error={error || firstPartEmptyError}
                    maxLength={5}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-poland/plate',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    onFocus={() => {
                        if (!withKeyboard) return
                        if (plateFirstPart !== '') {
                            showPlateLastPartKeyboard()
                        } else {
                            setFirstPartEmptyError(true)
                            plateFirstPartRef.current?.select()
                            plateFirstPartRef.current?.focus()
                            return
                        }
                    }}
                    onBlur={() => {
                        withKeyboard && hidePlateLastPartKeyboard()
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handlePlateLastPartChange(e.target.value)
                    }}
                />
            </StyledLPInnerWraper>
        </StyleLPWrapper>
    )
}

export default PolandLPInput
