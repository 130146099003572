import { styled } from 'baseui'

export const LoadingContainer = styled('div', () => ({
    width: '100%',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#eee'
}))
