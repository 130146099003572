import '@adyen/adyen-web/dist/adyen.css'
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin/Dropin'
import { useStyletron } from 'parkdepot-shared/theme'
import React, { useEffect, useRef } from 'react'

export interface DropinProps {
    dropin: DropinElement
}

const DropIn = ({ dropin }: DropinProps) => {
    const dropInRef = useRef<null | HTMLDivElement>(null)
    const [css] = useStyletron()

    useEffect(() => {
        if (dropInRef.current && !dropin?.dropinRef) {
            dropin?.mount(dropInRef.current)
        }

        return () => {
            dropin?.unmount()
        }
    }, [dropin, dropInRef])

    return (
        <div
            className={`dropin-container ${css({ width: '100%' })}`}
            id="dropin-container"
            ref={dropInRef}
            data-testid="dropinContainer"
        />
    )
}

export default DropIn
