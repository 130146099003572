import { Layer } from 'baseui/layer'
import { ExtendedTheme, styled, useStyletron } from 'parkdepot-shared/theme'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'

const AnimatableWrapper = styled('div', () => ({
    animationFillMode: 'forwards',
    opacity: 1,
    animationName: {
        '0%': {
            opacity: 1
        },
        '65%': {
            opacity: 1
        },
        '100%': {
            opacity: 0
        }
    }
}))

const Wrapper = styled('div', ({ $theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: $theme.colors.white,
    opacity: '90%'
}))

const ContentWrapper = styled('div', () => ({
    paddingTop: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
}))

const Icon = styled('i', ({ $theme }) => ({
    color: $theme.colors.primary,
    fontSize: $theme.sizing.scale1600
}))

const Title = styled('h1', ({ $theme }) => ({
    ...$theme.typography.HeadingMedium,
    maxWidth: '470px',
    textAlign: 'center'
}))

export interface PrepaymentAppliedOverlayHandler {
    showOverlay: (config?: AnimationConfig) => void
}

export interface DefaultAnimationConfig {
    duration: number // secs
    timingFn: keyof ExtendedTheme['animation']
    title: string
}

export interface AnimationConfig {
    duration?: number // secs
    timingFn?: keyof ExtendedTheme['animation']
    title?: string
}

const defaultConfig: DefaultAnimationConfig = {
    duration: 3,
    timingFn: 'easeInOutCurve',
    title: ''
}

const PrepaymentAppliedOverlay = forwardRef<PrepaymentAppliedOverlayHandler, object>((_, ref) => {
    const [css] = useStyletron()
    const { t } = useTranslation('orders')
    const [animConfig, setAnimConfig] = useState<DefaultAnimationConfig & { visible: boolean }>({
        ...defaultConfig,
        visible: false
    })

    useEffect(() => {
        if (!animConfig.visible) return

        const id = setTimeout(() => {
            setAnimConfig((prev) => ({ ...prev, visible: false }))
        }, animConfig.duration * 1000)

        return () => {
            clearTimeout(id)
        }
    }, [animConfig.visible, animConfig.duration])

    useImperativeHandle(
        ref,
        () => {
            return {
                showOverlay: (config) => setAnimConfig((prev) => ({ ...prev, ...config, visible: true }))
            }
        },
        []
    )

    return (
        <Layer>
            <AnimatableWrapper
                className={css({
                    display: animConfig.visible ? 'block' : 'none',
                    animationTimingFunction: animConfig.timingFn,
                    animationDuration: `${animConfig.duration}s`
                })}
            >
                <Wrapper>
                    <ContentWrapper>
                        <Icon className="pkd-check" />
                        <Title>{t('prepayment.ticketSelectionTitle', { ticketTitle: animConfig.title })}</Title>
                    </ContentWrapper>
                </Wrapper>
            </AnimatableWrapper>
        </Layer>
    )
})

PrepaymentAppliedOverlay.displayName = 'PrepaymentAppliedOverlay'
export default PrepaymentAppliedOverlay
