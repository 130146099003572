import buttonGhost from './buttonGhost'
import buttonPrimary from './buttonPrimary'
import buttonSecondary from './buttonSecondary'
import buttonTertiary from './buttonTertiary'

export default {
    ...buttonPrimary,
    ...buttonSecondary,
    ...buttonTertiary,
    ...buttonGhost
}
