import primitives from '../../primitives'

export default {
    /** white */
    inputFill: primitives.white,
    /** mono200 */
    inputBorder: primitives.mono200,
    /** white */
    inputFillError: primitives.white,
    /** red200 */
    inputBorderError: primitives.red200,
    /** mono100 */
    inputFillDisabled: primitives.mono100,
    /** white */
    inputFillActive: primitives.white,
    /** black */
    inputTextDisabled: primitives.black,
    /** black */
    inputEnhancerFill: primitives.black,
    /** black */
    inputEnhancerFillDisabled: primitives.black,
    /** mono300 */
    inputPlaceholder: primitives.mono300,
    /** mono300 */
    inputPlaceholderDisabled: primitives.mono300
}
