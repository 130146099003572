import { ResultCode } from '@adyen/adyen-web/dist/types/components/types'
import { useApolloClient } from '@apollo/client'
import { FullOrderFragment } from 'parkdepot-shared/gql/graphql'
import { CARPAY_STATUS } from 'parkdepot-shared/models/database'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { OrderFragment } from '../../graphql/queries/carpayGetOrders.query'
import DropinContainer from '../Dropin/DropinContainer'
import StepContainer from './styles/StepContainer'
import Title from './styles/Title'

interface PaymetDropinProps {
    order: FullOrderFragment
}

const PaymentDropin = ({ order }: PaymetDropinProps) => {
    const history = useHistory()
    const { t } = useTranslation('payment')
    const { order_id: orderId, plate } = order
    const client = useApolloClient()

    const handleAdyenPaymentCompleted = useCallback(
        (resultCode: ResultCode) => {
            const order = client.cache.readFragment({
                id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
                fragment: OrderFragment
            })

            if (order && resultCode === 'Authorised') {
                const modifiedOrder = { ...order, order_status: CARPAY_STATUS.PAID }

                client.writeFragment({
                    id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
                    fragment: OrderFragment,
                    data: modifiedOrder
                })
            }

            history.replace(`/orders/success?order=${orderId}&plate=${plate}`, {
                success: resultCode
            })
        },
        [history, orderId, plate, client]
    )

    return (
        <StepContainer>
            <Title>{t('paymentMethod')}</Title>
            <DropinContainer order={order} onCompleted={handleAdyenPaymentCompleted} />
        </StepContainer>
    )
}

export default PaymentDropin
