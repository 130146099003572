import { ApolloError } from '@apollo/client'
import { Delete } from 'baseui/icon'
import { useSnackbar } from 'baseui/snackbar'
import { IMixpanelContext, MixpanelContext } from 'parkdepot-shared/views/tracking/MixpanelContext'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const useGraphQLErrorHandler = () => {
    const { t } = useTranslation(['errors'])
    const { enqueue } = useSnackbar()
    const { track } = React.useContext<IMixpanelContext>(MixpanelContext)

    const handleGraphQLError = useCallback(
        (e: ApolloError) => {
            enqueue({
                message: t('errors.genericBackendError'),
                startEnhancer: ({ size }) => <Delete size={size} />
            })
            track('errorGraphQL', { error: e })
        },
        [t, track, enqueue]
    )

    const handleGraphQLErrorWithoutSnackbar = (e: ApolloError) => {
        track('errorGraphQL', { error: e })
    }

    return {
        handleGraphQLError,
        handleGraphQLErrorWithoutSnackbar
    }
}

export default useGraphQLErrorHandler
