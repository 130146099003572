import { graphql } from 'parkdepot-shared/gql'

export const OrderFragment = graphql(`
    fragment FullOrder on Order {
        lot_id
        price
        price_valid_until
        order_id
        lot_name
        lot_address
        start_date
        end_date
        vat_rate
        currency
        applied_discount
        applied_discount_codes
        order_status
        billable
        plate
        full_price
        billable_start
        billable_end
        lot_type
        lot_tariffs
        discount_types
        parking_contexts {
            uuid
            vehicle_type
        }
        sv_lot_type
        visible_toleration_time
        arrival_detection_time
    }
`)

const CARPAY_GET_ORDERS = graphql(`
    query CarpayGetOrders($plate: String!, $return_free: Boolean!) {
        carpay_get_orders(plate: $plate, return_free: $return_free) {
            ...FullOrder
        }
    }
`)

export default CARPAY_GET_ORDERS
