import { FullOrderFragment, LotType } from 'parkdepot-shared/gql/graphql'
import React from 'react'

// components
import FullyWhitelistedOrderCard from '../../components/OrderCard/FullyWhitelistedOrderCard'
import { OrderInfo } from '../../components/OrderInfo'
import OrderStepper from '../../components/OrderStepper'
import OrderCardWrapper from '../../components/style/OrderCardWrapper.style'
import { useOrderStore } from '../../context/OrderProvider'
import getDerivedValuesFromOrder from '../../helpers/getDerivedValuesFromOrder'
import { StayOrLeaveDecision, useOrdersStore } from './ordersListingContext'

interface OrderCardProps {
    disabled?: boolean
    order: FullOrderFragment
}

const OrderCard = ({ disabled, order }: OrderCardProps) => {
    const step = useOrderStore('step')
    const { stayDecision } = useOrdersStore()

    const {
        isFullyWhitelisted,
        lot_type: lotType,
        order_id
    } = getDerivedValuesFromOrder({
        order
    })

    if (
        isFullyWhitelisted &&
        (LotType.Postpaid === lotType ||
            LotType.Hybrid === lotType ||
            (LotType.Prepaid === lotType && stayDecision === StayOrLeaveDecision.leave))
    ) {
        return <FullyWhitelistedOrderCard orderId={order_id} />
    }

    return (
        <OrderCardWrapper data-testid="orderCard">
            <OrderInfo order={order} step={step} />
            {!disabled ? <OrderStepper step={step} order={order} /> : null}
        </OrderCardWrapper>
    )
}

export default OrderCard
