import {
    Select as BUSelect,
    SelectOverrides,
    StyledControlContainer,
    StyledDropdown,
    StyledDropdownListItem,
    StyledPlaceholder,
    StyledRoot
} from 'baseui/select'
import { ExtendedTheme, styled, useStyletron, withStyle } from 'parkdepot-shared/theme'
import React, { ComponentProps } from 'react'

export type BaseSelectProps = ComponentProps<typeof BUSelect>

const SelectRoot = withStyle(StyledRoot, ({ $theme, $error }) => ({
    border: `1px solid ${$theme.colors.accent200}`,
    borderTopLeftRadius: $theme.sizing.scale800,
    borderTopRightRadius: $theme.sizing.scale800,
    borderBottomRightRadius: $theme.sizing.scale800,
    borderBottomLeftRadius: $theme.sizing.scale800,

    ...($error && {
        borderLeftColor: $theme.colors.red700,
        borderRightColor: $theme.colors.red700,
        borderTopColor: $theme.colors.red700,
        borderBottomColor: $theme.colors.red700
    })
}))

const SelectControlContainer = withStyle(StyledControlContainer, ({ $theme, $disabled }) => ({
    backgroundColor: $theme.colors.white,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',

    ...($disabled && {
        backgroundColor: $theme.colors.mono200
    })
}))

const SelectDropdownListItem = withStyle(StyledDropdownListItem, ({ $theme }) => ({
    paddingTop: $theme.sizing.scale500,
    paddingBottom: $theme.sizing.scale500,
    lineHeight: $theme.sizing.scale800,

    ':hover': {
        backgroundColor: $theme.colors.mono100
    }
}))

const SelectPlaceholder = withStyle(StyledPlaceholder, ({ $theme }) => ({
    ...$theme.typography.ParagraphMedium,
    color: $theme.colors.mono300
}))

const SelectDropdown = withStyle(StyledDropdown, ({ $theme }) => ({
    borderRadius: $theme.sizing.scale800
}))

const ArrowIcon = ({ $disabled, $error }: { $disabled: boolean; $error: boolean }) => {
    const [css, theme] = useStyletron()
    return (
        <i
            className={`wemolo-chevron-down ${css({
                fontSize: theme.sizing.scale600,
                cursor: 'pointer',

                ...($disabled && {
                    color: theme.colors.mono300,
                    cursor: 'not-allowed'
                }),

                ...($error && {
                    color: theme.colors.red700
                })
            })}`}
        />
    )
}

export const BaseSelect = ({ overrides, ...props }: BaseSelectProps) => {
    const defaultOverrides: SelectOverrides = {
        ...overrides,
        Root: { component: SelectRoot, ...overrides?.Root },
        Dropdown: { component: SelectDropdown, ...overrides?.Dropdown },
        ControlContainer: { component: SelectControlContainer, ...overrides?.ControlContainer },
        DropdownListItem: { component: SelectDropdownListItem, ...overrides?.DropdownListItem },
        SelectArrow: { component: ArrowIcon, ...overrides?.SelectArrow },
        Placeholder: { component: SelectPlaceholder, ...overrides?.Placeholder }
    }

    return <BUSelect overrides={defaultOverrides} {...props} />
}

BaseSelect.Option = styled<'div', { $isSelected?: boolean }, ExtendedTheme>('div', ({ $isSelected, $theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: $theme.sizing.scale300,
    color: $isSelected ? $theme.colors.primary : $theme.colors.black
}))
