import { ApolloError, useMutation } from '@apollo/client'
import { CarpayApplyDiscountCodeMutation, CarpayApplyDiscountCodeMutationVariables } from 'parkdepot-shared/gql/graphql'
import { useCallback } from 'react'
import REDEEM_VOUCHER_CODE from '../../graphql/mutations/carpayRedeemVoucheCode.mutation'

const useRedeemVoucherCode = (
    onCompleted?: (data: CarpayApplyDiscountCodeMutation) => void,
    onError?: (e: ApolloError) => void
) => {
    const [insertGraphQLEntry, result] = useMutation(REDEEM_VOUCHER_CODE, { onCompleted })
    const redeemVoucherCode = useCallback(
        (entry: CarpayApplyDiscountCodeMutationVariables) =>
            insertGraphQLEntry({
                variables: {
                    code: entry.code,
                    order_id: entry.order_id,
                    parking_context_uuid: entry.parking_context_uuid
                    // TODO: we should also send here the expected parking end (for prepayment)
                }
            }).catch(onError),
        [onError, insertGraphQLEntry]
    )

    return [redeemVoucherCode, result] as const
}

export default useRedeemVoucherCode
