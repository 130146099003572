import { FullOrderFragment } from 'parkdepot-shared/gql/graphql'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import PostpayActionButtons from '../PostpayActionButtons'
import PrepaySelection from './PrepaySelection'
import OrderStep from './types'

interface OrderStepperProps {
    step: OrderStep
    order: FullOrderFragment
}

const OrderStepper = ({ step, order }: OrderStepperProps) => {
    const { path } = useRouteMatch()
    const history = useHistory()

    const handlePay = () => {
        history.push({
            pathname: `${path}/${order.order_id}/payment`,
            search: history.location.search
        })
    }

    if (step === OrderStep.PrepayFlow) return <PrepaySelection />

    return <PostpayActionButtons order={order} onPayButtonClick={handlePay} />
}

export default OrderStepper
