import React from 'react'
import { LicensePlateInputContext } from '../LicensePlateInputContext'

import { useStyletron } from 'baseui'
import useTouchKeyboard from '../../../utils/hooks/useTouchkeyboard'
import { layouts, TouchKeyboardContext } from '../../TouchKeyboard'
import { StyledLPInnerWraper, StyleLPWrapper } from '../StyledComponents'
import LeftLPInput from './LeftLPInput'
import PlateCountryPart from './PlateCountryPart'

const IntLPInput: React.FunctionComponent = () => {
    const [, theme] = useStyletron()
    const { value, onChange, disabled, error, overrides, readOnly, withKeyboard } =
        React.useContext(LicensePlateInputContext)

    const inputMode = withKeyboard ? 'none' : 'text'

    const { keyboardRef, forceSelectionOfKeyboard } = React.useContext(TouchKeyboardContext)

    const handleChange = React.useCallback(
        (input: string) => {
            if (keyboardRef?.current?.getInput('internation_lp_input') === undefined && withKeyboard) return
            const newValue = input.replace(/[^a-z0-9\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]/i, '').toUpperCase()
            if (newValue.length > 11) {
                forceSelectionOfKeyboard('internation_lp_input', value || '')
                return
            }
            onChange(newValue)
            if (withKeyboard && keyboardRef?.current?.getInput('internation_lp_input') !== newValue) {
                forceSelectionOfKeyboard('internation_lp_input', newValue)
            }
        },
        [value]
    )

    const { showKeyboard, hideKeyboard } = useTouchKeyboard('internation_lp_input', {
        handler: handleChange,
        defaultValue: value,
        props: { layout: layouts.INT, layoutName: 'license_plate' }
    })

    return (
        <StyleLPWrapper $error={error} $overrides={overrides?.LPContainer}>
            <PlateCountryPart $backgroundColor={theme.colors.mono200} $overrides={overrides?.PlateCountryPart} />
            <StyledLPInnerWraper $overrides={overrides?.LPInnerContainer}>
                <LeftLPInput
                    id="internation_lp_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    value={value.replace(/\_/g, ' ')}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-international/plate'
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    placeholder="AB1234CD"
                    disabled={disabled}
                    error={error}
                    onFocus={() => {
                        withKeyboard && showKeyboard()
                    }}
                    onBlur={() => withKeyboard && hideKeyboard()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleChange(e.target.value)
                    }}
                />
            </StyledLPInnerWraper>
        </StyleLPWrapper>
    )
}

export default IntLPInput
