import React from 'react'

const BREAKPOINTS = {
    xsmall: 320,
    small: 400,
    medium: 768,
    large: 1025
}

const calculateBreakpoint = () => {
    const width = window.innerWidth

    if (width < BREAKPOINTS.small) {
        return 0
    } else if (width < BREAKPOINTS.medium) {
        return 1
    } else if (width < BREAKPOINTS.large) {
        return 2
    } else {
        return 3
    }
}

const useBreakpoint = () => {
    const [activeBreakpoint, setActiveBreakpoint] = React.useState(calculateBreakpoint())

    React.useEffect(() => {
        const listener = () => {
            const newBreakpoint = calculateBreakpoint()
            if (newBreakpoint !== activeBreakpoint) {
                setActiveBreakpoint(newBreakpoint)
            }
        }
        window.addEventListener('resize', listener)
        return () => window.removeEventListener('resize', listener)
    }, [activeBreakpoint])

    return activeBreakpoint
}

export default useBreakpoint
