import { FormControl as BUFormControl, StyledCaption, StyledLabel } from 'baseui/form-control'
import { withStyle } from 'parkdepot-shared/theme'
import React, { ComponentProps } from 'react'

type FormControlProps = ComponentProps<typeof BUFormControl>

const FormControlLabel = withStyle(StyledLabel, ({ $theme }) => ({
    ...$theme.typography.ParagraphXSmall,
    color: $theme.colors.mono400
}))

const FormControlCaption = withStyle(StyledCaption, ({ $theme }) => ({
    ...$theme.typography.ParagraphXSmall,
    color: $theme.colors.black
}))

const FormControl = ({ ...props }: FormControlProps) => {
    return <BUFormControl overrides={{ Label: FormControlLabel, Caption: FormControlCaption }} {...props} />
}

export default FormControl
