import { styled } from 'baseui'

const Title = styled('h2', ({ $theme }) => ({
    width: '100%',
    textAlign: 'center',
    marginBottom: $theme.sizing.scale900,
    fontSize: '1.5rem',
    fontWeight: 600,
    color: $theme.colors.black
}))

export default Title
