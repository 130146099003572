import { ModalBody as MB, Modal } from 'baseui/modal'
import { ExtendedTheme, styled, useStyletron, withStyle } from 'parkdepot-shared/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AExamplePlate, ATExamplePlate, DEExamplePlate, DEExamplePlate2, FRxamplePlate } from '../../assets'
import StyledModalHeader from '../style/StyledModalHeader.style'

interface LicensePlateInputExplanationModalProps {
    isOpen: boolean
    onClose: () => void
}

const List = styled('ul', ({ $theme }) => ({
    color: $theme.colors.black,
    marginBottom: $theme.sizing.scale900,
    paddingInlineStart: $theme.sizing.scale850
}))

const ImagesList = styled('ul', () => ({
    listStyle: 'none',
    padding: 0
}))

const ListTextItem = styled('li', ({ $theme }) => ({
    marginBottom: $theme.sizing.scale100
}))

const ListImageItem = styled('li', ({ $theme }) => ({
    display: 'flex',
    gap: $theme.sizing.scale700,
    alignItems: 'center',
    marginBottom: $theme.sizing.scale300,
    color: $theme.colors.black
}))

const ListImageItemText = styled('p', ({ $theme }) => ({
    fontWeight: '500',
    fontSize: $theme.sizing.scale700
}))

const ModalBody = withStyle(MB, ({ $theme }) => ({
    padding: '0 3rem',
    marginBottom: $theme.sizing.scale1200,
    fontSize: '1.125rem',

    [$theme.mediaQuery.small]: {
        padding: '0'
    }
}))

const LPExamples = [
    {
        key: 'de',
        imageSrc: DEExamplePlate,
        imageAltText: 'Deutsch License Plate Example',
        exampleText: 'HHKFZ023'
    },
    {
        key: 'de2',
        imageSrc: DEExamplePlate2,
        imageAltText: 'Other Deutsch License Plate Example',
        exampleText: 'MBD14E'
    },
    {
        key: 'a',
        imageSrc: AExamplePlate,
        imageAltText: 'Austrian License Plate Example',
        exampleText: 'W24681R'
    },
    {
        key: 'at',
        imageSrc: ATExamplePlate,
        imageAltText: 'Swiss License Plate Example',
        exampleText: 'BE56789'
    },
    {
        key: 'fr',
        imageSrc: FRxamplePlate,
        imageAltText: 'French License Plate Example',
        exampleText: 'AA229AA'
    }
]

const LicensePlateInputExplanationModal = ({ isOpen, onClose }: LicensePlateInputExplanationModalProps) => {
    const { t } = useTranslation(['home'])
    const [css, theme] = useStyletron()

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            overrides={{
                Dialog: {
                    style: ({ $theme }: { $theme: ExtendedTheme }) => ({
                        width: '1057px',

                        [$theme.breakpoints.small]: {
                            width: '340px'
                        }
                    })
                }
            }}
        >
            <StyledModalHeader>{t('main.licensePlateExplanation.title')}</StyledModalHeader>
            <ModalBody>
                <List>
                    <ListTextItem>{t('main.licensePlateExplanation.explanation1')}</ListTextItem>
                    <ListTextItem>{t('main.licensePlateExplanation.explanation2')}</ListTextItem>
                    <ListTextItem>{t('main.licensePlateExplanation.explanation3')}</ListTextItem>
                    <ListTextItem>{t('main.licensePlateExplanation.explanation4')}</ListTextItem>
                </List>

                <p className={css({ fontSize: theme.sizing.scale650, fontWeight: '600', color: theme.colors.black })}>
                    {t('main.licensePlateExplanation.examples')}
                </p>

                <ImagesList>
                    {LPExamples.map(({ imageSrc, imageAltText, exampleText, key }) => (
                        <ListImageItem key={key}>
                            <img src={imageSrc} alt={imageAltText} />
                            <i className={`pkd-arrow-right ${css({ fontSize: theme.sizing.scale700 })}`} />
                            <ListImageItemText>{exampleText}</ListImageItemText>
                        </ListImageItem>
                    ))}
                </ImagesList>
            </ModalBody>
        </Modal>
    )
}

export default LicensePlateInputExplanationModal
