import React from 'react'

export const generateContext = <T extends {}>() => {
    const Context = React.createContext<T>({} as any)

    interface IProvider<T> {
        context: T
        children: React.ReactNode | React.ReactNodeArray
    }

    const Provider = ({ context, children }: IProvider<T>) => (
        <Context.Provider value={context}>{children}</Context.Provider>
    )

    return [Context, Provider] as [typeof Context, typeof Provider]
}
