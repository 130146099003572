import { ResultCode } from '@adyen/adyen-web/dist/types/components/types'
import { useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { useLocation } from 'react-router-dom'
import useGetAdyenPaymentInfo from '@hooks/useGetAdyenPaymentInfo'
import Success from '../../components/Success/index'
import LoadingSpinner from '../../components/style/LoadingSpinner.style'

type LocationState = {
    success: ResultCode
}

const SuccessView = () => {
    const [css] = useStyletron()
    const location = useLocation<LocationState>()
    const urlParams = new URLSearchParams(location.search)
    const order = urlParams.get('order')
    const urlPlate = urlParams.get('plate')
    const stripeSessionId = urlParams.get('session_id')
    const redirectResult = urlParams.get('redirectResult')
    const sessionId = urlParams.get('sessionId')

    const [adyenResultCode, adyenResultLoading] = useGetAdyenPaymentInfo({
        orderId: order,
        status: location.state?.success,
        redirectResult,
        sessionId
    })

    let view

    if (adyenResultLoading) {
        view = (
            <div style={{ textAlign: 'center' }}>
                <LoadingSpinner />
            </div>
        )
    }

    // success status is in state (because of card payment for example)
    if (location.state?.success) {
        view = <Success success={location.state.success} orderId={order} />
    }

    // success status is not in the state because we are coming from a redirection (Klarna, Paypal, etc)
    if (adyenResultCode) {
        view = <Success success={adyenResultCode} orderId={order} />
    }

    // if payment was done with stripe
    if (urlPlate && stripeSessionId) {
        view = <Success success="Authorised" plate={urlPlate} />
    }

    return <div className={css({ position: 'relative' })}>{view}</div>
}

export default SuccessView
