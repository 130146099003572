import { styled } from 'parkdepot-shared/theme'

const ButtonsWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    gap: $theme.sizing.scale1600,
    justifyContent: 'center',

    [$theme.mediaQuery.small]: {
        gap: $theme.sizing.scale500
    }
}))

export default ButtonsWrapper
