import { withStyle } from 'baseui'
import { Spinner } from 'baseui/spinner'
import { styled } from 'parkdepot-shared/theme'
import React from 'react'

const LoadingContainer = styled('div', () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
}))

const LoadingSpinner = withStyle(Spinner, ({ $theme }) => ({
    width: '80px',
    height: '80px',
    borderWidth: '12px',
    borderTopColor: $theme.colors.primary
}))

const LoadingWrapper = () => {
    return (
        <LoadingContainer>
            <LoadingSpinner />
        </LoadingContainer>
    )
}

export default LoadingWrapper
