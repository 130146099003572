import React from 'react'
import { generateContext } from './contextGenerator'

export type ConsentChangeHandler<P extends {}> = (e: CustomEvent<P>) => void

export const generateConsentContext = <T extends {}, P extends {}>() => {
    const [ConsentContext, ConsentProvider] = generateContext<T>()

    const getConsentContainer: (onConsentChange: ConsentChangeHandler<P>) => React.FC<{ context: T }> =
        (onConsentChange) =>
        ({ children, context }) => {
            React.useEffect(() => {
                window.addEventListener('consentChanged', onConsentChange as EventListener)
                window.addEventListener('UC_SDK_EVENT', onConsentChange as EventListener)
                return () => {
                    window.removeEventListener('consentChanged', () => {})
                    window.removeEventListener('UC_SDK_EVENT', () => {})
                }
                //eslint-disable-next-line
            }, [])

            return <ConsentProvider context={context}>{children}</ConsentProvider>
        }

    return [ConsentContext, getConsentContainer] as const
}
