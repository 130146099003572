import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import * as Sentry from '@sentry/react'
import { persistCache } from 'apollo-cache-persist'
import { LocalStorageWrapper } from 'apollo3-cache-persist'

const generateClient = async () => {
    // Create HTTP Link
    const httpLink = new HttpLink({
        uri: process.env.VITE_HASURA_URL
    })

    // Create Retry Link
    const retryLink = new RetryLink({ attempts: { max: Infinity } })

    // Create Auth Link
    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                'content-type': 'application/json'
            }
        }
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        // Sentry methods are safe to be called before initialization
        if (graphQLErrors) graphQLErrors.map(({ message }) => Sentry.captureException(message))
        if (networkError) {
            Sentry.captureException(networkError)
        }
    })

    const link = ApolloLink.from([errorLink, retryLink, authLink, httpLink])

    const cache = new InMemoryCache({
        typePolicies: {
            Order: {
                keyFields: ['order_id']
            }
        }
    })

    /* Apollo by default caches query responses; however on restarting the app, the cache is lost. 
    We use the apollo-cache-persist API to persist the apollo cache in the local storage and hence
    make it available for offline use. */
    await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage)
    })

    return new ApolloClient({
        cache,
        link
    })
}

export default generateClient
