import React from 'react'
import { IStyledCountrySection, StyledCountrySection } from '../StyledComponents'

const PlateCountryPart = ({
    src,
    label,
    $color,
    $backgroundColor,
    $overrides,
    width = '16px'
}: IStyledCountrySection & { src?: string; label?: string; width?: string }) => {
    let image
    if (src) image = <img src={src} width={width} />
    else if (label) image = <i className="pkd-europe" style={{ color: '#ffcc01' }} />

    return (
        <StyledCountrySection $color={$color} $backgroundColor={$backgroundColor} $overrides={$overrides}>
            {image}
            {label}
        </StyledCountrySection>
    )
}

export default PlateCountryPart
