import { FullOrderFragment } from 'parkdepot-shared/gql/graphql'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shouldRedirectUserToEmailInput } from '@helpers/stepsUtils'
import usePolicyContextMapper from '@hooks/usePolicyContextMapper'
import PaymentStep from '@views/Payment/types'
import { useOrdersStore } from '../../views/Orders/ordersListingContext'
import BackSubheader from '../BackSubheader/BackSubheader'
import EmailForm from './EmailForm'
import PaymentDropin from './PaymentDropin'
import StepperBar from './StepperBar'
import UserInput from './UserInput'

interface PaymentStepperProps {
    order: FullOrderFragment
}

const PaymentStepper = ({ order }: PaymentStepperProps) => {
    const { stayDecision } = useOrdersStore()
    const history = useHistory()
    const policies = usePolicyContextMapper(order.order_id)
    const [step, setStep] = useState(() => {
        if (shouldRedirectUserToEmailInput(order, stayDecision, policies)) return PaymentStep.EmailInput

        return PaymentStep.UserInput
    })

    const handleStepChange = (step: PaymentStep) => {
        setStep(step)
    }

    let mainView = null
    if (step === PaymentStep.UserInput) {
        mainView = <UserInput onInputSubmit={() => handleStepChange(PaymentStep.EmailInput)} order={order} />
    } else if (step === PaymentStep.EmailInput) {
        mainView = <EmailForm onNextStep={() => handleStepChange(PaymentStep.PaymentDropin)} />
    } else mainView = <PaymentDropin order={order} />

    const handleBack = () => {
        if (step === PaymentStep.UserInput) return history.goBack()
        if (step === PaymentStep.EmailInput) {
            if (!shouldRedirectUserToEmailInput(order, stayDecision, policies)) {
                return setStep(PaymentStep.UserInput)
            }

            return history.goBack()
        }

        if (step === PaymentStep.PaymentDropin) return setStep(PaymentStep.EmailInput)

        // Fallback -> should never happen
        history.push('/')
    }

    return (
        <>
            <BackSubheader onClick={handleBack} />
            <StepperBar step={step} />
            {mainView}
        </>
    )
}

export default PaymentStepper
