import { useStyletron } from 'baseui'
import React from 'react'

import { AUSTRIAN_PLATE_EXTRACT } from '../../../utils/licensePlate'

import useTouchKeyboard from '../../../utils/hooks/useTouchkeyboard'
import { TouchKeyboardContext } from '../../TouchKeyboard'
import { LicensePlateInputContext } from '../LicensePlateInputContext'
import { StyledCircularBadge, StyledLPInnerWraper, StyledPlateBadgeText, StyleLPWrapper } from '../StyledComponents'
import LeftLPInput from './LeftLPInput'
import PlateCountryPart from './PlateCountryPart'
import RightLpInput from './RightLPInput'

const convertToPlate = (plateObject: { city?: string; plate?: string }) => {
    if (!plateObject.plate) {
        return `${plateObject.city || ''}_`
    } else {
        return `${plateObject.city || ''}_${plateObject.plate.slice(0, 6)}`
    }
}

const AustrianLPInput: React.FunctionComponent = () => {
    const [css] = useStyletron()
    const cityCodeRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const plateRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)

    const { value, onChange, disabled, error, overrides, readOnly, withKeyboard, setFirstPartEmptyError } =
        React.useContext(LicensePlateInputContext)

    let displayedValue: {
        city?: string
        plate?: string
    } = {}

    if (value === '' || value.match(AUSTRIAN_PLATE_EXTRACT)) {
        displayedValue = {
            city: '',
            plate: '',
            ...AUSTRIAN_PLATE_EXTRACT.exec(value)?.groups
        }
    }
    const { keyboardRef, forceSelectionOfKeyboard } = React.useContext(TouchKeyboardContext)

    const { city, plate } = displayedValue

    const handleCityChange = React.useCallback(
        (cityInput: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('austrian_city_input') === undefined && withKeyboard) return
            const newCity = cityInput.replace(/[^a-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]/i, '').toUpperCase()
            if (newCity.length > 3) {
                forceSelectionOfKeyboard('austrian_city_input', city || '')
                return
            }
            onChange(
                convertToPlate({
                    ...displayedValue,
                    city: newCity
                })
            )
            if (withKeyboard && keyboardRef?.current?.getInput('austrian_city_input') !== newCity) {
                forceSelectionOfKeyboard('austrian_city_input', newCity)
            }
        },
        [city, plate]
    )

    const handleRightInputChange = React.useCallback(
        (input: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('austrian_right_input') === undefined && withKeyboard) return
            const newSecondPartValue = input
                .replace(/[^a-z0-9\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]/i, '')
                .toUpperCase()
            const convertedPlate = convertToPlate({
                ...displayedValue,
                plate: newSecondPartValue
            })
            const { plate: newSecondPart } = { plate: '', ...AUSTRIAN_PLATE_EXTRACT.exec(convertedPlate)?.groups }
            const newFormattedSecondPart = newSecondPart.replace(/_/g, '')
            if (newFormattedSecondPart !== newSecondPartValue && withKeyboard && keyboardRef?.current) {
                forceSelectionOfKeyboard('austrian_right_input', newFormattedSecondPart)
                return
            }
            onChange(convertedPlate)
            if (withKeyboard && keyboardRef?.current?.getInput('austrian_right_input') !== newFormattedSecondPart) {
                forceSelectionOfKeyboard('austrian_right_input', newFormattedSecondPart)
            }
        },
        [city, plate]
    )

    const { showKeyboard: showCityKeyboard, hideKeyboard: hideCityKeyboard } = useTouchKeyboard('austrian_city_input', {
        handler: handleCityChange,
        defaultValue: city
    })
    const { showKeyboard: showRightInputKeyboard, hideKeyboard: hideRightInputKeyboard } = useTouchKeyboard(
        'austrian_right_input',
        {
            handler: handleRightInputChange,
            defaultValue: plate ? plate?.replace(/_/g, '') : ''
        }
    )

    React.useEffect(() => {
        const plateInput = plateRef.current
        if (plate) {
            plateInput && plateInput.setSelectionRange(plate.length, plate.length)
        }
    }, [plate])

    React.useEffect(() => {
        const cityCodeInput = cityCodeRef.current
        if (city) {
            cityCodeInput && cityCodeInput.setSelectionRange(city.length, city.length)
        }
    }, [city])

    const inputMode = withKeyboard ? 'none' : 'text'

    return (
        <StyleLPWrapper $error={error} $overrides={overrides?.LPContainer}>
            <PlateCountryPart label="A" $overrides={overrides?.PlateCountryPart} />
            <StyledLPInnerWraper $overrides={overrides?.LPInnerContainer}>
                <LeftLPInput
                    id="austrian_city_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={cityCodeRef}
                    value={displayedValue.city || ''}
                    placeholder="IL"
                    disabled={disabled}
                    error={error}
                    maxLength={3}
                    onFocus={() => {
                        withKeyboard && showCityKeyboard()
                    }}
                    onBlur={() => withKeyboard && hideCityKeyboard()}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-austria/plate',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleCityChange(e.target.value)
                    }}
                />
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    })}
                >
                    <StyledCircularBadge $overrides={overrides?.CircularBadge} />
                    <StyledPlateBadgeText $overrides={overrides?.PlateBadge} />
                </div>
                <RightLpInput
                    id="austrian_right_input"
                    readOnly={readOnly}
                    inputMode={inputMode}
                    inputRef={plateRef}
                    value={displayedValue.plate ? displayedValue.plate.replace(/_/g, '') : ''}
                    placeholder="1234M"
                    disabled={disabled}
                    error={error}
                    onBlur={() => hideRightInputKeyboard()}
                    onFocus={() => {
                        if (!withKeyboard) return
                        if (displayedValue.city !== '') {
                            showRightInputKeyboard()
                        } else {
                            setFirstPartEmptyError(true)
                            cityCodeRef.current?.select()
                            cityCodeRef.current?.focus()
                            return
                        }
                    }}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-austria/plate',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleRightInputChange(e.target.value)
                    }}
                />
            </StyledLPInnerWraper>
        </StyleLPWrapper>
    )
}

export default AustrianLPInput
