import { ProgressBar as BUProgressBar, StyledBar, StyledBarContainer, StyledBarProgress } from 'baseui/progress-bar'
import { withStyle } from 'parkdepot-shared/theme'
import React, { ComponentProps } from 'react'

type ProgressBarProps = ComponentProps<typeof BUProgressBar>

const Bar = withStyle(StyledBar, ({ $theme }) => ({
    backgroundColor: $theme.colors.mono200,
    height: '5px',
    borderTopLeftRadius: $theme.sizing.scale100,
    borderTopRightRadius: $theme.sizing.scale100,
    borderBottomLeftRadius: $theme.sizing.scale100,
    borderBottomRightRadius: $theme.sizing.scale100
}))

const Progress = withStyle(StyledBarProgress, ({ $theme }) => ({
    backgroundColor: $theme.colors.primary,
    borderTopLeftRadius: $theme.sizing.scale100,
    borderTopRightRadius: $theme.sizing.scale100,
    borderBottomLeftRadius: $theme.sizing.scale100,
    borderBottomRightRadius: $theme.sizing.scale100
}))

const Container = withStyle(StyledBarContainer, () => ({
    marginLeft: 0,
    marginRight: 0
}))

const ProgressBar = ({ ...props }: ProgressBarProps) => {
    return <BUProgressBar overrides={{ Bar, BarProgress: Progress, BarContainer: Container }} {...props} />
}

export default ProgressBar
