import { useStyletron } from 'baseui'
import React from 'react'
import useTouchKeyboard from '../../../utils/hooks/useTouchkeyboard'
import { TouchKeyboardContext } from '../../TouchKeyboard'
import { LicensePlateInputContext } from '../LicensePlateInputContext'

import { StyledCircularBadge, StyledLPInnerWraper, StyleLPWrapper } from '../StyledComponents'
import LeftLPInput from './LeftLPInput'
import PlateCountryPart from './PlateCountryPart'
import RightLpInput from './RightLPInput'

const GERMAN_PLATE_EXTRACT =
    /^(?<city>[a-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]*)(_)*(?<plate>[a-z0-9_\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]*)$/i

const convertToPlate = (plateObject: { city?: string; plate?: string }) => {
    const plateSections = (plateObject.plate && plateObject.plate.match(/\D+|\d+/gi)) || ([] as string[])

    if (plateSections[0] === undefined) {
        return `${plateObject.city || ''}_`
    } else if (plateSections[1] === undefined) {
        return `${plateObject.city || ''}_${plateSections[0]?.slice(0, 2) || ''}`
    } else if (plateSections[2] === undefined) {
        return `${plateObject.city || ''}_${plateSections[0]?.slice(0, 2) || ''}_${plateSections[1]?.slice(0, 4) || ''}`
    } else {
        return `${plateObject.city || ''}_${plateSections[0]?.slice(0, 2) || ''}_${
            plateSections[1]?.slice(0, 4) || ''
        }${plateSections[2]?.slice(0, 1) || ''}`
    }
}

const GermanLPInput: React.FunctionComponent = () => {
    const cityCodeRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const plateRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const [css] = useStyletron()

    const {
        value,
        onChange,
        disabled,
        error,
        overrides,
        readOnly,
        withKeyboard,
        firstPartEmptyError,
        setFirstPartEmptyError
    } = React.useContext(LicensePlateInputContext)

    let displayedValue: {
        city?: string
        plate?: string
    } = {}

    if (value === '' || value.match(GERMAN_PLATE_EXTRACT)) {
        displayedValue = {
            city: '',
            plate: '',
            ...GERMAN_PLATE_EXTRACT.exec(value)?.groups
        }
    }

    const { keyboardRef, forceSelectionOfKeyboard } = React.useContext(TouchKeyboardContext)

    const { city, plate } = displayedValue

    const handleCityChange = React.useCallback(
        (cityInput: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('german_city_input') === undefined && withKeyboard) return
            const newCity = cityInput.replace(/[^a-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]/i, '').toUpperCase()
            if (newCity.length > 3) {
                forceSelectionOfKeyboard('german_city_input', city || '')
                return
            }
            onChange(
                convertToPlate({
                    ...displayedValue,
                    city: newCity
                })
            )
            if (withKeyboard && keyboardRef?.current?.getInput('german_city_input') !== newCity) {
                forceSelectionOfKeyboard('german_city_input', newCity)
            }
        },
        [city, plate]
    )

    const handleRightInputChange = React.useCallback(
        (input: string) => {
            setFirstPartEmptyError(false)
            if (keyboardRef?.current?.getInput('german_right_input') === undefined && withKeyboard) return
            const newSecondPartValue = input
                .replace(/[^a-z0-9\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc¨]/i, '')
                .toUpperCase()
            const convertedPlate = convertToPlate({
                ...displayedValue,
                plate: newSecondPartValue
            })
            const { plate: newSecondPart } = { plate: '', ...GERMAN_PLATE_EXTRACT.exec(convertedPlate)?.groups }
            const newFormattedSecondPart = newSecondPart.replace(/_/g, '')
            if (newFormattedSecondPart !== newSecondPartValue && withKeyboard && keyboardRef?.current) {
                forceSelectionOfKeyboard('german_right_input', newFormattedSecondPart)
                return
            }
            onChange(convertedPlate)
            if (withKeyboard && keyboardRef?.current?.getInput('german_right_input') !== newFormattedSecondPart) {
                forceSelectionOfKeyboard('german_right_input', newFormattedSecondPart)
            }
        },
        [city, plate]
    )

    const { showKeyboard: showCityKeyboard, hideKeyboard: hideCityKeyboard } = useTouchKeyboard('german_city_input', {
        handler: handleCityChange,
        defaultValue: city
    })
    const { showKeyboard: showRightInputKeyboard, hideKeyboard: hideRightInputKeyboard } = useTouchKeyboard(
        'german_right_input',
        {
            handler: handleRightInputChange,
            defaultValue: plate ? plate?.replace(/_/g, '') : ''
        }
    )

    React.useEffect(() => {
        const plateInput = plateRef.current
        if (plate) {
            plateInput && plateInput.setSelectionRange(plate.length, plate.length)
        }
    }, [plate])

    React.useEffect(() => {
        const cityCodeInput = cityCodeRef.current
        if (city) {
            cityCodeInput && cityCodeInput.setSelectionRange(city.length, city.length)
        }
    }, [city])

    const inputMode = withKeyboard ? 'none' : 'text'

    return (
        <StyleLPWrapper $error={error} $overrides={overrides?.LPContainer}>
            <PlateCountryPart label="D" $overrides={overrides?.PlateCountryPart} />
            <StyledLPInnerWraper $overrides={overrides?.LPInnerContainer}>
                <LeftLPInput
                    id="german_city_input"
                    readOnly={readOnly}
                    inputRef={cityCodeRef}
                    inputMode={inputMode}
                    value={displayedValue.city || ''}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-germany/city-code',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    placeholder="M"
                    disabled={disabled}
                    error={error}
                    maxLength={3}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleCityChange(e.target.value)
                    }}
                    onFocus={() => {
                        withKeyboard && showCityKeyboard()
                    }}
                    onBlur={() => withKeyboard && hideCityKeyboard()}
                />
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    })}
                >
                    <StyledCircularBadge $overrides={overrides?.CircularBadge} />
                    <StyledCircularBadge $overrides={overrides?.CircularBadge} />
                </div>
                <RightLpInput
                    id="german_right_input"
                    readOnly={readOnly}
                    inputRef={plateRef}
                    inputMode={inputMode}
                    value={displayedValue.plate ? displayedValue.plate.replace(/_/g, '') : ''}
                    placeholder="PD1234"
                    disabled={disabled}
                    error={error || firstPartEmptyError}
                    overrides={{
                        Input: {
                            props: {
                                'data-testid': 'lp-input-germany/plate',
                                onSelect: (e: any) => {
                                    e.target.setSelectionRange(e.target.value.length, e.target.value.length, 'forward')
                                }
                            },
                            style: {
                                ...overrides?.Input
                            }
                        }
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        handleRightInputChange(e.target.value)
                    }}
                    onFocus={() => {
                        if (!city) {
                            setFirstPartEmptyError(true)
                            cityCodeRef.current?.select()
                            cityCodeRef.current?.focus()
                            return
                        }
                        withKeyboard && showRightInputKeyboard()
                    }}
                    onBlur={() => withKeyboard && hideRightInputKeyboard()}
                />
            </StyledLPInnerWraper>
        </StyleLPWrapper>
    )
}

export default GermanLPInput
