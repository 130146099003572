import { DateTime } from 'luxon'
import { styled } from 'parkdepot-shared/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CardWrapper = styled('div', ({ $theme }) => ({
    backgroundColor: `${$theme.colors.mono100}`,
    width: '100%',
    padding: `${$theme.sizing.scale1200} ${$theme.sizing.scale950}`,
    borderRadius: $theme.sizing.scale800,
    display: 'flex',
    flexDirection: 'column',
    gap: $theme.sizing.scale900
}))

const CardCaption = styled('h2', ({ $theme }) => ({
    ...$theme.typography.LabelXSmall
}))

const CardHeader = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: $theme.sizing.scale700
}))

const CardTitle = styled('h1', ({ $theme }) => ({
    ...$theme.typography.HeadingSmall,
    marginBottom: $theme.sizing.scale800
}))

const CardSubtitle = styled('p', ({ $theme }) => ({
    ...$theme.typography.ParagraphLarge
}))

interface FullyWhitelistedOrderCardProps {
    orderId: string
}

const FullyWhitelistedOrderCard = ({ orderId }: FullyWhitelistedOrderCardProps) => {
    const { t } = useTranslation('orders')

    const now = DateTime.now().toLocaleString()

    return (
        <CardWrapper data-testid="fullyWhitelistedCard">
            <CardHeader>
                <CardCaption>
                    {t('orderCard.transactionNr')}: {orderId}
                </CardCaption>
                <CardCaption>
                    {t('orderCard.date')}: {now}
                </CardCaption>
            </CardHeader>
            <div>
                <CardTitle>{t('orderCard.fullyWhitelist.title')}</CardTitle>

                <CardSubtitle>{t('orderCard.fullyWhitelist.subtitle')}</CardSubtitle>
            </div>
        </CardWrapper>
    )
}

export default FullyWhitelistedOrderCard
