import { useTranslation } from 'react-i18next'
import notNull from '../helpers/notNull'
import useGetOrderFromCache from './useGetOrderFromCache'

type VEHICLE_TYPES = 'MOTORCYCLE' | 'TRUCK' | 'CAR' | 'CARAVAN' | 'MINIBUS' | 'BUS'

export interface PolicyContextOption {
    uuid: string | null
    id: string
    title: string
}

const usePolicyContextMapper = (orderId: string) => {
    const { t } = useTranslation('orders')
    const order = useGetOrderFromCache(orderId)

    if (!order) return []

    let policyContexts: PolicyContextOption[] = order.parking_contexts
        .map((it) => {
            if (!it.vehicle_type) return null

            const vehicleType = it.vehicle_type as VEHICLE_TYPES

            return {
                id: it.uuid,
                uuid: it.uuid,
                title: t(`orderCard.policySelection.vehicleType.${vehicleType.toLowerCase()}`)
            }
        })
        .filter(notNull)

    policyContexts = [
        {
            id: 'car',
            uuid: null,
            title: t(`orderCard.policySelection.vehicleType.car`)
        },
        ...policyContexts
    ]

    policyContexts.sort((a, b) => a.title.length - b.title.length)

    return policyContexts
}

export default usePolicyContextMapper
