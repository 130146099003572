import { styled } from 'baseui'

const DescriptionWrapper = styled('div', ({ $theme }) => ({
    maxWidth: '365px',
    margin: '30px auto',
    marginBottom: 0,
    color: $theme.colors.black
}))

export default DescriptionWrapper
