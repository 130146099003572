import React from 'react'

export const WemoloIcon = ({ $dark = true }: { $dark?: boolean }) => {
    return (
        <svg width="124" height="21" viewBox="0 0 124 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M45.4799 16.4071C45.5788 12.3952 45.4975 8.32235 45.6363 4.02233C45.6921 2.29706 46.6667 0.988286 48.4214 0.823661C53.0522 0.198084 53.4335 5.2883 55.8167 5.2883C59.1601 5.28995 59.3771 2.00073 63.089 1.7933C64.2328 1.7291 65.6748 3.21238 65.9045 5.65048C66.1885 8.66971 66.3687 16.8911 65.9141 18.5555C65.2585 20.9541 62.3329 20.7137 61.1717 18.9604C60.4187 17.8229 60.6883 16.9487 59.7296 16.8664C58.4407 16.7545 58.554 18.7662 56.0511 18.5621C53.9216 18.3876 54.4831 16.0729 52.8704 16.0795C51.7585 16.0844 51.6788 17.8673 51.0184 19.135C49.6051 21.848 45.4464 20.5738 45.4799 16.4088V16.4071Z"
                fill={$dark ? '#0A0A0A' : '#FFFFFF'}
            />
            <path
                d="M0.0132878 6.03609C-0.0967791 3.77084 0.44877 1.72948 2.71072 1.64717C5.3619 1.55169 6.10525 4.02272 7.12935 4.03589C9.227 4.06223 8.73569 0.880017 11.4236 0.685759C13.8004 0.514548 14.7734 2.36988 15.4195 3.36751C16.9397 5.71177 18.2302 3.57494 19.3229 2.19702C20.4682 0.937636 22.6504 0.791119 24.0318 2.82754C25.0479 4.61866 23.9169 7.99184 23.3427 10.1781C22.6121 12.9586 22.2931 14.4682 21.3088 17.2817C21.1429 17.7574 20.4953 20.1297 18.3785 20.3618C16.1724 20.6054 15.1307 17.4265 13.0076 17.4644C10.6706 17.5055 10.141 20.3042 8.2029 20.4573C5.29012 20.6861 4.31866 17.95 3.16375 15.2041C1.52073 11.3041 0.973582 9.55084 0.0132878 6.03609Z"
                fill={$dark ? '#0A0A0A' : '#FFFFFF'}
            />
            <path
                d="M28.3146 13.9197C28.2429 10.4741 28.1296 8.83281 28.211 5.80206C28.329 1.44113 31.4204 0.726655 35.008 0.914328C36.9748 1.0164 39.1905 0.749703 40.5799 2.39102C42.5069 4.58054 40.8352 6.58238 38.4296 6.59885C38.1457 6.60049 36.7898 6.82438 36.7515 7.54544C36.7196 8.16443 37.4853 8.5513 37.6464 8.60892C39.4633 9.26084 39.5622 9.82551 39.5638 10.7425C39.5638 11.8356 38.5238 12.1582 37.841 12.7262C37.4885 13.0192 37.2588 14.0103 37.9096 14.6968C38.3579 15.1692 39.5463 15.2581 40.095 15.8426C41.9358 17.8033 40.4172 20.1376 37.1407 20.3681C34.9378 20.5245 33.7382 20.6348 31.5592 20.5805C28.5188 20.4192 28.3705 16.3282 28.3162 13.9214L28.3146 13.9197Z"
                fill={$dark ? '#0A0A0A' : '#FFFFFF'}
            />
            <path
                d="M76.3101 20.4182C71.5692 19.4815 68.3071 10.2362 69.9437 5.85383C71.0731 2.14482 76.4712 -0.382184 81.6379 1.57357C84.0514 2.48724 86.5941 5.35172 86.8111 10.6658C86.8829 12.4191 86.3214 22.3937 76.3101 20.4166V20.4182Z"
                fill={$dark ? '#0A0A0A' : '#FFFFFF'}
            />
            <path
                d="M123.855 13.3325C123.864 15.7377 122.231 20.0921 115.995 20.3719C108.452 20.711 106.909 13 106.837 11.3784C105.743 -3.87248 125.45 -2.00069 123.855 13.3325Z"
                fill={$dark ? '#0A0A0A' : '#FFFFFF'}
            />
            <path
                d="M95.7094 20.5028C93.7377 20.5028 90.6622 19.7225 90.4596 16.0826C90.2076 11.5455 90.0864 8.03078 90.2315 3.9563C90.2698 2.8994 90.3304 2.29358 90.9398 1.63343C92.6546 -0.225192 96.1432 0.894261 96.3331 3.47559C96.5037 5.80998 96.6728 9.29839 96.8148 10.9578C96.9807 12.8988 98.2297 13.2659 98.7609 13.4223C99.4596 13.628 100.87 13.847 101.471 14.0873C104.346 14.9846 104.009 19.9809 101.091 20.2871C99.5457 20.4501 97.9394 20.5028 95.7094 20.5028Z"
                fill={$dark ? '#0A0A0A' : '#FFFFFF'}
            />
        </svg>
    )
}
