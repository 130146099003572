import { styled } from 'baseui'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { Footer } from 'parkdepot-shared/components/Footer'
import { PublicHeader } from 'parkdepot-shared/components/PublicHeader'
import { LanguageSelectOptions } from 'parkdepot-shared/components/Select'
import { useStyletron } from 'parkdepot-shared/theme'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

const languages = LanguageSelectOptions.filter((lang) => {
    return lang.id !== 'pl' && lang.id !== 'da'
})

const AppFrameWrapper = styled('div', {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
})

const MainFrameContent = styled('section', () => ({
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    flexGrow: 1
}))

const FAQUrl = 'https://wemolo.com/faq-carpay'

const handleCookiesClick = () => {
    if (window.UC_UI) {
        if (typeof window.UC_UI.showSecondLayer === 'function') {
            window.UC_UI.showSecondLayer()
        }
    } else {
        console.error('Usercentrics is not loaded yet.')
    }
}

const screensWithDarkHeader = ['/contact', '/orders', '/payment']

const AppFrame: React.FC = ({ children }) => {
    const location = useLocation()
    const history = useHistory()
    const { t, i18n } = useTranslation('appFrame')
    const [css] = useStyletron()

    useEffect(() => {
        console.log('Page-mounted: ', location.pathname, ', state: ', location.state)
    }, [location])

    const handleFAQRedirect = () => {
        window.open(FAQUrl, '_blank')
    }

    const handleContactRedirect = () => {
        history.push('/contact')
    }

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang)
    }

    // We need to put inside the render cycle to change the labels on language change
    const footerLinks = [
        {
            label: i18n.t('appFrame.footer.impressum'),
            clicked: () => window.open('https://www.wemolo.com/impressum', '_blank')
        },
        {
            label: i18n.t('appFrame.footer.dataPrivacy'),
            clicked: () => window.open('https://www.wemolo.com/datenschutz', '_blank')
        },
        { label: 'Cookies', clicked: handleCookiesClick }
    ]

    const isDark = !!screensWithDarkHeader.find((path) => location.pathname.match(path))

    return (
        <AppFrameWrapper>
            <PublicHeader
                $dark={isDark}
                actions={(_, isMobile) => [
                    <div key={`faq-${isDark}`} className={css({ display: 'flex', flexDirection: 'column' })}>
                        <Button
                            $kind={BUTTON_KIND.primary}
                            $size={BUTTON_SIZE.medium}
                            onClick={handleFAQRedirect}
                            overrides={{
                                BaseButton: {
                                    style: {
                                        backgroundColor: isDark || isMobile ? 'black' : 'white',
                                        color: isDark || isMobile ? 'white' : 'black',
                                        borderColor: isDark || isMobile ? 'black' : 'white',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',

                                        ':hover': {
                                            backgroundColor: isDark ? 'black' : 'white',
                                            opacity: 0.6
                                        },

                                        ':active': {
                                            backgroundColor: 'transparent'
                                        },

                                        ':focus': {
                                            backgroundColor: 'transparent'
                                        }
                                    }
                                }
                            }}
                        >
                            FAQ
                        </Button>
                    </div>,
                    <div key={`contact-${isDark}`} className={css({ display: 'flex', flexDirection: 'column' })}>
                        <Button
                            $kind={BUTTON_KIND.secondary}
                            $size={BUTTON_SIZE.medium}
                            onClick={handleContactRedirect}
                            overrides={{
                                BaseButton: {
                                    style: {
                                        backgroundColor: 'transparent',
                                        color: isDark || isMobile ? 'black' : 'white',
                                        borderColor: isDark || isMobile ? 'black' : 'white',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',

                                        ':hover': {
                                            backgroundColor: 'transparent',
                                            opacity: 0.6
                                        },

                                        ':active': {
                                            backgroundColor: 'transparent'
                                        },

                                        ':focus': {
                                            backgroundColor: 'transparent'
                                        }
                                    }
                                }
                            }}
                        >
                            {t('header.contact')}
                        </Button>
                    </div>
                ]}
            />

            <MainFrameContent>{children}</MainFrameContent>

            <Footer
                selectedLanguage={i18n.language}
                languages={languages}
                onLanguageChange={handleLanguageChange}
                links={footerLinks}
            />
        </AppFrameWrapper>
    )
}

export default AppFrame
