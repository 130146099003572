import { ApolloProvider } from '@apollo/client'
import UsercentricsContainer from 'parkdepot-shared/components/UsercentricsContainer'
import 'parkdepot-shared/fonts/pkd/index.css'
import 'parkdepot-shared/fonts/wemolo/index.css'
import { ThemeProvider } from 'parkdepot-shared/theme'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import createWorker from '../mocks/browser'
import generateClient from './apollo-client'
import App from './App'
import CarpayConsentContainer from './context/ConsentProvider'
import './i18n'
import './index.css'

if (process.env.VITE_MOCKS === 'true') {
    const worker = createWorker()
    worker.start()
    window.worker = worker
}

const prepare = async () => {
    const apolloClient = await generateClient()

    return apolloClient
}

prepare()
    .then((client) => {
        // window.apolloClient = client

        ReactDOM.render(
            <React.StrictMode>
                <ThemeProvider>
                    <UsercentricsContainer settingsID={process.env.VITE_USERCENTRICS_SETTINGS_ID}>
                        <CarpayConsentContainer>
                            <BrowserRouter>
                                <ApolloProvider client={client}>
                                    <App />
                                </ApolloProvider>
                            </BrowserRouter>
                        </CarpayConsentContainer>
                    </UsercentricsContainer>
                </ThemeProvider>
            </React.StrictMode>,
            document.getElementById('root')
        )
    })
    .catch(() => {})
