import React from 'react'

import { useStyletron } from 'parkdepot-shared/theme'
import { useTranslation } from 'react-i18next'
import BackSubheader from '../../components/BackSubheader/BackSubheader'
import CardBackground from '../../components/style/CardBackground.styles'

const Cancel: React.FC = () => {
    const { t } = useTranslation(['feedback'])
    const [css, theme] = useStyletron()

    return (
        <div className={css({ position: 'relative' })}>
            <BackSubheader />
            <CardBackground>
                <i
                    className="wemolo-cross-circle"
                    style={{
                        color: 'red',
                        fontSize: '6rem'
                    }}
                />
                <p style={{ marginTop: '20px', color: theme.colors.negative, fontWeight: 500 }}>
                    {t('feedback.cancel.message')}
                </p>
                <p
                    style={{
                        margin: '5px 10%',
                        fontWeight: 500
                    }}
                >
                    {t('feedback.cancel.description')}
                </p>
            </CardBackground>
        </div>
    )
}

export default Cancel
