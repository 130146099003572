import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { Settings } from 'luxon'
import de from './de.json'
import de_CH from './de_CH.json'
import en from './en.json'
import en_CH from './en_CH.json'
import fr from './fr.json'
import fr_CH from './fr_CH.json'
import it from './it.json'
import it_CH from './it_CH.json'
import capitalize from './utils/capitalize'

export const LANGUAGE_FALLBACKS: Record<string, string> = {
    de: 'de',
    'de-DE': 'de_DE',
    'de-AT': 'de_AT',
    'de-CH': 'de_CH',
    en: 'en',
    'en-CH': 'en_CH',
    en_CH: 'en_CH',
    fr: 'fr',
    'fr-CH': 'fr_CH',
    it: 'it',
    'it-CH': 'it_CH',
    pl: 'pl',
    'da-DK': 'da_DK',
    da: 'da_DK'
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'de-DE': de,
            'en-GB': en,
            'de-CH': de_CH,
            'en-CH': en_CH,
            fr,
            it,
            'fr-CH': fr_CH,
            'it-CH': it_CH
        },
        ns: ['common', 'appFrame', 'home', 'orders', 'feedback', 'errors', 'payment'],
        lng: 'de',
        defaultNS: 'common',
        nsSeparator: '.',
        fallbackLng: {
            default: ['de-DE'],
            de: ['de-DE'],
            'de-AT': ['de-DE'],
            'de-CH': ['de-DE'],
            en: ['en-GB'],
            'en-US': ['en-GB'],
            'en-CA': ['en-GB']
        },
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
            format(value, format) {
                if (format === 'uppercase') return value.toUpperCase()
                if (format === 'capitalize') return capitalize(value)
                return value
            }
        },
        returnObjects: true
    })

Settings.defaultLocale = 'de'

export default i18n
