import { Value } from 'baseui/select'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { FullOrderFragment, LotType } from 'parkdepot-shared/gql/graphql'
import { styled, useStyletron } from 'parkdepot-shared/theme'
import React, { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import getDerivedValuesFromOrder from '../../helpers/getDerivedValuesFromOrder'
import useCalculateOrderPriceQuery from '../../hooks/useCalculateOrderPriceQuery'
import usePolicyContextMapper from '../../hooks/usePolicyContextMapper'
import useTariffMapper from '../../hooks/useTariffMapper'
import useUserInputStore, { ValueWithUUID } from '../../hooks/useUserInputStore'
import { StayOrLeaveDecision, useOrdersStore } from '../../views/Orders/ordersListingContext'
import { MiniOrderInfo } from '../OrderInfo'
import Voucher from '../Voucher'
import PolicyContextSelect from './PolicyContextSelect'
import TariffSelect from './TariffSelect'
import StepContainer from './styles/StepContainer'
import Title from './styles/Title'

const SelectsContainer = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
}))

const ButtonContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: $theme.sizing.scale400
}))

interface UserInputProps {
    onInputSubmit: () => void
    order: FullOrderFragment
}

const UserInput = ({ onInputSubmit, order }: UserInputProps) => {
    const { id: orderId } = useParams<{ id: string }>()
    const { t } = useTranslation(['common', 'payment'])
    const { stayDecision } = useOrdersStore()
    const tariffOptions = useTariffMapper(orderId)
    const policiesContext = usePolicyContextMapper(orderId)
    const selectedPolicy = useUserInputStore((state) => state.policy)
    const selectedTariff = useUserInputStore((state) => state.tariff)
    const selectedPolicyUUID = useUserInputStore((state) => state.getPolicyId())
    const setSelectedPolicy = useUserInputStore((state) => state.setPolicy)
    const setSelectedTariff = useUserInputStore((state) => state.setTariff)
    const [executePriceCalculation, { loading }] = useCalculateOrderPriceQuery()
    const [css, theme] = useStyletron()

    const {
        priceValidUntil,
        totalPrice,
        tax,
        vat,
        currency,
        whitelistDiscount,
        lot_type,
        isTollRoadLot,
        applied_discount: appliedDiscount
    } = getDerivedValuesFromOrder({
        order
    })

    const handleNext = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        onInputSubmit()
    }

    const tariffDuration = selectedTariff ? selectedTariff[0].id?.toString() : undefined
    const showTariffSelect =
        tariffOptions.length > 0 &&
        ((lot_type === LotType.Prepaid && stayDecision === StayOrLeaveDecision.stay) ||
            (lot_type === LotType.Prepaid && isTollRoadLot))
    const showPolicySelect = policiesContext.length > 1

    const hidePrice =
        (policiesContext.length > 1 && !selectedPolicy) || (showTariffSelect && !selectedTariff) || loading
    const nextButtonDisabled = hidePrice || !totalPrice

    const applyPrice = (policyId: string | null, tariff: string) => {
        executePriceCalculation(orderId, policyId, tariff)
    }

    const handleTariffChange = (value: Value) => {
        setSelectedTariff(value)
        const tariff = value[0].id?.toString()
        if (tariff) applyPrice(selectedPolicyUUID, tariff)
    }

    const handlePolicyChange = (value: ValueWithUUID) => {
        setSelectedPolicy(value)
        const policyId = value[0].uuid
        if (tariffDuration) applyPrice(policyId, tariffDuration)
    }

    return (
        <StepContainer>
            <Title>{t('tariffSelection', { ns: 'payment' })}</Title>

            <form
                onSubmit={handleNext}
                className={css({
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.sizing.scale600
                })}
            >
                <SelectsContainer>
                    {showPolicySelect ? (
                        <PolicyContextSelect
                            options={policiesContext}
                            value={selectedPolicy}
                            onChange={handlePolicyChange}
                        />
                    ) : null}

                    {showTariffSelect ? (
                        <TariffSelect onChange={handleTariffChange} value={selectedTariff} options={tariffOptions} />
                    ) : null}
                </SelectsContainer>

                <MiniOrderInfo
                    hidePrice={hidePrice}
                    currency={currency}
                    loading={loading}
                    validUntil={priceValidUntil}
                    totalPrice={totalPrice}
                    vat={vat}
                    tax={tax}
                    whitelistDiscount={whitelistDiscount}
                    appliedDiscount={appliedDiscount}
                />

                <ButtonContainer>
                    <Button
                        data-testid="user-input-next-button"
                        disabled={nextButtonDisabled}
                        $kind={BUTTON_KIND.primary}
                        $size={BUTTON_SIZE.large}
                        type="submit"
                    >
                        {t('button.next')}
                    </Button>

                    <Voucher order={order} />
                </ButtonContainer>
            </form>
        </StepContainer>
    )
}

export default UserInput
