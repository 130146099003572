import { styled } from 'parkdepot-shared/theme'
import useEmailValidation from 'parkdepot-shared/utils/hooks/useEmailValidation'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useUserInputStore from '@hooks/useUserInputStore'
// import Button from '../Button'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import FormControl from '../FormControl'
import Input from '../Input'
import StepContainer from './styles/StepContainer'
import Title from './styles/Title'

interface EmailInputProps {
    onNextStep: () => void
}

const ButtonContainer = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: $theme.sizing.scale400
}))

const EmailForm = ({ onNextStep }: EmailInputProps) => {
    const globalStoreEmail = useUserInputStore((state) => state.email)
    const setEmailGlobalStore = useUserInputStore((state) => state.setEmail)
    const [email, setEmail] = useState(globalStoreEmail)
    const { t } = useTranslation(['payment', 'common'])
    const validateEmail = useEmailValidation()
    const emailError = email ? !validateEmail(email) : false
    console.log(globalStoreEmail)
    const submitForm = () => {
        if (email && !emailError) {
            setEmailGlobalStore(email)
        }

        onNextStep()
    }

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(e.currentTarget.value)
    }

    return (
        <StepContainer>
            <Title>{t('contactDetails')}</Title>

            <div>
                <FormControl label={t('placeholders.emailInput', { ns: 'common' })} caption={t('emailDescription')}>
                    <Input
                        type="email"
                        placeholder={t('enterEmail')}
                        value={email}
                        error={emailError}
                        onChange={handleEmailChange}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                submitForm()
                            }
                        }}
                    />
                </FormControl>
            </div>

            <ButtonContainer>
                <Button
                    $kind={BUTTON_KIND.primary}
                    $size={BUTTON_SIZE.large}
                    type="submit"
                    disabled={!email || emailError}
                    onClick={submitForm}
                >
                    {t('confirm')}
                </Button>
                <Button $kind={BUTTON_KIND.secondary} $size={BUTTON_SIZE.large} type="submit" onClick={onNextStep}>
                    {t('skip')}
                </Button>
            </ButtonContainer>
        </StepContainer>
    )
}

export default EmailForm
