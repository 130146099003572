import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin/Dropin'
import { ResultCode } from '@adyen/adyen-web/dist/types/components/types'
import { useApolloClient, useQuery } from '@apollo/client'
import Spinner from 'parkdepot-shared/components/Spinner'
import { FullOrderFragment } from 'parkdepot-shared/gql/graphql'
import { styled } from 'parkdepot-shared/theme'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderFragment } from '../../graphql/queries/carpayGetOrders.query'
import GET_CHECKOUT_SESSION from '../../graphql/queries/getCheckoutSession.query'
import createAdyenCheckout from '../../helpers/createAdyenSession'
import getDerivedValuesFromOrder from '../../helpers/getDerivedValuesFromOrder'
import useUserInputStore from '../../hooks/useUserInputStore'
import Dropin from './Dropin'
import { LoadingContainer } from './style/LoadingContainer.style'

const ErrorContainer = styled('div', ({ $theme }) => ({
    backgroundColor: $theme.colors.white,
    border: `3px solid ${$theme.colors.negative}`,
    padding: $theme.sizing.scale400
}))

export interface DropinContainerProps {
    order: FullOrderFragment
    onCompleted: (resultCode: ResultCode) => void
}

const DropinContainer = ({ order, onCompleted }: DropinContainerProps) => {
    const { t, i18n } = useTranslation('payment')
    const [dropin, setDropin] = useState<DropinElement>()
    const [adyenError, setAdyenError] = useState<string>()
    const { priceValidUntil, order_id: orderId } = getDerivedValuesFromOrder({ order })
    const selectedPolicyContextId = useUserInputStore((state) => state.getPolicyId())
    const selectedTariff = useUserInputStore((state) => state.getTariff())
    const email = useUserInputStore((state) => state.email)
    const client = useApolloClient()

    const { data } = useQuery(GET_CHECKOUT_SESSION, {
        variables: {
            order_id: orderId,
            email,
            language: i18n.language.replace('-', '_'),
            ...(selectedTariff ? { selected_parking_tariff: selectedTariff } : {}),
            parking_context_uuid: selectedPolicyContextId === 'car' ? undefined : selectedPolicyContextId // TODO: change this validation in the future. Should do it in one single place
        },
        onCompleted: () => {
            const order = client.cache.readFragment({
                id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
                fragment: OrderFragment
            })

            if (!order) return

            const modifiedOrder = { ...order, price_valid_until: priceValidUntil.toISO() }

            client.writeFragment({
                id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
                fragment: OrderFragment,
                data: modifiedOrder
            })
        },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (!data) return

        const initAdyenDropIn = async (adyenId: string, adyenSessionData: string) => {
            const adyenInfo = await createAdyenCheckout(
                {
                    id: adyenId,
                    sessionData: adyenSessionData
                },
                onCompleted
            )

            if (typeof adyenInfo === 'string') setAdyenError(adyenInfo)
            else setDropin(adyenInfo.dropin)
        }

        if (data.carpay_checkout_session?.adyen_id && data?.carpay_checkout_session?.adyen_session_data) {
            const adyenId = data.carpay_checkout_session?.adyen_id
            const adyenSessionData = data?.carpay_checkout_session?.adyen_session_data
            initAdyenDropIn(adyenId, adyenSessionData)
        }

        // Probably we are getting rid of stripe soon
        if (data.carpay_checkout_session?.stripe_url) {
            window.location.href = data.carpay_checkout_session?.stripe_url
        }
    }, [data, onCompleted])

    return (
        <>
            {!dropin ? (
                <LoadingContainer>
                    {!adyenError ? (
                        <Spinner size="lg" theme="primary" />
                    ) : (
                        <ErrorContainer>{t('dropIn.sessionError')}</ErrorContainer>
                    )}
                </LoadingContainer>
            ) : (
                <Dropin dropin={dropin} />
            )}
        </>
    )
}

export default DropinContainer
