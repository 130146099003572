import { ResultCode } from '@adyen/adyen-web/dist/types/components/types'
import createAdyenCheckout from '@helpers/createAdyenSession'
import { captureMessage } from '@sentry/react'
import { useEffect, useState } from 'react'

interface UseGetAdyenPaymentInfoProps {
    orderId: string | null
    status?: string
    redirectResult: string | null
    sessionId: string | null
}

// This hook is being called when there is a redirection from another payment provider like Klarna
const useGetAdyenPaymentInfo = ({
    orderId,
    status,
    redirectResult,
    sessionId
}: UseGetAdyenPaymentInfoProps): [ResultCode | undefined, boolean] => {
    const [adyenResultCode, setAdyenResultCode] = useState<ResultCode>()
    const [adyenDataLoading, setAdyenDataLoading] = useState(false)

    useEffect(() => {
        // only call the query if the order is not null and the state is not success
        if (orderId && !status && redirectResult && sessionId) {
            captureMessage(`Getting adyen status - Coming back from external payment service: ${orderId}`)
            setAdyenDataLoading(true)
            const getAdyenSessionData = async (sessionId: string) => {
                const adyenInfo = await createAdyenCheckout(
                    {
                        id: sessionId
                    },
                    (adyenResultCode) => {
                        setAdyenResultCode(adyenResultCode)
                        setAdyenDataLoading(false)
                    }
                )

                if (typeof adyenInfo !== 'string') adyenInfo.checkout.submitDetails({ details: { redirectResult } })
            }

            getAdyenSessionData(sessionId)
        }
    }, [orderId, redirectResult, status, sessionId])

    return [adyenResultCode, adyenDataLoading]
}

export default useGetAdyenPaymentInfo
