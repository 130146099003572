import AdyenCheckout from '@adyen/adyen-web'
import { ResultCode } from '@adyen/adyen-web/dist/types/components/types'
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types'
import { captureException } from '@sentry/react'

const ADYEN_CLIENT_KEY = process.env.VITE_ADYEN_CLIENT_KEY
const ADYEN_ENVIRONMENT = process.env.VITE_ADYEN_ENVIRONMENT

const adyenConfig: CoreOptions = {
    environment: ADYEN_ENVIRONMENT, // Change to 'live' for the live environment.
    clientKey: ADYEN_CLIENT_KEY, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
    onError: (error, element) => {
        captureException(
            `There has been an error on Adyen Config: ${error.name}, ${error.message}, ${error.stack}, ${element}`
        )
    },
    // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
    // For example, this is 3D Secure configuration for cards:
    paymentMethodsConfiguration: {
        card: {
            hasHolderName: true,
            holderNameRequired: true
        }
    }
}

const createAdyenCheckout = async (session: { [key: string]: string }, onCompleted: (code: ResultCode) => void) => {
    if (!ADYEN_CLIENT_KEY || !ADYEN_ENVIRONMENT) {
        const error = 'Missing configuration variables'
        captureException(error)

        return error
    }

    const configuration: CoreOptions = {
        ...adyenConfig,
        session,
        onPaymentCompleted: (data) => {
            onCompleted(data.resultCode)
        }
    }

    try {
        const checkout = await AdyenCheckout(configuration)
        const dropin = checkout.create('dropin')
        return { checkout, dropin }
    } catch (error) {
        captureException(`Error on Adyen Dropin: ${error}`)
        if (error instanceof Error) return error.message

        return 'Something went wrong'
    }
}

export default createAdyenCheckout
