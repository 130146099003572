import { Input as BUInput, StyledInput, StyledInputContainer, StyledRoot } from 'baseui/input'
import { withStyle } from 'parkdepot-shared/theme'
import React, { ComponentProps } from 'react'

type InputProps = ComponentProps<typeof BUInput>

export const InputInput = withStyle(StyledInput, ({ $theme }) => ({
    paddingBottom: $theme.sizing.scale500,
    paddingTop: $theme.sizing.scale500,

    '::placeholder': {
        ...$theme.typography.ParagraphMedium,
        color: $theme.colors.mono300
    }
}))

const InputInputContainer = withStyle(StyledInputContainer, ({ $theme }) => ({
    backgroundColor: $theme.colors.white
}))

const InputRoot = withStyle(StyledRoot, ({ $theme }) => ({
    borderColor: $theme.colors.mono300,
    borderWidth: '1px'
}))

const Input = ({ ...props }: InputProps) => {
    return (
        <BUInput overrides={{ Input: InputInput, InputContainer: InputInputContainer, Root: InputRoot }} {...props} />
    )
}

export default Input
