import { styled } from 'baseui'

const StyledWrapper = styled('div', ({ $theme }) => ({
    flex: 1,
    paddingRight: $theme.sizing.scale600,
    paddingBottom: $theme.sizing.scale600,
    paddingLeft: $theme.sizing.scale600,
    paddingTop: '80px', // header height
    width: '100vw',
    maxWidth: '480px',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column'
}))

export default StyledWrapper
